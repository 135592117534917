/* eslint-disable no-empty */
import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import { useFormik } from 'formik';
import useEmpleado from 'hooks/useEmpleado';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import solicitarCertificadoLaboralSchema from 'schemas/solicitarCertificadoLaboral.schema';
import { solicitarCertificadoLaboral } from 'services/CertificadoLaboralService';
import useAlerts from 'hooks/useAlerts';
import useFirebase from 'hooks/useFirebase';
import DOMPurify from 'dompurify';
import VerificarDatosModal from './VerificarDatosModalComponent';

export default function SolicitarCertificadoLaboralCard() {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();
  const { obtenerSolicitudes } = useEmpleado();
  const { openSnackbar } = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState({
    state: false,
    presentar_ante: '',
    enviarSolicitud: () => {},
  });
  const {
    state: { firebaseInstance },
  } = useFirebase();
  const initialValues = {
    presentar_ante: '',
    firma_certificada: false,
  };

  async function enviarSolicitud(presentar_ante, firma_certificada) {
    try {
      const res = await solicitarCertificadoLaboral(
        presentar_ante,
        firma_certificada,
      );

      if (res.data.success) {
        if (!firma_certificada) {
          const { base64, mime, name } = res.data.data;
          const link = document.createElement('a');
          link.href = `data:${mime};base64,${base64}`;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        obtenerSolicitudes();

        firebaseInstance?.logEvent('solicito-certificado-laboral');

        openSnackbar({
          state: true,
          message: res.data.message,
          severity: 'success',
        });
      } else {
        openSnackbar({
          state: true,
          message: res.data.message,
          severity: 'error',
        });
      }

      setModalState((prevState) => ({
        ...prevState,
        state: false,
        enviarSolicitud: () => {},
      }));
    } catch (error) {}
  }

  const solicitarCertificadoLaboralForm = useFormik({
    initialValues,
    validationSchema: solicitarCertificadoLaboralSchema,
    onSubmit: async (data, options) => {
      try {
        setIsLoading(true);
        const { firma_certificada, presentar_ante } = data;
        const sanitizedPresentarAnte = DOMPurify.sanitize(presentar_ante);

        setModalState({
          state: true,
          firma_certificada,
          presentar_ante: sanitizedPresentarAnte,
          enviarSolicitud: () => enviarSolicitud(sanitizedPresentarAnte, firma_certificada),
        });

        setIsLoading(false);
        options.resetForm();
      } catch (error) {
        options.resetForm();
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <VerificarDatosModal
        modalState={modalState}
        setModalState={setModalState}
      />
      <Card>
        <form onSubmit={solicitarCertificadoLaboralForm.handleSubmit}>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <FormikTextField
                id="presentar_ante"
                label={t('pages.solicitudes.presentar_ante_input')}
                formik={solicitarCertificadoLaboralForm}
              />
            </Grid>
            <Grid xs={12} item>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="firma_certificada"
                    checked={
                      solicitarCertificadoLaboralForm.values.firma_certificada
                    }
                    onChange={solicitarCertificadoLaboralForm.handleChange}
                  />
                )}
                label={t('pages.solicitudes.firma_certificada_input')}
              />
              <Typography sx={{ fontStyle: 'italic' }} variant="body2">
                {t('pages.solicitudes.firma_certificada_input_help')}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              mt={{ xs: 0, md: 1 }}
              display="flex"
              justifyContent="flex-end"
              item
            >
              <Button
                variant="contained"
                disabled={isLoading}
                type="submit"
                fullWidth={isMobile}
              >
                {isLoading ? t('botones.cargando') : t('botones.continuar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  );
}
