export default function ListIcon({
  width = 24,
  height = 22,
  stroke = '#4082F6',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 22"
      {...props}
    >
      <path
        d="M13.361 2.236h9.89M13.361 7.18h6.18M13.361 14.597h9.89M13.361 19.541h6.18M7.18 1H2.237C1.553 1 1 1.553 1 2.236v4.945c0 .682.553 1.236 1.236 1.236h4.945c.682 0 1.236-.554 1.236-1.236V2.236C8.417 1.553 7.863 1 7.18 1ZM7.18 13.36H2.237c-.683 0-1.236.554-1.236 1.237v4.944c0 .683.553 1.236 1.236 1.236h4.945c.682 0 1.236-.553 1.236-1.236v-4.944c0-.683-.554-1.236-1.236-1.236Z"
        stroke={stroke}
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
