export default function dateStringToFormatedDate(date) {
  const dateToFormat = new Date(date);
  const formatedDate = new Intl.DateTimeFormat('es-AR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(dateToFormat);

  return formatedDate;
}
