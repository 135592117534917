import { alpha } from '@mui/material';
import {
  ESTADO_APROBADO,
  ESTADO_ENVIADO,
  ESTADO_RECHAZADO,
  ESTADO_SOLICITADO,
} from '../constants';

export default function getStatusColor(id, firma_certificada, theme) {
  if (firma_certificada) {
    switch (id) {
      case ESTADO_APROBADO:
        return {
          backgroundColor: alpha(theme.palette.success.main, 0.2),
          color: theme.palette.success.main,
        };
      case ESTADO_SOLICITADO:
        return {
          backgroundColor: alpha(theme.palette.info.main, 0.2),
          color: theme.palette.info.main,
        };
      case ESTADO_RECHAZADO:
        return {
          backgroundColor: alpha(theme.palette.error.main, 0.2),
          color: theme.palette.error.main,
        };
      default:
        return {
          backgroundColor: alpha(theme.palette.common.black, 0.2),
          color: theme.palette.common.white,
        };
    }
  } else {
    switch (Number(id)) {
      case ESTADO_ENVIADO:
        return {
          backgroundColor: alpha(theme.palette.success.main, 0.2),
          color: theme.palette.success.main,
        };
      case ESTADO_APROBADO:
      case ESTADO_SOLICITADO:
        return {
          backgroundColor: alpha(theme.palette.info.main, 0.2),
          color: theme.palette.info.main,
        };
      case ESTADO_RECHAZADO:
        return {
          backgroundColor: alpha(theme.palette.error.main, 0.2),
          color: theme.palette.error.main,
        };
      default:
        return {
          backgroundColor: alpha(theme.palette.common.black, 0.2),
          color: theme.palette.common.white,
        };
    }
  }
}
