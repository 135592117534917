import empleadosApi from 'apis/EmpleadosApi';

export const getCuentaPeriodos = async () => {
  return empleadosApi.get('/ares/cuenta/periodos');
};

export const getCuentaResumenCuentaPdf = async (ultimoPeriodo) => {
  return empleadosApi.get('/ares/cuenta/resumen_cuenta_pdf', {
    params: { desde: ultimoPeriodo, hasta: ultimoPeriodo },
  });
};

export const getCasaPersonalCheckAsociado = async () => {
  return empleadosApi.get('/ares/casa_personal/check_asociado');
};

export const getCasaPersonalSolicitudes = async () => {
  return empleadosApi.get('/ares/casa_personal/solicitudes');
};

export const getCasaPersonalFechas = async (salon, turno) => {
  return empleadosApi.get('/ares/casa_personal/fechas', {
    params: { salon, turno },
  });
};

export const getCasaPersonalSolicitar = async ({
  salon,
  turno,
  fecha,
  motivo,
  personas,
}) => {
  return empleadosApi.post('/ares/casa_personal/solicitar', {
    salon,
    turno,
    fecha,
    motivo,
    personas,
  });
};

export const getCasaPersonalCancelar = async (id) => {
  return empleadosApi.post('/ares/casa_personal/cancelar', {
    id,
  });
};

export const getBmpj = async () => {
  return empleadosApi.get('/bmpj');
};

export const getBmpjInforme = async () => {
  return empleadosApi.get('/bmpj/descargar');
};
