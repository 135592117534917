import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useEmpleado from 'hooks/useEmpleado';
import { TARJETA_EMPLEADO, TARJETA_JUBILADO, TARJETA_SERVICIO } from 'constants/tipoTarjeta';
import SeleccionarSolicitudCard from './components/SeleccionarSolicitudCardComponent';
import { BMPJ, CASA_PERSONAL, RESUMEN_CUENTA } from './constants';

export default function AresPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {
      empleado: { tarjeta },
    },
  } = useEmpleado();

  const handleClickSolicitud = (id) => {
    if (id === RESUMEN_CUENTA) {
      navigate('/tarjeta-empleado/resumen-cuenta');
    } else if (id === CASA_PERSONAL) {
      navigate('/tarjeta-empleado/casa-personal');
    } else if (id === BMPJ) {
      navigate('/tarjeta-empleado/bmpj');
    }
  };

  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <Typography fontWeight={600}>
          {tarjeta === TARJETA_EMPLEADO
            && t('pages.tarjeta_empleado.titulo_empleado')}
          {tarjeta === TARJETA_SERVICIO
            && t('pages.tarjeta_empleado.titulo_servicio')}
          {tarjeta === TARJETA_JUBILADO
            && t('pages.tarjeta_empleado.titulo_jubilado')}
        </Typography>
      </Grid>

      <Grid xs={12} item>
        <Typography>{t('pages.tarjeta_empleado.subtitulo')}</Typography>
      </Grid>

      <Grid xs={12} lg={4} item>
        <SeleccionarSolicitudCard handleClickSolicitud={handleClickSolicitud} />
      </Grid>
    </Grid>
  );
}
