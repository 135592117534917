import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFoundView() {
  const navigation = useNavigate();

  useEffect(() => {
    navigation('/ingresar', { replace: true });
  }, []);

  return null;
}
