import { Grid, Typography, Button } from '@mui/material';
import InputCodigo from 'components/inputCodigo/InputCodigoComponent';
import Modal from 'components/modal/ModalComponent';
import { SMS_CODE_DIGITOS } from 'constants/validacion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ValidaTusDatosModal({ modalState, setModalState }) {
  const { t } = useTranslation();
  const [finalizoElTiempo, setFinalizoElTiempo] = useState(false);

  const handleClose = () => {
    setModalState({
      state: false,
      tiempoSegundos: 0,
      smsCodeError: false,
      onComplete: () => {},
    });
  };

  const handleClickReintentar = () => {
    setFinalizoElTiempo(false);
  };

  const handleComplete = async (value) => {
    modalState.onComplete(value);
  };

  return (
    <Modal open={modalState.state} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography fontWeight={600}>
            {t('pages.inicio.modales.valida_tus_datos.titulo')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">
            {t('pages.inicio.modales.valida_tus_datos.mensaje')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          {finalizoElTiempo ? (
            <Button variant="contained" onClick={handleClickReintentar} fullWidth>
              {t('botones.reintentar')}
            </Button>
          ) : (
            <InputCodigo
              error={modalState.smsCodeError}
              digitos={SMS_CODE_DIGITOS}
              handleFinalizoElTiempo={() => setFinalizoElTiempo(true)}
              handleComplete={handleComplete}
              tiempo={modalState.tiempoSegundos}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
}
