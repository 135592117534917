import {
  IconChevronLeft,
  IconDownload,
} from '@cooperativaobrera/react-icons/tabler-icons';
import { PERMISO_BMJP } from 'constants/permisos';
import {
  Button, ButtonBase, Grid, Typography, 
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getBmpj, getBmpjInforme } from 'services/Ares';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useUserHasPermission from 'hooks/useUserHasPermission';
import useEmpleado from 'hooks/useEmpleado';
import {
  TARJETA_EMPLEADO,
  TARJETA_JUBILADO,
  TARJETA_SERVICIO,
} from 'constants/tipoTarjeta';
import DatosAportes from './components/DatosAportes';
import InformeSemestralPDF from './components/InformeSemestralPDF';

export default function ResumenCuentaPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const isMobile = useMobileBreakpoint();
  const {
    state: { empleado },
  } = useEmpleado();

  const tieneBMPJ = useUserHasPermission(PERMISO_BMJP);

  const handleDownloadInforme = () => {
    getBmpjInforme().then((res) => {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${res.data.data.base_64}`;
      link.setAttribute('download', 'Informe semestral');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  useEffect(() => {
    if (tieneBMPJ) {
      getBmpj().then((bmpjRes) => {
        getBmpjInforme().then((informeRes) => {
          setData({
            ...bmpjRes.data.data,
            informeSemestral: informeRes.data.data.base_64,
          });
        });
      });
    } else {
      navigate('/tarjeta-empleado', { replace: true });
    }
  }, []);

  return (
    tieneBMPJ && (
      <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
        <Grid xs={12} item>
          <Typography fontWeight={600}>
            {empleado.tarjeta === TARJETA_EMPLEADO
              && t('pages.tarjeta_empleado.titulo_empleado')}
            {empleado.tarjeta === TARJETA_SERVICIO
              && t('pages.tarjeta_empleado.titulo_servicio')}
            {empleado.tarjeta === TARJETA_JUBILADO
              && t('pages.tarjeta_empleado.titulo_jubilado')}
          </Typography>
        </Grid>

        <Grid xs={12} display="flex" justifyContent="space-between" item>
          <ButtonBase
            onClick={() => navigate('/tarjeta-empleado')}
            disableRipple
          >
            <IconChevronLeft />
            <Typography>{t('pages.tarjeta_empleado.bmpj.titulo')}</Typography>
          </ButtonBase>
        </Grid>
        <Grid xs={12} item>
          <DatosAportes datos={data?.aportante} />
        </Grid>
        <Grid xs={12} display="flex" justifyContent="flex-end" item>
          <Button
            variant="contained"
            size="small"
            onClick={handleDownloadInforme}
            endIcon={<IconDownload />}
            disabled={!data?.informeSemestral}
            fullWidth={isMobile}
          >
            {t('pages.tarjeta_empleado.bmpj.descargar_informe')}
          </Button>
        </Grid>
        <Grid xs={12} item>
          <InformeSemestralPDF pdf={data?.informeSemestral} />
        </Grid>
      </Grid>
    )
  );
}
