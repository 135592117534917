import Cargando from 'components/cargando/CargandoComponent';
import useAlerts from 'hooks/useAlerts';

export default function GlobalLoading() {
  const {
    state: { loadingInstances, progreso },
  } = useAlerts();

  return <Cargando loadingInstances={loadingInstances} progreso={progreso} />;
}
