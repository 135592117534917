import Modal from 'components/modal/Modal2/ModalComponent';
import { Button, Typography } from '@mui/material';
import ModalHeader from 'components/modal/Modal2/ModalHeaderComponent';
import ModalActions from 'components/modal/Modal2/ModalActionsComponent';
import ModalContent from 'components/modal/Modal2/ModalContentComponent';
import { useTranslation } from 'react-i18next';

export default function SolicitudRealizadaModal({ open, handleClose }) {
  const { t } = useTranslation();

  return (
    <Modal
      sx={{
        maxWidth: {
          xs: '392px',
          sm: '600px',
        },
      }}
      open={open}
    >
      <ModalHeader>
        <Typography variant="h6" fontWeight={600}>
          Solicitud realizada exitosamente
        </Typography>
      </ModalHeader>
      <ModalContent>
        <Typography>
          Por favor recordá que al realizar la solicitud del salón con tanta
          anticipación,
          {' '}
          <Typography component="span" fontWeight={500}>
            el salón podría ser reservado por otras personas para su uso.
          </Typography>
          {' '}
          En tal caso, se realizará un sorteo un mes antes del evento para
          determinar quién podrá utilizarlo ese día.
          <br />
          <br />
          <Typography component="span" color="secondary.main">
            Aclaración:
          </Typography>
          {' '}
          Te sugerimos que revises un mes antes para verificar si se te asignó
          el salón o no.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={handleClose}
          size="small"
        >
          {t('botones.entendido')}
        </Button>
      </ModalActions>
    </Modal>
  );
}
