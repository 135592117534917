import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Hooks
import CuentaDuplicadaModal from 'components/ingresar/CuentaDuplicadaModalComponent';
import { useFormik } from 'formik';
import ingresarSchema from 'schemas/ingresar.schema';
import IngresarForm from 'components/ingresar/IngresarFormComponent';
import useAlerts from 'hooks/useAlerts';
import useFirebase from 'hooks/useFirebase';
import useAuth from '../../hooks/useAuth';
import useEmpleado from '../../hooks/useEmpleado';

export default function IngresarPage() {
  const { t } = useTranslation();
  const { signin } = useAuth();
  const {
    state: { firebaseInstance },
  } = useFirebase();
  const { initEmpleado, obtenerPermisos } = useEmpleado();
  const navigate = useNavigate();
  const { openSnackbar } = useAlerts();

  // Validar onChange luego del primer submit
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState({
    state: false,
    usuariosEncontrados: [],
    onClickSoyYo: () => {},
  });

  const initialValues = {
    documento: '',
    password: '',
  };

  const login = ({ documento, password, tipoDocumento = undefined }) => {
    return signin(documento, password, tipoDocumento).then((resp) => {
      if (resp?.data?.success) {
        initEmpleado();
        obtenerPermisos().then(() => {
          firebaseInstance?.logEvent('login');
  
          navigate('/validar', { replace: true });
        }).catch((error) => {
          openSnackbar({
            message: error.response.data.message,
            severity: 'error',
          });
        });
      }
      // Más de un usuario, modal de seleción
      if (resp && resp.status && resp.status === 409) {
        setModalState({
          state: true,
          usuariosEncontrados: resp.data.data.map((usuario, i) => ({
            id: i,
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            nro_documento: documento,
            id_tipo_documento: usuario.id_tipo_documento,
          })),
          onClickSoyYo: (usuarioSeleccionado) => {
            login({
              documento,
              password,
              tipoDocumento: usuarioSeleccionado.id_tipo_documento,
            });
            setModalState((prevState) => ({ ...prevState, state: false }));
          },
        });
      }
    });
  };

  const ingresarForm = useFormik({
    initialValues,
    validationSchema: ingresarSchema,
    validateOnChange: shouldValidateOnChange,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (data, options) => {
      try {
        setIsLoading(true);
        await login(data);
        setIsLoading(false);
      } catch (error) {
        options.resetForm();
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <CuentaDuplicadaModal
        modalState={modalState}
        setModalState={setModalState}
      />
      <Grid spacing={{ xs: 3, md: 4 }} container>
        <Grid xs={12} item>
          <Typography mb={1} variant="h5">
            {t('pages.ingresar.titulo')}
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{t('pages.ingresar.subtitulo')}</Typography>
        </Grid>
        <Grid xs={12} item>
          <IngresarForm
            formik={ingresarForm}
            setShouldValidateOnChange={setShouldValidateOnChange}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
}
