// Selecciona la librería correspondiente según el navegador, si no existe retorna error.
export default function cargarCryptoAPINavegador() {
  const cryptoLibrary = window.crypto.subtle || window.crypto.webkitSubtle;

  const funcionesUsadas = [
    'encrypt',
    'decrypt',
    'digest',
    'generateKey',
    'deriveBits',
    'importKey',
    'exportKey',
  ];

  const funcionesSoportadas = cryptoLibrary && funcionesUsadas.every((funcion) => typeof cryptoLibrary[funcion] === 'function');
  const base64Soportado = window.atob && typeof window.atob === 'function' && window.btoa && typeof window.btoa === 'function';

  if (!cryptoLibrary || !funcionesSoportadas || !base64Soportado) {
    const error = new Error('El navegador no es compatible con esta función, disculpe el incoveniente.');
    error.code = 404;
    throw error;
  }

  return cryptoLibrary;
}
