import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useEmpleado from 'hooks/useEmpleado';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import DatosDiasVacacionesCard from 'components/vacaciones/DatosDiasVacacionesCardComponent';
import DatosDiasVacacionesMobile from 'components/vacaciones/DatosDiasVacacionesMobileComponent';
import HistorialVacacionesCard from 'components/vacaciones/HistorialVacacionesCardComponent';

export default function Vacaciones() {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();
  const {
    obtenerHistorialVacaciones,
    state: {
      empleado: { datos_vacaciones, historial_vacaciones },
    },
  } = useEmpleado();

  useEffect(() => {
    if (datos_vacaciones.length === 0) {
      obtenerHistorialVacaciones();
    }
  }, []);

  const styles = {
    container: {
      width: !isMobile ? {
        xl: '50% !important',
        lg: '70% !important',
      } : null,
    },
  };

  return (
    <Grid
      container
      sx={styles.container}
      padding={{ xs: 2, md: 4 }}
      spacing={{ xs: 2, md: 4 }}
    >
      {!isMobile && (
        <Grid xs={12} item>
          <Typography fontWeight={600}>
            {t('pages.vacaciones.titulo')}
          </Typography>
        </Grid>
      )}
      <Grid xs={12} item>
        {isMobile ? (
          <DatosDiasVacacionesMobile datos={datos_vacaciones} />
        ) : (
          <DatosDiasVacacionesCard datos={datos_vacaciones} />
        )}
      </Grid>
      <Grid xs={12} item>
        <HistorialVacacionesCard historial={historial_vacaciones} />
      </Grid>
    </Grid>
  );
}
