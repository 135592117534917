export default function CerrarSesionIcon({
  width = 22,
  height = 19,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 19"
      {...props}
    >
      <path
        d="M13.125 5.25V3.125A2.125 2.125 0 0 0 11 1H3.562a2.125 2.125 0 0 0-2.124 2.125v12.75A2.125 2.125 0 0 0 3.562 18H11a2.125 2.125 0 0 0 2.125-2.125V13.75M5.688 9.5h14.874m0 0-3.187-3.188M20.562 9.5l-3.187 3.188"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
