import authApi from 'apis/AuthApi';
import empleadosApi from 'apis/EmpleadosApi';

export const sendVerifySMS = async (telefono_area, telefono_numero) => {
  return authApi.post('/verify_phone', { telefono_area, telefono_numero });
};

export const verifySMSCode = async (codigo) => {
  return authApi.put('/verify_phone', { codigo });
};

export const activarUsuario = async () => {
  return empleadosApi.post('/user/activar');
};
