import { Box, Typography } from '@mui/material';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';

const styles = {
  datos: {
    display: 'flex',
    width: 'auto',
    maxWidth: 200,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 1,
    height: '100%',
  },
  datosMobile: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
  },
};

/**
 * Componente para mostrar un dato numérico
 * @param title El texto que acompaña al dato
 * @param number El dato numérico
 * @param customStyles Define el estilo del componente
 * @param font El estilo de la fuente para el texto
 */
export default function NumericInfoItem({
  title,
  number,
  font = 'body2',
}) {
  const isMobile = useMobileBreakpoint();
  return (
    <Box sx={isMobile ? styles.datosMobile : styles.datos}>
      <Typography variant={font} maxWidth={200}>
        {title}
      </Typography>
      <Typography variant="h5" color="secondary" fontWeight={600}>
        {number}
      </Typography>
    </Box>
  );
}
