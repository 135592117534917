export default function InicioIcon({
  width = 24,
  height = 24,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="m21.032 7.988-6.78-5.274a3.39 3.39 0 0 0-4.162 0L3.308 7.988A3.388 3.388 0 0 0 2 10.664v9.154a2.543 2.543 0 0 0 2.543 2.542h15.256a2.543 2.543 0 0 0 2.543-2.542v-9.154a3.389 3.389 0 0 0-1.31-2.676Z"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
