import { EMPRESAS_TARJETA_SERVICIOS } from 'constants/empresa';
import { getPermisosUsuarioAPI } from 'services/AuthService';
import { getHistorialSolicitudes } from 'services/CertificadoLaboralService';
import { getHistorialVacaciones } from 'services/VacacionesService';
import {
  formatDatosVacacionesResponse,
  formatHistorialVacacionesResponse,
} from 'utils/vacaciones';
import { TARJETA_EMPLEADO, TARJETA_JUBILADO, TARJETA_SERVICIO } from 'constants/tipoTarjeta';
import { USUARIO_JUBILADO } from 'constants/tipoUsuario';
import { EMPLOYEE_KEY, USER_KEY } from '../constants/storage';
import CreateDataContext from './CreateDataContext';

const empleadoReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        usuario: { ...state.usuario, ...action.payload.usuario },
        empleado: { ...state.empleado, ...action.payload.empleado },
      };
    case 'setUsuario':
      return state;
    case 'setEmpleado':
      return state;
    case 'setPermisos':
      return {
        ...state,
        permisos: action.payload.permisos,
      };
    case 'updateUsuario':
      return { ...state, usuario: { ...state.usuario, ...action.payload } };
    case 'updateEmpleado':
      return { ...state, empleado: { ...state.empleado, ...action.payload } };
    default:
      return state;
  }
};

const initEmpleado = (dispatch) => async () => {
  const usuario = JSON.parse(sessionStorage.getItem(USER_KEY));
  const empleado = JSON.parse(sessionStorage.getItem(EMPLOYEE_KEY));
  const tarjetaServicios = EMPRESAS_TARJETA_SERVICIOS.some(
    (local) => local === Number(empleado.id_empresa),
  );
  let tarjeta = TARJETA_EMPLEADO;
  
  if (empleado.tipoUsuario === USUARIO_JUBILADO) {
    tarjeta = TARJETA_JUBILADO;
  } else if (tarjetaServicios) {
    tarjeta = TARJETA_SERVICIO;
  }

  dispatch({ type: 'init', payload: { usuario, empleado: { ...empleado, tarjeta } } });
};

const setUsuario = (dispatch) => async () => {
  dispatch({ type: 'setUsuario', payload: undefined });
};

const setEmpleado = (dispatch) => async () => {
  dispatch({ type: 'setEmpleado', payload: undefined });
};

const updateUsuario = (dispatch) => async (data) => {
  dispatch({ type: 'updateUsuario', payload: data });
};

const updateEmpleado = (dispatch) => async (data) => {
  dispatch({ type: 'updateEmpleado', payload: data });
};

const obtenerPermisos = (dispatch) => async () => {
  const res = await getPermisosUsuarioAPI();

  if (res.data.success) {
    dispatch({ type: 'setPermisos', payload: { permisos: res.data.data } });
  }

  return res;
};

const obtenerSolicitudes = (dispatch) => async () => {
  const res = await getHistorialSolicitudes();

  if (res.data.success) {
    dispatch({ type: 'updateEmpleado', payload: { solicitudes: res.data.data } });
  }

  return res;
};

const obtenerHistorialVacaciones = (dispatch) => async () => {
  const res = await getHistorialVacaciones();

  if (res.data.success) {
    const datos_vacaciones = formatDatosVacacionesResponse(res.data.data);
    const historial_vacaciones = formatHistorialVacacionesResponse(
      res.data.data,
    );
    dispatch({
      type: 'updateEmpleado',
      payload: { datos_vacaciones, historial_vacaciones },
    });
  }

  return res;
};

export const { Context, Provider } = CreateDataContext(
  empleadoReducer,
  {
    initEmpleado,
    setUsuario,
    setEmpleado,
    updateUsuario,
    updateEmpleado,
    obtenerPermisos,
    obtenerSolicitudes,
    obtenerHistorialVacaciones,
  },
  {
    usuario: null,
    permisos: [],
    empleado: {
      legajo: '',
      fecha_ingreso: '',
      id_puesto: '',
      puesto_desc: '',
      id_sector: '',
      sector_desc: '',
      id_empresa: '',
      empresa_desc: '',
      id_local: '',
      local_desc: '',
      provincia: '',
      localidad: '',
      calle: '',
      numero: '',
      status: '',
      domicilio: '',
      solicitudes: [],
      datos_vacaciones: [],
      historial_vacaciones: [],
      tipoUsuario: '',
    },
  },
);
