import {
  alpha,
  styled,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@mui/material';
import { useState } from 'react';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { stableSort, getComparator } from 'utils/sorting';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableHeadWithSorting from './EnhancedTableHeadWithSorting';

const styles = {
  tableContainer: {
    /** 
     * Se restan esos margenes porque al usar el borderSpacing en la tabla
     * añade margenes indeseados alrededor
    */
    margin: '-8px -24px -8px -8px',
    '&[data-is-mobile=true]': {
      margin: '-8px -16px -8px 0px',
    },
    width: 'calc(100% + 48px)',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: '24px 8px',
  },
};

const StyledTableHeader = styled(TableRow)(() => ({
  '& td, th': {
    paddingLeft: 16,
    fontWeight: 'bold',
  },
}));

/**
 * Tabla simple con un opcional de ordenamiento.
 * @param sorting para activar el ordenamiento
 * @param allowSorting en cada headCells que se quiera habilitar para ordenar
*/
export default function SimpleTableComponent({ headCells, rows, sorting }) {
  const isMobile = useMobileBreakpoint();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(headCells[0].id);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedrows = sorting ? stableSort(rows, getComparator(order, orderBy)) : rows;

  return (
    <TableContainer sx={styles.tableContainer} data-is-mobile={isMobile}>
      <Table sx={styles.table} aria-label="tabla de vacaciones ordenable">
        {sorting
          ? (
            <EnhancedTableHeadWithSorting
              headCells={headCells}
              StyledTableHeadRow={StyledTableHeader}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          )
          : (
            <EnhancedTableHead
              headCells={headCells}
              StyledTableHeadRow={StyledTableHeader}
            />
          )}
        <TableBody>
          {sortedrows.map((row) => (
            <TableRow key={row.id}>
              {headCells.map((cell) => (
                <TableCell
                  key={cell.id}
                  sx={{
                    width: cell.width,
                    color: cell.color ? cell.color : 'inherit',
                    backgroundColor: cell.color
                      ? `${alpha(cell.color, cell.opacity)} !important`
                      : 'inherit',
                  }}
                  align={cell.align}
                >
                  {row[cell.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {/* Row vacío para darle espacio al final de la tabla */}
          <TableRow sx={{ height: 20 }} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
