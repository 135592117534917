import createDataContext from 'contexts/CreateDataContext';

const appbarReducer = (state, action) => {
  switch (action.type) {
    case 'setTitle':
      return { ...state, title: action.payload };
    case 'setLeftButton':
      return { ...state, leftButton: action.payload };
    case 'setRightButton':
      return { ...state, rightButton: action.payload };
    default:
      return state;
  }
};

const updateTitle = (dispatch) => async (title) => {
  dispatch({
    type: 'setTitle',
    payload: title,
  });
};

const updateLeftButton = (dispatch) => async (leftButton) => {
  dispatch({
    type: 'setLeftButton',
    payload: leftButton,
  });
};

const updateRightButton = (dispatch) => async (rightButton) => {
  dispatch({
    type: 'setRightButton',
    payload: rightButton,
  });
};

const defaultLeftButton = (dispatch) => async () => {
  dispatch({
    type: 'setLeftButton',
    payload: null,
  });
};

const defaultRightButton = (dispatch) => async () => {
  dispatch({
    type: 'setRightButton',
    payload: null,
  });
};

export const { Context, Provider } = createDataContext(
  appbarReducer,
  {
    updateTitle,
    updateLeftButton,
    updateRightButton,
    defaultLeftButton,
    defaultRightButton,
  },
  {
    title: '',
    leftButton: null,
    rightButton: null,
  },
);
