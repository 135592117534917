export default function SectorIcon({
  width = 18,
  height = 22,
  stroke = '#4082F6',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 22"
      {...props}
    >
      <path
        d="M10.993 1v4.444a1.111 1.111 0 0 0 1.111 1.112h4.445"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.326 21H3.216a2.222 2.222 0 0 1-2.223-2.222V3.222A2.222 2.222 0 0 1 3.215 1h7.778l5.556 5.556v12.222A2.222 2.222 0 0 1 14.326 21ZM5.438 7.667H6.55M5.438 12.111h6.667M5.438 16.556h6.667"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
