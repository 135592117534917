import {
  Grid, Typography, Button, InputAdornment, 
} from '@mui/material';
import Card from 'components/card/CardComponent';
import useEmpleado from 'hooks/useEmpleado';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useState } from 'react';
import datosPersonalesSchema from 'schemas/datosPersonales.schema';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import capitalizeFirstLetterOfEachWord from 'utils/capitalizeFirstLetterOfEachWord';
import DatePicker from 'components/datepicker/FormikDatepickerComponent';
import { TIPOS_DOCUMENTOS } from 'constants/tiposDocumentos';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { sendVerifySMS, verifySMSCode } from 'services/VerifyService';
import { actualizarDatosPersonales } from 'services/DatosPersonalesService';
import useAlerts from 'hooks/useAlerts';
import useFirebase from 'hooks/useFirebase';
import onlyNumbersValidate from 'utils/onlyNumbersValidate';
import ValidaTusDatosModal from './ValidaTusDatosModalComponent';

export default function DatosPersonalesCard() {
  const { t } = useTranslation();
  const {
    state: { usuario, empleado },
    updateUsuario,
    updateEmpleado,
  } = useEmpleado();
  const isMobile = useMobileBreakpoint();
  const [isLoading, setIsLoading] = useState(false);
  const { openSnackbar } = useAlerts();
  const initialValues = {
    nombreApellido: capitalizeFirstLetterOfEachWord(
      `${usuario.nombre} ${usuario.apellido}`,
    ),
    fechaNacimiento: new Date(`${usuario.fecha_nacimiento}T00:00`),
    tipoDocumento: TIPOS_DOCUMENTOS.find(
      (tipoDocumento) => tipoDocumento.id_tipo_documento === usuario.id_tipo_documento,
    ).tipo_documento,
    documento: usuario.nro_documento,
    codigoArea: usuario.telefono_area,
    numeroCelular: usuario.telefono_numero,
    direccion: empleado.domicilio,
    correoPersonal: usuario.email,
  };
  const [modalState, setModalState] = useState({
    state: false,
    tiempoSegundos: 0,
    smsCodeError: false,
    onComplete: () => {},
  });
  const {
    state: { firebaseInstance },
  } = useFirebase();

  const verificoCodigoValidacion = async (
    codigo,
    codigoArea,
    numeroCelular,
  ) => {
    const res = await verifySMSCode(codigo);
    if (res.data.success) {
      firebaseInstance?.logEvent('actualizo-telefono');

      openSnackbar({
        message: res.data.message,
        severity: 'success',
      });
      setModalState({
        state: false,
        tiempoSegundos: 0,
        smsCodeError: false,
        onComplete: () => {},
      });
      updateUsuario({
        telefono_area: codigoArea,
        telefono_numero: numeroCelular,
      });
    } else {
      openSnackbar({
        message: res.data.message,
        severity: 'error',
      });
      setModalState((prevState) => ({ ...prevState, smsCodeError: true }));
      setTimeout(() => {
        setModalState((prevState) => ({ ...prevState, smsCodeError: false }));
      }, [1000]);
    }
  };

  const datosPersonalesForm = useFormik({
    initialValues,
    validationSchema: datosPersonalesSchema,
    enableReinitialize: true,
    onSubmit: async (data, options) => {
      try {
        setIsLoading(true);
        const { codigoArea, numeroCelular, direccion } = data;
        if (
          initialValues.codigoArea !== codigoArea
          || initialValues.numeroCelular !== numeroCelular
        ) {
          // Para cambiar el celular se envia un SMS de validacion
          const res = await sendVerifySMS(codigoArea, numeroCelular);
          if (res.data.success) {
            // Verifico el codigo de validacion introducido
            setModalState({
              state: true,
              tiempoSegundos: res.data.data.expires_in,
              onComplete: (codigo) => verificoCodigoValidacion(codigo, codigoArea, numeroCelular),
            });
          }
        } 
        
        if (initialValues.direccion !== direccion) {
          // Cambio la direccion
          const res = await actualizarDatosPersonales(direccion);
          if (res.data.success) {
            firebaseInstance?.logEvent('actualizo-datos-personales');

            updateEmpleado({
              domicilio: direccion,
            });
            openSnackbar({
              message: res.data.message,
              severity: 'success',
            });
          } else {
            openSnackbar({
              message: res.data.message,
              severity: 'error',
            });
          }
        }
        setIsLoading(false);
      } catch (error) {
        options.resetForm();
        setIsLoading(false);
      }
    },
  });

  return (
    <Card>
      <ValidaTusDatosModal
        modalState={modalState}
        setModalState={setModalState}
      />
      <form onSubmit={datosPersonalesForm.handleSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <Typography fontWeight={600}>
              {t('pages.inicio.datos_personales_titulo')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} lg={4} item>
                <FormikTextField
                  id="nombreApellido"
                  label={t('pages.inicio.nombre_apellido_input')}
                  formik={datosPersonalesForm}
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6} lg={3.5} item>
                <FormikTextField
                  id="correoPersonal"
                  label={t('pages.inicio.correo_personal_input')}
                  formik={datosPersonalesForm}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid xs={12} lg={2.4} item>
                <DatePicker
                  id="fechaNacimiento"
                  label={t('pages.inicio.fecha_nacimiento_input')}
                  formik={datosPersonalesForm}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid xs={5} sm={4} md={4} lg={1.6} item>
                <FormikTextField
                  id="tipoDocumento"
                  label={t('pages.inicio.tipo_documento_input')}
                  formik={datosPersonalesForm}
                  disabled
                />
              </Grid>
              <Grid xs={7} sm={8} md={8} lg={3.5} item>
                <FormikTextField
                  id="documento"
                  label={t('pages.inicio.documento_input')}
                  formik={datosPersonalesForm}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid xs={5} sm={4} md={4} lg={1.6} item>
                <FormikTextField
                  id="codigoArea"
                  label={t('pages.inicio.codigo_area_input')}
                  formik={datosPersonalesForm}
                  validate={onlyNumbersValidate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        0
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={7} sm={8} md={8} lg={2.4} item>
                <FormikTextField
                  id="numeroCelular"
                  label={t('pages.inicio.numero_celular_input')}
                  formik={datosPersonalesForm}
                  validate={onlyNumbersValidate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        15
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid mt={{ md: 4 }} xs={12} textAlign="right" item>
            <Button
              variant="contained"
              disabled={
                  !datosPersonalesForm.isValid
                  || isLoading
                  || !datosPersonalesForm.dirty
                }
              type="submit"
              fullWidth={isMobile}
            >
              {isLoading
                ? t('botones.cargando')
                : t('botones.guardar_cambios')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}
