export default function UsuarioIcon({
  width = 56,
  height = 56,
  stroke = '#8B8B8B',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 56"
      {...props}
    >
      <circle cx={28} cy={28} r={28} fill="#EFEFEF" />
      <path
        d="M27.929 33.385a8.192 8.192 0 1 0 0-16.385 8.192 8.192 0 0 0 0 16.385ZM12 49.356a10.923 10.923 0 0 1 10.464-7.78h10.923a10.924 10.924 0 0 1 10.47 7.797"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
