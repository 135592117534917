/* eslint-disable no-plusplus */
import {
  Box, CircularProgress, Grid, TextField, 
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CuentaAtras from '../cuentaAtras/CuentaAtrasComponent';

const styles = {
  inputCodigo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default function InputCodigo({
  digitos,
  handleFinalizoElTiempo,
  handleComplete,
  error,
  tiempo = 300,
}) {
  const refs = useRef([]);
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState(false);

  const verificar = async () => {
    let codigo = '';
    let completo = true;
    let i = 0;

    while (i < refs.current.length && completo) {
      const valor = refs.current[i].value;
      if (valor === '') completo = false;
      codigo += valor;
      i++;
    }

    if (!completo) return;

    setLoading(true);
    await handleComplete(codigo);
    setLoading(false);
  };

  const handleChange = (valor, indice) => {
    let aEnfocar = null;
    const referencias = refs.current;
    if (valor.length > 0) {
      if (indice !== inputs.length - 1) {
        aEnfocar = referencias[indice + 1];
      }
    }

    if (valor.length === 0) {
      if (indice === 0) return;
      aEnfocar = referencias[indice - 1];
    }
    verificar();
    if (aEnfocar) {
      aEnfocar.focus();
    }
  };

  const inicializarInput = () => {
    const toSet = [];
    for (let i = 0; i < digitos; i++) {
      toSet.push(
        <TextField
          // eslint-disable-next-line no-return-assign
          inputRef={(element) => (refs.current[i] = element)}
          sx={{ width: `${(0.9 / digitos) * 100}%` }}
          variant="standard"
          inputProps={{
            maxLength: 1,
            sx: {
              textAlign: 'center',
              fontSize: { xs: '20px !important', md: '30px !important' },
              border: '0 solid !important',
              boxShadow: 'none !important',
            },
          }}
          onChange={(e) => {
            handleChange(e.target.value, i);
          }}
        />,
      );
    }

    setInputs([...toSet]);
  };

  useEffect(() => {
    inicializarInput();
  }, []);

  useEffect(() => {
    if (error) {
      refs.current.forEach((ref) => {
        ref.value = '';
      });
      inicializarInput();
      refs.current[0].focus();
    }
  }, [error]);

  return (
    <Grid px={{ xs: 0, sm: 5 }} container spacing={5}>
      <Grid textAlign="center" xs={12} item>
        {loading && <CircularProgress />}
        {!loading && (
          <Box
            className={error ? 'animate__animated animate__shakeX' : ''}
            sx={styles.inputCodigo}
          >
            {inputs.map((valor, index) => {
              // eslint-disable-next-line react/no-array-index-key
              return <React.Fragment key={index}>{valor}</React.Fragment>;
            })}
          </Box>
        )}
      </Grid>
      <Grid xs={12} item>
        <CuentaAtras
          tiempo={tiempo}
          alExpirar={{
            accion: handleFinalizoElTiempo,
            reiniciar: false,
          }}
        />
      </Grid>
    </Grid>
  );
}
