/* eslint-disable import/no-cycle */
import { loginCU, loginEmpleo } from '../services/AuthService';
import { validateSession } from '../utils';
import CreateDataContext from './CreateDataContext';
import { UTOKEN, USER_KEY, EMPLOYEE_KEY } from '../constants/storage';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'signin':
      return { ...state, session: action.payload };
    case 'signout':
      return { ...state, session: null };
    default:
      return state;
  }
};

const signin = (dispatch) => async (nroDocumento, password, tipoDocumento) => {
  return new Promise((resolve) => {
    loginCU(nroDocumento, password, tipoDocumento)
      .then((respCU) => {
        if (respCU?.data?.success) {
          const userData = respCU.data;
          const session = validateSession(userData);
          if (session) {
            sessionStorage.setItem(UTOKEN, session.access_token);
            loginEmpleo(respCU.data.usuario.legajo)
              .then((respPE) => {
                if (respPE?.data?.success && respPE.data.data) {
                  sessionStorage.setItem(
                    USER_KEY,
                    JSON.stringify(userData.usuario),
                  );
                  sessionStorage.setItem(
                    EMPLOYEE_KEY,
                    JSON.stringify(respPE.data.data),
                  );
                  dispatch({ type: 'signin', payload: session });
                  resolve(respCU);
                } else {
                  resolve(false);
                }
              })
              .catch(() => resolve(false));
          }
        } else {
          resolve(respCU);
        }
      })
      .catch(() => resolve(false));
  });
};

const signout = (dispatch) => async () => {
  dispatch({ type: 'signout', payload: undefined });
};

export const { Context, Provider } = CreateDataContext(
  authReducer,
  {
    signin,
    signout,
  },
  {
    session: null,
  },
);
