import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import mensajes from './assets/dictionary/mensajes.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  es: {
    translation: mensajes,
  },
};

i18n
  .use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'es',

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
