export default function convertYearMonthToFormattedDate(dateString) {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4);
  const date = new Date(`${year}-${month}-02`);
  const options = { year: 'numeric', month: 'long' };

  // Formatear la fecha sin el "de" y con la primera letra del mes en mayúscula
  const formattedDate = new Intl.DateTimeFormat('es-AR', options)
    .format(date)
    .replace(/ de/, '') // Eliminar el "de"
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalizar la primera letra del mes

  return formattedDate;
}
