export default function SeguridadIcon({
  width = 20,
  height = 20,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      {...props}
    >
      <path
        d="M10 1a12.333 12.333 0 0 0 8.736 3.083A12.333 12.333 0 0 1 9.999 19.5 12.333 12.333 0 0 1 1.263 4.083C4.465 4.23 7.6 3.124 10 1Z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.25a1.028 1.028 0 1 0 0-2.055 1.028 1.028 0 0 0 0 2.055ZM10 10.25v2.57"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
