export default function ColmacoIcon({ width = 30, height = 31, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 31"
      {...props}
    >
      <path
        d="M0 15.295C0 23.7423 6.84783 30.5901 15.295 30.5901C20.7433 30.5901 25.5262 27.7415 28.2356 23.4524C22.2163 27.6449 14.334 24.2508 11.0504 22.0296C8.86876 15.94 10.1967 11.2875 11.8566 8.67905C17.0973 1.68364 25.0848 4.52133 28.0827 6.90056C25.3485 2.74394 20.6422 0 15.295 0C6.84783 0 0 6.84783 0 15.295Z"
        fill="#0C4C9C"
      />
      <path
        d="M20.8676 14.963C21.5885 10.9602 18.4805 7.87278 16.8601 6.75826C21.9822 5.07462 26.4482 7.14557 28.9301 8.46561C27.2038 8.46561 26.377 9.20863 26.2031 9.58014C29.0108 11.8376 29.7917 15.1765 29.9261 16.789C30.5521 22.2335 27.0805 26.0371 23.9978 27.0805C11.6432 30.2581 9.46155 20.5989 9.95953 15.4847C12.6154 24.1922 19.0891 22.8596 21.9822 21.1048C26.7248 17.3296 25.0965 12.9079 23.6895 11.1689C22.5323 11.4725 21.3419 13.7774 20.8676 14.963Z"
        fill="#E4241C"
      />
    </svg>
  );
}
