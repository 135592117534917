export default function PuestoIcon({
  width = 18,
  height = 22,
  stroke = '#4082F6',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 22"
      {...props}
    >
      <path
        d="M7.754 9.421a4.21 4.21 0 1 0 0-8.421 4.21 4.21 0 0 0 0 8.421ZM1.438 19.948v-2.106a4.21 4.21 0 0 1 4.21-4.21h1.053M12.49 18.895a2.632 2.632 0 1 0 0-5.263 2.632 2.632 0 0 0 0 5.263ZM14.596 18.369 17.227 21"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
