import { useState } from 'react';
import {
  Fab, Modal, styled, Box, Fade, 
} from '@mui/material';
import useEmpleado from 'hooks/useEmpleado';
import ChatBot from '@cooperativaobrera/react-chat-bot';
import messageSound from 'assets/sounds/message.mp3';
import ChatbotIcon from 'assets/images/Chatbot.webp';
import ChatBotIcon from 'components/icons/ChatBot';
import useFirebase from 'hooks/useFirebase';
import nextStepNotFound from './nextStepNotFound';
import onFinish from './onFinish';

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 16,
  right: 16,
  zIndex: 1,
  backgroundColor: theme.palette.primary.main,
  transition: 'transform 300ms',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  padding: '10px',
  overflow: 'auto',
  '& .MuiModal-backdrop': {
    backgroundColor: 'transparent',
  },
}));

const svg = `
  <svg
      width="56"
      height="56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      view-box="0 0 56 56"
    >
      <circle cx="28" cy="28" r="28" fill="#EFEFEF" />
      <path
        d="M27.929 33.385a8.192 8.192 0 1 0 0-16.385 8.192 8.192 0 0 0 0 16.385ZM12 49.356a10.923 10.923 0 0 1 10.464-7.78h10.923a10.924 10.924 0 0 1 10.47 7.797"
        stroke="#8B8B8B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>`;
const blob = new Blob([svg], { type: 'image/svg+xml' });
const defaultAvatarUrl = URL.createObjectURL(blob);

const FloatChatbot = ({ firstSteps }) => {
  const {
    state: { usuario },
  } = useEmpleado();
  const {
    state: { firebaseInstance },
  } = useFirebase();
  const [open, setOpen] = useState(false);
  const firstStepsCopy = JSON.parse(JSON.stringify(firstSteps));
  const [steps, setSteps] = useState(firstStepsCopy);

  const onOpen = () => {
    setOpen(true);
    firebaseInstance?.logEvent('abro-chatbot');
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledModal keepMounted open={open} onClose={onClose} closeAfterTransition>
        <Fade in={open}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              width: '100%',
              maxWidth: 400,
              maxHeight: '70%',
              display: 'flex',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <ChatBot
              steps={steps}
              botName="Asistente virtual"
              botAvatar={ChatbotIcon}
              userAvatar={usuario.foto || defaultAvatarUrl}
              nextStepNotFound={(trigger) => {
                const stepClick = steps
                  .slice(-1)[0]
                  ?.options?.find((option) => option.trigger === trigger);

                if (stepClick) {
                  firebaseInstance?.logEvent('obtengo-nodo-chatbot', {
                    text: stepClick.label,
                  });
                }

                return nextStepNotFound(trigger, setSteps);
              }}
              onClose={onClose}
              onFinish={onFinish}
              language="es"
              messageSound={open ? messageSound : undefined}
              navigationButtons
            />
          </Box>
        </Fade>
      </StyledModal>
      <StyledFab onClick={onOpen} color="secondary" aria-label="add">
        <ChatBotIcon />
      </StyledFab>
    </>
  );
};

export default FloatChatbot;
