import Modal from 'components/modal/Modal2/ModalComponent';
import { Typography } from '@mui/material';
import ModalHeader from 'components/modal/Modal2/ModalHeaderComponent';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CheckingAssociateModal() {
  const [renderModal, setRenderModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderModal(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    renderModal && (
      <Modal
        sx={{
          maxWidth: {
            xs: '392px',
            sm: '600px',
          },
        }}
        open
      >
        <ModalHeader>
          <Typography variant="h6" fontWeight={600}>
            {t('pages.tarjeta_empleado.casa_personal.modales.comprobar_asociado.titulo')}
          </Typography>
        </ModalHeader>
      </Modal>
    )
  );
}
