/* eslint-disable no-restricted-syntax */
import pages from 'constants/pages';

export default function getPagePermissions(pathname) {
  const path = pathname.split('/').filter(Boolean);
  let currentPage = pages;
  let permissions = null;

  for (const segment of path) {
    if (currentPage && currentPage.children && currentPage.children[segment]) {
      currentPage = currentPage.children[segment];
      permissions = currentPage.permissions;
    } else {
      permissions = undefined;
      break;
    }
  }

  return permissions;
}
