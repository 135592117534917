import cargarCryptoAPINavegador from './cargarCryptoAPINavegador';

export function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const { byteLength } = bytes;
  for (let i = 0; i < byteLength; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
}

export function base64ToArrayBuffer(base64String) {
  const binaryString = window.atob(base64String);
  const { length } = binaryString;
  const arrayBuffer = new ArrayBuffer(length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < length; i += 1) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  return arrayBuffer;
}

// Legajo viene como string con padding a 6 digitos
export function generarIV(legajo) {
  const cryptoLibrary = cargarCryptoAPINavegador();

  const iv = new Uint8Array(12);
  const hashBuffer = cryptoLibrary.digest('SHA-256', new TextEncoder().encode(legajo));
  const hashArray = new Uint8Array(hashBuffer);
  // Toma los primeros 12 bits para el iv
  for (let i = 0; i < Math.min(12, hashArray.length); i += 1) {
    iv[i] = hashArray[i];
  }
  return iv;
}
