import Modal from 'components/modal/Modal2/ModalComponent';
import { Button, Typography } from '@mui/material';
import ModalHeader from 'components/modal/Modal2/ModalHeaderComponent';
import ModalActions from 'components/modal/Modal2/ModalActionsComponent';
import ModalContent from 'components/modal/Modal2/ModalContentComponent';
import { useTranslation } from 'react-i18next';

export default function CancelarSolicitudModal({
  open, handleClose, data, cancelarSolicitud, 
}) {
  const { t } = useTranslation();

  const handleCancelarSolicitud = () => {
    cancelarSolicitud(data.id);
    handleClose();
  };

  return (
    <Modal
      sx={{
        maxWidth: {
          xs: '392px',
          sm: '600px',
        },
      }}
      open={open}
    >
      <ModalHeader>
        <Typography variant="h6" fontWeight={600}>
          ¿Deseas cancelar el uso del salón?
        </Typography>
      </ModalHeader>
      <ModalContent>
        <Typography>
          Recordá que si anulas la reserva, 
          {' '}
          <Typography component="span" fontWeight={500}>
            se registrará como si el salón hubiera sido utilizado y se aplicará
            el correspondiente cargo por uso
          </Typography>
          {' '}
          dado que falta menos de 7 días para el evento.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={handleCancelarSolicitud}
          size="small"
        >
          {t('botones.cancelar_reserva')}
        </Button>
        <Button
          variant="outlined"
          color="tertiary"
          type="button"
          onClick={handleClose}
          size="small"
        >
          {t('botones.volver')}
        </Button>
      </ModalActions>
    </Modal>
  );
}
