import { CARACTERES_TOTAL_CELULAR } from 'constants';
import * as Yup from 'yup';

const datosPersonalesSchema = Yup.object({
  codigoArea: Yup.string()
    .required('Ingresá tu codigo de area')
    .min(2, 'Debe tener como minimo 2 digitos')
    .max(4, 'Debe tener como maximo 4 digitos'),
  numeroCelular: Yup.string()
    .when('codigoArea', (codigoArea, field) => {
      const codigoAreaString = codigoArea[0];
      const equalsLengthTo = codigoAreaString
        ? CARACTERES_TOTAL_CELULAR - codigoAreaString.length
        : 10;

      if (equalsLengthTo <= 8 && equalsLengthTo >= 6) {
        return field
          .min(equalsLengthTo, `Debe tener ${equalsLengthTo} caracteres`)
          .max(equalsLengthTo, `Debe tener ${equalsLengthTo} caracteres`);
      }

      return field;
    })
    .required('Ingresá tu numero de celular'),
  /* direccion: Yup.string()
    .min(3, 'Debe tener como minimo 3 caracteres')
    .max(150, 'Debe tener como maximo 150 caracteres')
    .required('Ingresa tu dirección'), */
  correoPersonal: Yup.string()
    .email('El correo no es valido')
    .required('Ingresa tu correo electrónico personal'),
});

export default datosPersonalesSchema;
