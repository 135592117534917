import { IconFileDownload } from '@cooperativaobrera/react-icons/tabler-icons';
import {
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  Typography,
  alpha,
} from '@mui/material';
import useRecibo from 'pages/recibo/hooks/useRecibo';
import Card from 'components/card/CardComponent';
import convertYearMonthToFormattedDate from 'utils/convertYearMonthToFormattedDate';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    padding: 1,
  },
  button: {
    width: '100%',
    p: 1,
    borderRadius: '10px',
    transition: 'background-color 0.1s, color 0.1s',
    '&[data-selected=true]': {
      backgroundColor: 'secondary.main',
      '& p': {
        color: 'common.white',
      },
    },
    justifyContent: 'start',
  },
};

const descargarRecibo = (file, periodo, tipo) => {
  const nombrePeriodo = convertYearMonthToFormattedDate(String(periodo));
  const nombreArchivo = tipo === 2 ? nombrePeriodo.concat(' - ', 'Aguinaldo') : nombrePeriodo;
  const link = document.createElement('a');
  link.href = `data:application/pdf;base64,${file}`;
  link.setAttribute('download', nombreArchivo);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function SeleccionarReciboCard({ keyPrivadaEmpleado }) {
  const { t } = useTranslation();
  const {
    state: { recibos, reciboSeleccionado },
    obtenerReciboDesencriptado,
    seleccionarRecibo,
  } = useRecibo();

  useEffect(() => {
    if (recibos.length > 0 && !reciboSeleccionado) {
      // Preselecciono el primer recibo
      const ultimoRecibo = recibos[0];
      obtenerReciboDesencriptado(
        ultimoRecibo.periodo,
        ultimoRecibo.tipo,
        ultimoRecibo.recibo_empleado_key,
        keyPrivadaEmpleado,
      ).then((file) => {
        seleccionarRecibo(ultimoRecibo.periodo, ultimoRecibo.tipo, file);
      });
    }
  }, [recibos]);

  const handleClickDescargarRecibo = (recibo) => {
    if (recibo.periodo === reciboSeleccionado.periodo && recibo.tipo === reciboSeleccionado.tipo) {
      descargarRecibo(reciboSeleccionado.file, reciboSeleccionado.periodo, reciboSeleccionado.tipo);
    } else {
      obtenerReciboDesencriptado(
        recibo.periodo,
        recibo.tipo,
        recibo.recibo_empleado_key,
        keyPrivadaEmpleado,
      ).then((reciboBase64) => {
        descargarRecibo(reciboBase64, recibo.periodo, recibo.tipo);
      });
    }
  };

  const handleSeleccionarRecibo = (recibo) => {
    const seleccion = recibos.find(
      (rec) => rec.periodo === recibo.periodo && rec.tipo === recibo.tipo && rec.file,
    );
    if (seleccion) {
      seleccionarRecibo(recibo.periodo, recibo.tipo, recibo.file);
    } else {
      obtenerReciboDesencriptado(
        recibo.periodo,
        recibo.tipo,
        recibo.recibo_empleado_key,
        keyPrivadaEmpleado,
      ).then((file) => {
        seleccionarRecibo(recibo.periodo, recibo.tipo, file);
      });
    }
  };

  return (
    <Card padding={{ xs: 0 }}>
      <Grid container p={2}>
        <Grid item xs={8} lg={9}>
          <Typography variant="body2" fontWeight={600}>
            {t('pages.recibo.header.periodo')}
          </Typography>
        </Grid>
        <Grid item xs={4} lg={3} sx={styles.center}>
          <Typography variant="body2" fontWeight={600}>
            {t('pages.recibo.header.descargar')}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      {recibos.length > 0
        ? (recibos?.map((recibo) => (
          <Grid container sx={styles.container} key={recibo.periodo + recibo.tipo}>
            <Grid item xs={8} lg={9}>
              <ButtonBase
                sx={styles.button}
                data-selected={
                  reciboSeleccionado?.periodo === recibo.periodo
                  && reciboSeleccionado?.tipo === recibo.tipo
                }
                onClick={() => handleSeleccionarRecibo(recibo)}
              >
                <Typography>
                  {convertYearMonthToFormattedDate(String(recibo.periodo))}
                  {recibo.tipo === 2 ? ' Aguinaldo' : ''}
                </Typography>
              </ButtonBase>
            </Grid>
            <Grid item xs={4} lg={3} sx={styles.center}>
              <IconButton
                onClick={() => handleClickDescargarRecibo(recibo)}
              >
                <IconFileDownload />
              </IconButton>
            </Grid>
          </Grid>
        ))
        ) : (
          <Grid item xs={12} sx={styles.center} height={200} p={2}>
            <Typography variant="body2" color={(theme) => alpha(theme.palette.text.primary, 0.6)}>
              {t('pages.recibo.sin_recibos')}
            </Typography>
          </Grid>
        )}
    </Card>
  );
}
