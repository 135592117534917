import { Box, Typography } from '@mui/material';

/**
 * Componente para mostrar datos con label - valor
 * @param label El texto que acompaña al dato
 * @param value El dato numérico
 */
export default function ListItem2({
  label,
  labelVariant = 'body2',
  labelColor = 'accent.main',
  value,
  valueVariant = 'body1',
  valueColor = 'text.primary',
}) {
  return (
    <Box>
      <Typography color={labelColor} variant={labelVariant}>
        {label}
      </Typography>
      <Typography color={valueColor} variant={valueVariant}>
        {value}
      </Typography>
    </Box>
  );
}
