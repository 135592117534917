export default function BorlenghiIcon({ width = 35, height = 32, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 32"
      {...props}
    >
      <path
        d="M18 0.0644876C12.7628 -0.415403 2.34267 1.00678 11.8678 32C9.54029 0.718916 14.1057 2.46394 18 0.0644876Z"
        fill="#E8E83C"
      />
      <path
        d="M10.4865 2C4.72492 3.01879 -3.23854 10.4447 11 32C7.83405 23.9367 3.29882 6.64854 10.4865 2Z"
        fill="#F16C2B"
      />
      <path
        d="M9.82952 31.8382L10 32C9.95078 31.9639 9.89372 31.9097 9.82952 31.8382C4.31949 26.5834 -4.55382 14.9218 2.78301 9C2.15672 11.1618 1.60496 16.8164 4.40581 22.1428C5.82745 24.9946 8.7788 30.6716 9.82916 31.8379L9.82952 31.8382Z"
        fill="#EC2B2C"
      />
      <path
        d="M24 1.25128C13.477 -5.72638 11.6488 18.1763 12.0502 31C12.0502 28.2023 15.3201 15.0678 16.955 8.84993C18.7389 2.52896 22.3949 1.1503 24 1.25128Z"
        fill="#3EB449"
      />
      <path
        d="M21.2439 6.20715C24.2931 0.728157 27.6851 3.49101 29 5.55726C23.0786 3.26834 15.8659 22.2317 13 32C13.4253 24.7172 18.673 11.7701 21.2439 6.20715Z"
        fill="#42C1F5"
      />
      <path
        d="M26.1407 7.59735C29.1756 4.22496 31.3117 6.96642 32 8.75897C28.0884 5.4552 18.37 22.2097 14 31C15.3154 25.6313 21.7985 13.104 26.1407 7.59735Z"
        fill="#33489B"
      />
      <path
        d="M15 31C20.5838 20.9415 32.4007 3.20281 35 12.7182C31.9196 10.9117 22.9326 19.7932 15 31Z"
        fill="#663092"
      />
    </svg>
  );
}
