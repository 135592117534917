import { useState, useEffect } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
  cuentaAtras: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};

const CuentaAtras = ({ tiempo, alExpirar }) => {
  const { t } = useTranslation();
  const [progreso, setProgreso] = useState(100);
  const [temporizador, setTemporizador] = useState(
    `${new Date(tiempo * 1000).toISOString().substring(14, 19)}`,
  );

  if (temporizador === '00:00') {
    alExpirar.accion();
  }

  const reiniciar = () => {
    setProgreso(100);
    setTemporizador(
      `${new Date(tiempo * 1000).toISOString().substring(14, 19)}`,
    );
  };

  useEffect(() => {
    let time = tiempo;
    const id = setInterval(() => {
      if (time === 0) {
        // Ejecutar la acción al expirar.
        if (alExpirar.accion) {
          alExpirar.accion();
        }
        if (alExpirar.reiniciar) {
          time = tiempo;
          reiniciar();
        } else return clearInterval(id);
      } else {
        time -= 1;
        const progresoActualizado = Math.trunc((time * 100) / tiempo);
        if (progresoActualizado !== progreso) setProgreso(progresoActualizado);
        setTemporizador(
          `${new Date(time * 1000).toISOString().substring(14, 19)}`,
        );
      }
      return null;
    }, 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <div style={styles.cuentaAtras}>
      <LinearProgress variant="determinate" value={progreso} />
      <Typography
        variant="body2"
        sx={{
          alignSelf: 'center',
          marginTop: '20px',
        }}
      >
        {`${t('componentes.cuenta_atras.duracion')} ${temporizador}`}
      </Typography>
    </div>
  );
};

export default CuentaAtras;
