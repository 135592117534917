import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { PERMISO_CERTIFICADO_LABORAL } from 'constants/permisos';
import useUserHasPermission from 'hooks/useUserHasPermission';
import Card from 'components/card/CardComponent';
import InfoItemIconContainer from 'components/infoItemIconContainer/InfoItemIconContainer';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import CertificadoLaboralIcon from 'components/icons/CertificadoLaboral';
import { CERTIFICADO_LABORAL } from '../constants';

const sections = [
  {
    id: 1,
    text: 'Certificación laboral',
    permiso: PERMISO_CERTIFICADO_LABORAL,
    childrens: [
      {
        id: CERTIFICADO_LABORAL,
        text: 'Solicitar certificado laboral',
        icon: <CertificadoLaboralIcon />,
      },
    ],
  },
];

const styles = {
  button: {
    iconTextContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    width: '100%',
    justifyContent: 'space-between',
  },
};

export default function SeleccionarSolicitudCard({ handleClickSolicitud }) {
  return (
    <Card>
      <Grid container spacing={2}>
        {sections.filter((section) => {
          if (useUserHasPermission(section.permiso)) {
            return true;
          }

          return false;
        }).map((section, i) => (
          <Grid key={section.id} xs={12} item>
            <Grid spacing={2} container>
              {i !== 0 && (
                <Grid xs={12} item>
                  <Divider />
                </Grid>
              )}
              <Grid xs={12} item>
                <Typography variant="body2" color="secondary">
                  {section.text}
                </Typography>
              </Grid>
              {section.childrens.map((children) => (
                <Grid key={children.id} xs={12} item>
                  <ButtonBase
                    onClick={() => handleClickSolicitud(children.id)}
                    sx={styles.button}
                    disableRipple
                  >
                    <Box sx={styles.button.iconTextContainer}>
                      <InfoItemIconContainer
                        icon={children.icon}
                        size="small"
                      />
                      <Typography textAlign="left" variant="body2">
                        {children.text}
                      </Typography>
                    </Box>
                    <ArrowRightIcon />
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}
