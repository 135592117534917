import { alpha, styled } from '@mui/material';

const shouldForwardProp = [
  'fullHeight',
  'fullWidth',
  'width',
  'color',
  'noHover',
  'underline',
  'sx',
];

const MyButton = styled('button', {
  shouldForwardProp: (prop) => !shouldForwardProp.includes(prop),
})(({
  theme, fullHeight, fullWidth, width = 'none', color, noHover = false, underline = false,
}) => ({
  height: fullHeight ? '100%' : '56px',
  width: fullWidth ? '100%' : width,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  borderRadius: '10px',
  cursor: 'pointer',
  transition: 'background-color 0.1s',
  padding: noHover ? 0 : '0 20px',

  fontSize: '1.1rem',
  fontFamily: 'Inter',
  color: color || theme.palette.primary.main,
  textDecoration: underline ? 'underline' : 'none',

  [theme.breakpoints.down('md')]: {
    height: fullHeight ? '100%' : '40px',
    fontSize: '0.9rem',
  },

  '&:focus': {
    // focus
  },
  '&:hover': {
    // hover
    backgroundColor: noHover
      ? 'transparent'
      : alpha(color || theme.palette.primary.main, 0.1),
  },
  '&:disabled': {
    // deshabilitado
  },
  '&[data-errors=false]': {
    // error false
  },
  '&[data-errors=true]': {
    // error true
  },
}));

export default function TextButton({
  children,
  type = 'button',
  ...props
}) {
  return (
    <MyButton {...props} type={type}>
      {children}
    </MyButton>
  );
}
