import {
  Box, Card, Grid, Typography, alpha, 
} from '@mui/material';
import { IconCalendar } from '@cooperativaobrera/react-icons/tabler-icons';
import ListItem2 from 'components/infoItem/ListItem2Component';
import {
  ESTADO_CANCELADO, ESTADO_INCISO_51, ESTADO_NO_OTORGADO, ESTADO_OTORGADO, ESTADO_SOLICITADO, 
} from '../../constants';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    /* alignItems: 'stretch',
    justifyContent: 'space-between', */
    p: 2,
    item1: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5,
    },
    item2: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 0.5,
    },
  },
  estadoContainer: {
    borderRadius: '15px',
    p: 1,
    '&[data-estado="info"]': {
      backgroundColor: (theme) => alpha(theme.palette.info.main, 0.2),
      '& p': {
        color: (theme) => theme.palette.info.main,
      },
    },
    '&[data-estado="error"]': {
      backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
      '& p': {
        color: (theme) => theme.palette.error.main,
      },
    },
    '&[data-estado="warning"]': {
      backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.2),
      '& p': {
        color: (theme) => theme.palette.warning.main,
      },
    },
    '&[data-estado="success"]': {
      backgroundColor: (theme) => alpha(theme.palette.success.main, 0.2),
      '& p': {
        color: (theme) => theme.palette.success.main,
      },
    },
  },
};

function getStatusColor(id) {
  switch (Number(id)) {
    case ESTADO_SOLICITADO:
      return 'info';
    case ESTADO_CANCELADO:
    case ESTADO_INCISO_51:
      return 'error';
    case ESTADO_NO_OTORGADO:
      return 'warning';
    case ESTADO_OTORGADO:
      return 'success';
    default:
      return 'success';
  }
}

export default function Solicitud({ solicitud, estados }) {
  const estado = estados.find((e) => e.value === solicitud.status);

  return (
    <Grid xs={12} item>
      <Card sx={styles.container}>
        <Grid spacing={1} container>
          <Grid xs={12} item>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box display="flex" alignItems="center" gap={0.5}>
                <IconCalendar sx={{ color: (theme) => theme.palette.text.main, fontSize: 22 }} />
                <Typography fontWeight={600} variant="body2">{solicitud.fecha}</Typography>
              </Box>
              <Box data-estado={getStatusColor(solicitud.status)} sx={styles.estadoContainer}>
                <Typography variant="body2">
                  {estado.label}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <ListItem2 label="Salón" value={solicitud.salon} />
          </Grid>
          <Grid xs={6} item>
            <ListItem2 label="Turno" value={solicitud.turno} />
          </Grid>
          <Grid xs={6} item>
            <ListItem2 label="Motivo" value={solicitud.motivo} />
          </Grid>
          <Grid xs={6} item>
            <ListItem2 label="Solicitudes" value={solicitud.solicitudes} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
