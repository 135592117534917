export default function ChatBotIcon({ width = 56, height = 56, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 207 207"
      {...props}
    >
      <path
        fill="#ADC9E3"
        stroke="#ADC9E3"
        strokeWidth={0.31}
        d="M38.155 129.889c0 10.849 8.795 19.644 19.644 19.644a3.913 3.913 0 0 0 3.913-3.914v-28.785H38.155v13.055ZM167.845 129.889c0 10.849-8.795 19.644-19.644 19.644a3.913 3.913 0 0 1-3.913-3.914v-28.785h23.557v13.055Z"
      />
      <path
        fill="#E12B22"
        stroke="#E12B22"
        strokeWidth={0.31}
        d="M38.155 112.107c0-12.394 10.047-22.44 22.44-22.44h2.895v27.112H38.155v-4.672ZM167.845 112.107c0-12.394-10.047-22.44-22.44-22.44h-2.895v27.112h25.335v-4.672Z"
      />
      <circle
        cx={53.742}
        cy={76.563}
        r={3.4}
        fill="#ADC9E3"
        stroke="#ADC9E3"
        strokeWidth={0.31}
      />
      <circle
        cx={152.258}
        cy={76.563}
        r={3.4}
        fill="#ADC9E3"
        stroke="#ADC9E3"
        strokeWidth={0.31}
      />
      <path
        fill="#D0E3F0"
        stroke="#D0E3F0"
        strokeWidth={0.31}
        d="M53.897 76.214c0-19.915 16.144-36.06 36.06-36.06h26.087c19.915 0 36.059 16.145 36.059 36.06v42.831c0 16.838-13.65 30.488-30.488 30.488h-37.23c-16.838 0-30.488-13.65-30.488-30.488v-42.83Z"
      />
      <path
        fill="#F0F8FA"
        stroke="#F0F8FA"
        strokeWidth={0.31}
        d="M53.897 76.214c0-19.915 16.144-36.06 36.06-36.06h13.142v109.379H84.385c-16.838 0-30.488-13.65-30.488-30.488v-42.83Z"
      />
      <rect
        width={64.691}
        height={48.187}
        x={70.655}
        y={56.151}
        fill="#084E93"
        stroke="#084E93"
        strokeWidth={0.31}
        rx={17.179}
      />
      <path
        fill="#0B58A4"
        stroke="#0B58A4"
        strokeWidth={0.31}
        d="M70.655 73.33c0-9.488 7.69-17.18 17.178-17.18H103.1v48.187H87.833c-9.487 0-17.178-7.69-17.178-17.178v-13.83Z"
      />
      <path
        fill="#D0E3F0"
        stroke="#D0E3F0"
        strokeWidth={0.31}
        d="M75.733 133.172c0-8.462 6.86-15.322 15.321-15.322H103.1v31.683H79.602a3.869 3.869 0 0 1-3.87-3.869v-12.492Z"
      />
      <path
        fill="#ADC9E3"
        stroke="#ADC9E3"
        strokeWidth={0.31}
        d="M130.521 133.172c0-8.462-6.86-15.322-15.321-15.322h-12.045v31.683h23.497a3.869 3.869 0 0 0 3.869-3.869v-12.492Z"
      />
      <path
        fill="#0B58A4"
        stroke="#0B58A4"
        strokeWidth={0.31}
        d="M82.842 149.588H103.1v20.257h-7.876c-6.838 0-12.38-5.543-12.38-12.381v-7.876Z"
      />
      <path
        fill="#084E93"
        stroke="#084E93"
        strokeWidth={0.31}
        d="M123.412 149.588h-20.257v20.257h7.876c6.838 0 12.381-5.543 12.381-12.381v-7.876Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7.119}
        d="m88.527 80.752 3.428-3.174 3.174 3.174M110.871 80.752l3.428-3.174 3.174 3.174"
      />
    </svg>
  );
}
