/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import {
  DataGridPro,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { IconDotsVertical } from '@cooperativaobrera/react-icons/tabler-icons';

const autosizeOptions = {
  includeHeaders: true,
  includeOutliers: true,
};

const BORDER_WHITE = '8px solid white';
const BORDER_RADIUS = 10;
const H56IMPORTANT = '56px !important';

const groupsStyles = {
  '& .MuiDataGrid-columnHeaders': {
    height: H56IMPORTANT,
  },
  '.MuiDataGrid-columnHeadersInner': {
    height: H56IMPORTANT,
  },
  '& .MuiDataGrid-columnHeader--filledGroup': {
    paddingLeft: 4,
  },
  '& .MuiDataGrid-columnHeader--emptyGroup': {
    paddingLeft: 4,
  },
  '& .MuiDataGrid-columnHeader--filledGroup:nth-last-of-type(1)': {
    paddingLeft: 4,
  },
  '& .MuiDataGrid-columnHeadersInner > div:first-of-type': {
    height: '20px !important',
  },
  '& .MuiDataGrid-groupingTitle': {
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      border: 'none',
      color: '#676767',
      fontWeight: 'normal',
      fontSize: 12,
      padding: 0,
    },
  },
};

const StyledDataGrid = styled(DataGridPro)(({ theme, groupColumns }) => ({
  ...(groupColumns ? groupsStyles : {}),
  border: 'none',
  '& div .MuiDataGrid-columnHeaders': {
    minHeight: '30px !important',
  },
  '& :is(.MuiDataGrid-columnHeadersInner, .MuiDataGrid-pinnedColumnHeaders) > div > .MuiDataGrid-columnHeader':
    {
      height: '30px !important',
    },
  '& :is(.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns)': {
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    lineHeight: 1.2,
  },
  '& .MuiDataGrid-columnHeaders': {
    // marginBottom: 8,
  },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    maxWidth: '100%',
  },
  '& .MuiDataGrid-columnHeader': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 4,
    paddinButton: 8,
    // marginRight: 5,
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 500,
    },
  },
  '& .MuiDataGrid-cell': {
    padding: '0 10px', // Crea el margin del contenido en la celda
    fontSize: 14,
    border: 'none',
  },
  '& .MuiDataGrid-cellCheckbox': {
    minWidth: '25px !important',
  },
  '& .MuiCheckbox-root': {
    padding: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  },
  '& .MuiCheckbox-root.Mui-disabled': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderCheckbox  ': {
    width: '0px !important',
    minWidth: '25px !important',
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none',
    },
  },
  '& .MuiDataGrid-row': {
    '& .MuiDataGrid-cell.Default-cell': {
      borderRight: BORDER_WHITE, // Ocasiona problema del calculo
      borderRadius: BORDER_RADIUS,
      borderTopRightRadius: 14,
      borderBottomRightRadius: 14,
    },
    '& .MuiDataGrid-cell.Alert-cell': {
      borderRight: BORDER_WHITE, // Ocasiona problema del calculo
      borderRadius: BORDER_RADIUS,
      borderTopRightRadius: 14,
      borderBottomRightRadius: 14,
      backgroundColor: theme.palette.warning.main,
    },
    '& .MuiDataGrid-cell.Error-cell': {
      borderRight: BORDER_WHITE, // Ocasiona problema del calculo
      borderRadius: BORDER_RADIUS,
      borderTopRightRadius: 14,
      borderBottomRightRadius: 14,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    '&:nth-of-type(odd)': {
      '& .MuiDataGrid-cell.Default-cell': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    },
    '&:nth-of-type(even)': {
      '& .MuiDataGrid-cell.Default-cell': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
    },
    '&:hover': {
      borderRadius: 5,
    },
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'none',
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none',
    },
}));

function CustomActionsIcon() {
  return <IconDotsVertical fontSize="small" color="secondary" />;
}

function CustomEmptyMessage({ texto }) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingY: 3,
        borderColor: 'line.main',
      }}
    >
      <Typography variant="body2">{texto}</Typography>
    </Box>
  );
}

const CustomToolbar = (props) => {
  const apiRef = useGridApiContext();

  if (props.withoutHeader) {
    return null;
  }

  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between', px: 0 }}>
      <GridToolbarQuickFilter {...props.quickFilterProps} />
      <Typography variant="body2">
        Cantidad de resultados: 
        {' '}
        {apiRef.current.getRowsCount()}
      </Typography>
    </GridToolbarContainer>
  );
};

const slots = {
  moreActionsIcon: CustomActionsIcon,
  noRowsOverlay: CustomEmptyMessage,
  toolbar: CustomToolbar,
};

export default function DataGrid({
  columns,
  rows,
  apiRef,
  textoSinResultados = 'No se encontraron resultados de la búsqueda.',
  withoutHeader,
  initialState,
  disableAutosize,
  columnGroupingModel,
  columnGrouping = false,
  enableRowSelection = false,
  ...props
}) {
  const getRowSpacing = useCallback((params) => {
    return {
      // top: params.isFirstVisible ? 0 : 2,
      bottom: params.isLastVisible ? 0 : 8,
    };
  }, []);

  return (
    <StyledDataGrid
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      getRowSpacing={getRowSpacing}
      initialState={initialState}
      rowHeight={40}
      pageSizeOptions={[100]}
      hideFooter
      disableColumnMenu
      autoHeight={false}
      slots={slots}
      slotProps={{
        noRowsOverlay: { texto: textoSinResultados },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: {
            debounceMs: 500,
            variant: 'outlined',
            size: 'small',
            sx: {
              '.MuiOutlinedInput-root': {
                height: '30px',
                fontSize: '14px',
              },
            },
          },
          withoutHeader,
        },
      }}
      disableColumnReorder
      autosizeOptions={autosizeOptions}
      localeText={{
        toolbarQuickFilterPlaceholder: 'Buscar...',
      }}
      disableAutosize={disableAutosize}
      experimentalFeatures={{ columnGrouping }}
      columnGroupingModel={columnGroupingModel}
      groupColumns={!!columnGroupingModel}
      rowSelection={enableRowSelection}
      checkboxSelection={enableRowSelection}
      disableRowSelectionOnClick={!enableRowSelection}
      {...props}
    />
  );
}
