import {
  alpha,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import { useTranslation } from 'react-i18next';
import dateStringToFormatedDate from 'utils/dateStringToFormatedDate';
import { getPdfSolicitud } from 'services/CertificadoLaboralService';
import { getEstadoCertificado } from '../utils';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    item: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5,
    },
  },
  estadoContainer: {
    borderRadius: '15px',
    p: 1,
    '&[data-estado="0"]': {
      backgroundColor: (theme) => alpha(theme.palette.success.main, 0.2),
      '& p': {
        color: (theme) => theme.palette.success.main,
      },
    },
    '&[data-estado="1"]': {
      backgroundColor: (theme) => alpha(theme.palette.info.main, 0.2),
      '& p': {
        color: (theme) => theme.palette.info.main,
      },
    },
    '&[data-estado="2"]': {
      backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
      '& p': {
        color: (theme) => theme.palette.error.main,
      },
    },
  },
};

export default function HistorialSolicitudesMobile({ historial }) {
  const { t } = useTranslation();

  const descargarCertificadoLaboral = (id) => {
    getPdfSolicitud(id).then((res) => {
      const { base64, mime, name } = res.data.data;
      const link = document.createElement('a');
      link.href = `data:${mime};base64,${base64}`;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <Grid spacing={2} container>
      {historial.map((item) => (
        <Grid key={item.id} xs={12} item>
          <Card sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <Box sx={styles.container}>
              <Box sx={styles.container.item}>
                <Typography variant="body2">
                  {dateStringToFormatedDate(item.fecha)}
                </Typography>
                <Typography
                  fontWeight="500"
                  color="text.secondary"
                  variant="body2"
                >
                  {item.presentado_ante}
                </Typography>
                {item.observacion && (
                  <Typography variant="body2">
                    Observación: 
                    {' '}
                    {item.observacion}
                  </Typography>
                )}
              </Box>
              <Box sx={{ ...styles.container.item, alignItems: 'end' }}>
                <Box data-estado={item.estado} sx={styles.estadoContainer}>
                  <Typography variant="body2">
                    {getEstadoCertificado(item.estado, t)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {item.descargar && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => descargarCertificadoLaboral(item.id)}
                fullWidth
              >
                {t('botones.descargar')}
              </Button>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
