/* eslint-disable react/jsx-no-bind */
import { TextField } from '@mui/material';

export default function FormikTextField({
  autoComplete,
  defaultValue,
  disabled,
  formik,
  id,
  label,
  placeholder,
  required,
  size,
  type,
  validate,
  hideErrorMessage,
  ...props
}) {
  function handleChange(e) {
    const { value } = e.target;

    if (validate) {
      if (validate(value)) {
        formik.handleChange(e);
      }
    } else {
      formik.handleChange(e);
    }
  }

  return (
    <TextField
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      disabled={disabled}
      value={formik.values[id]}
      error={
        formik.touched[id]
        && !Array.isArray(formik.errors[id])
        && Boolean(formik.errors[id])
      }
      helperText={
        formik.touched[id]
        && !Array.isArray(formik.errors[id])
        && !hideErrorMessage
        && formik.errors[id]
      }
      onChange={handleChange}
      id={id}
      label={label}
      placeholder={placeholder}
      required={required}
      size={size}
      type={type}
      fullWidth
      {...props}
    />
  );
}
