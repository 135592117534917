/* eslint-disable import/no-cycle */
import qs from 'qs';
import authApi from 'apis/AuthApi';
import empleadosApi from 'apis/EmpleadosApi';
import { API_AUTH_CLIENT_ID, API_AUTH_GRAND_TYPE } from '../constants';

/**
 * Realiza el login con Coopeusuario
 */
export const loginCU = async (nro_documento, password, tipo_documento) => {
  const info = {
    grant_type: API_AUTH_GRAND_TYPE,
    client_id: API_AUTH_CLIENT_ID,
    id_tipo_documento: tipo_documento,
    nro_documento,
    password,
  };

  return authApi.post('/auth/token', qs.stringify(info));
};

/**
 * Realiza el login con empleo
 */
export const loginEmpleo = async (legajo) => {
  // Conexión con back propio
  const info = {
    legajo,
  };

  return empleadosApi.post('/auth/token', info);
};

export function getPermisosUsuarioAPI() {
  return empleadosApi.get('/auth/permisos');
}

export const resetPasswordRequestCU = async (nro_documento, tipo_documento) => {
  const info = {
    client_id: API_AUTH_CLIENT_ID,
    id_tipo_documento: tipo_documento,
    nro_documento,
  };

  return authApi.post('/auth/request_reset_password', qs.stringify(info));
};

export function changePasswordCU(password, new_password) {
  const info = {
    password,
    new_password,
  };

  return authApi.put('/users/me/change_password', qs.stringify(info));
}

export function sendVerifyEmailCU(new_email, password) {
  const info = {
    new_email,
    password,
  };

  return authApi.post('/users/me/validate_mail', qs.stringify(info));
}

export function changeValidatedEmailCU(codigo) {
  const info = {
    codigo,
  };

  return authApi.put('/users/me/change_validated_mail', qs.stringify(info));
}

export function updateUserPicture(picture) {
  return authApi.post(
    '/pictures',
    { picture },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );
}
