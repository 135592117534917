import * as Yup from 'yup';

const restablecerContraseñaSchema = Yup.object({
  documento: Yup.string()
    .test('len', 'El formato del documento no es válido', (val) => {
      if (val) {
        return val.length >= 7 && val.length <= 8 && !Number.isNaN(val);
      }
      return false;
    })
    .required('Ingresa tu documento'),
});

export default restablecerContraseñaSchema;
