import React from 'react';

import InicioIcon from 'components/icons/Inicio';
import AulaVirtualIcon from 'components/icons/AulaVirtual';
import CerrarSesionIcon from 'components/icons/CerrarSesion';
import MiPortalIcon from 'components/icons/MiPortal';
import {
  styled, IconButton, SwipeableDrawer, Box, Drawer as MuiDrawer,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VacacionesIcon from 'components/icons/Vacaciones';
import { ACCION, INTERNO, MOODLE } from 'constants/menu';
import SolicitudesIcon from 'components/icons/Solicitudes';
import MiPortalWithoutTextIcon from 'components/icons/MiPortalWithoutText';
import ReciboSueldoIcon from 'components/icons/ReciboSueldoIcon';
import TarjetaEmpleadoIcon from 'components/icons/TarjetaEmpleadoIcon';
import { TARJETA_EMPLEADO, TARJETA_JUBILADO, TARJETA_SERVICIO } from 'constants/tipoTarjeta';
import List from './List';
// import { ENVIRONMENT } from 'constants';

// const isNotProduction = ENVIRONMENT !== 'production';

const drawerWidth = {
  desktop: 350,
  mobile: 300,
};

const drawerOptions = (tarjeta) => {
  let tarjetaName = '';

  if (tarjeta === TARJETA_EMPLEADO) {
    tarjetaName = 'Tarjeta de empleado';
  } else if (tarjeta === TARJETA_SERVICIO) {
    tarjetaName = 'Tarjeta de servicio';
  } else if (tarjeta === TARJETA_JUBILADO) {
    tarjetaName = 'Tarjeta de jubilado';
  }

  return ({
    always: [
      {
        id: 1,
        icon: (
          <InicioIcon
            style={{
              width: '20px',
              minWidth: '20px',
            }}
          />
        ),
        text: 'Inicio',
        redirectTo: '/inicio',
        type: INTERNO,
      },
      {
        id: 9,
        icon: (
          <ReciboSueldoIcon
            width={20}
            height={20}
            strokeWidth={1.5}
          />
        ),
        text: 'Recibo de sueldo',
        redirectTo: '/recibo',
        type: INTERNO,
      },
      {
        id: 8,
        icon: (
          <TarjetaEmpleadoIcon
            style={{
              width: '20px',
              minWidth: '20px',
            }}
          />
        ),
        text: tarjetaName,
        redirectTo: '/tarjeta-empleado',
        type: INTERNO,
      },
      {
        id: 7,
        icon: (
          <VacacionesIcon
            style={{
              width: '20px',
              minWidth: '20px',
            }}
          />
        ),
        text: 'Vacaciones',
        redirectTo: '/vacaciones',
        type: INTERNO,
      },
      {
        id: 3,
        icon: (
          <AulaVirtualIcon
            style={{
              width: '20px',
              minWidth: '20px',
            }}
          />
        ),
        text: 'Aula virtual',
        redirectTo: MOODLE,
        type: ACCION,
      },
      {
        id: 5,
        icon: (
          <SolicitudesIcon
            style={{
              width: '20px',
              minWidth: '20px',
            }}
          />
        ),
        text: 'Solicitudes',
        redirectTo: '/solicitudes',
        type: INTERNO,
      },
    ],
    onMobile: [],
    fixedBottom: [
      {
        id: 6,
        icon: (
          <CerrarSesionIcon
            style={{
              width: '20px',
              minWidth: '20px',
            }}
          />
        ),
        text: 'Cerrar sesión',
        redirectTo: '/ingresar',
        type: INTERNO,
      },
    ],
  });
};

const openedMixin = (theme) => ({
  width: drawerWidth.desktop,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('md')]: {
    width: '88px',
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth.desktop,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '&  .MuiDrawer-paper': {
    border: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}));

function MiniDrawer({
  open,
  handleOpenDrawer,
  handleCloseDrawer,
  handleDrawerToggle,
  isMobile,
  tarjeta,
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      {isMobile ? (
        <SwipeableDrawer
          open={open}
          onClose={handleCloseDrawer}
          onOpen={handleOpenDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          disableBackdropTransition
          BackdropProps={{ invisible: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth.mobile,
              backdropFilter: 'blur(8px)',
              boxShadow: '2px 2px rgba(0, 0, 0, 0.04)',
            },
            backgroundColor: 'transparent',
          }}
        >
          <Box pl={1} py={2}>
            <IconButton
              color="inherit"
              aria-label="cerrar menu"
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <List
            items={drawerOptions(tarjeta)}
            drawerOpen={open}
            handleDrawerToggle={handleDrawerToggle}
          />
        </SwipeableDrawer>
      ) : (
        <Drawer
          onMouseEnter={handleOpenDrawer}
          onMouseLeave={handleCloseDrawer}
          variant="permanent"
          open={open}
        >
          <Box textAlign={open ? 'left' : 'center'} p={2} pb={4}>
            {open ? (
              <MiPortalIcon width={155} height={50} />
            ) : (
              <MiPortalWithoutTextIcon />
            )}
          </Box>
          <List
            items={drawerOptions(tarjeta)}
            drawerOpen={open}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Drawer>
      )}
    </Box>
  );
}

export default React.memo(MiniDrawer);
