/* eslint-disable no-unused-vars */
import Card from 'components/card/CardComponent';
import { useState } from 'react';
import PdfComponent from 'components/pdf/PdfComponent';

export default function InformeSemestralPDF({ pdf }) {
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <Card sx={{ overflow: 'auto', width: '100%' }}>
      {pdf && (
        <PdfComponent
          pdf={`data:application/pdf;base64,${pdf}`}
          pageNumber={pageNumber}
        />
      )}
    </Card>
  );
}
