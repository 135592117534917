export default function AulaVirtualIcon({
  width = 24,
  height = 24,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 1110 1027"
      {...props}
    >
      <path
        fill="#333951"
        stroke="#333951"
        strokeLinejoin="round"
        strokeWidth={13}
        d="M308.526 34.84a6.513 6.513 0 0 0-.187-.083l-46.177-19.66-.024-.01-.04-.016c-18.611-7.689-42.353-9.862-60.731-7.877-46.054 4.194-72.415 33.435-80.117 48.4a6.476 6.476 0 0 0-.584 4.306 6.5 6.5 0 0 0-5.104 3.898L20.72 286.9l-.026.061c-29.92 72.429-10.046 157.773 64.459 188.604l27.815 12.312c.153.068.309.13.468.186 81.197 28.657 152.429-11.244 180.077-78.171.038-.092.074-.185.108-.279l79.901-221.858c15.845-38.193 14.747-69.853 1.476-95.567-13.146-25.473-37.676-44.003-66.472-57.348ZM170.92 458.06c-76.814 0-115.236-45.587-122.707-67.171-13.549-51.121 1.387-91.93 15.459-130.051l.42-1.138.07-.2 63.55-191.108a6.472 6.472 0 0 0 .195-3.383 6.489 6.489 0 0 0 4.676-3.064c4.746-7.808 13.58-14.323 23.46-19.315 9.763-4.931 19.757-7.979 25.81-9.246h.001c25.115-5.26 55.005 1.863 82.838 13.659 27.661 11.722 52.276 27.623 66.367 38.599.127.098.256.192.389.28 13.632 9.088 18.582 22.923 18.985 37.418.408 14.654-3.915 29.266-8.017 38.105a6.737 6.737 0 0 0-.203.49l-80.467 218.541c-.033.089-.064.18-.093.271-20.58 64.529-69.698 78.133-90.478 77.318a7.23 7.23 0 0 0-.255-.005Z"
      />
      <path
        fill="#333951"
        stroke="#333951"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M641.076 146.672c67.375-33.002 226.565-67.46 368.904 28.298-76.333-44.938-206.209-84.915-346.75-22.095-64.719 29.53-195.528 130.951-206.547 296.968-2.266 34.301 1.583 90.444 21.156 148.024 19.572 57.573 54.898 116.676 115.651 156.758 64.807 51.444 239.792 124.602 422.18 6.44l.05-.036.05-.041c2.93-2.323 6.14-4.728 9.53-7.258 10.87-8.136 23.43-17.54 33.64-29.503l.03-.045.04-.05c2.36-3.316 5.91-7.085 10.01-9.957 4.13-2.883 8.66-4.764 13.04-4.555l.07.002c2.08 0 5.29 1.287 7.71 3.713 2.35 2.347 3.82 5.606 2.87 9.562-73.39 168.542-257.076 287.563-483.783 287.563-262.341 0-469.762-223.408-493.781-457.926 5.51 35.044 15.912 77.372 33.263 121.086 27.147 68.398 71.339 140.278 140.506 192.954 42.858 40.231 162.482 120.557 298.952 120.567 96.853 3.968 279.143-14.228 423.983-185.652l-1.81-2.029c-67.24 44.477-242.967 105.653-407.662-5.479l-.035-.025-.037-.021c-54.395-31.97-169.847-141.593-169.858-330.716 2.895-75.338 48.71-244.055 208.592-316.53l.036-.017Z"
      />
      <path
        stroke="#333951"
        strokeWidth={24}
        d="m244.031 113.861-.206-.092c-6.309-2.8-13.693.043-16.494 6.352l-15.172 34.176c-2.801 6.309.042 13.693 6.351 16.494l.206.091c6.308 2.801 13.693-.043 16.494-6.351l15.172-34.177c2.801-6.308-.043-13.693-6.351-16.493Z"
      />
      <path
        stroke="#333951"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={33}
        d="M719.5 233c-62 61.5-93.646 156.849-47.5 284M887 709.5C806.5 691 725.977 643.015 673 518"
      />
      <path
        stroke="#333951"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={33}
        d="M713 241.126c87.328-.353 177.127 44.691 234.406 167.231M915.5 700c43.841-70.004 82.844-164.369 31.905-290.229M578.5 555l463-169.5M565.5 415 945 270.5M684.5 681l357-140M894.5 702.5 728.641 244.28"
      />
      <path
        stroke="#333951"
        strokeWidth={38.648}
        d="M1059.57 470.953c0 138.557-112.324 250.88-250.881 250.88-138.557 0-250.88-112.323-250.88-250.88 0-138.557 112.323-250.88 250.88-250.88 138.557 0 250.881 112.323 250.881 250.88Z"
      />
    </svg>
  );
}
