import { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import {
  IconEye,
  IconEyeClosed,
} from '@cooperativaobrera/react-icons/tabler-icons';
import FormikTextField from './FormikTextFieldComponent';

export default function SecureFormikTextField(props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormikTextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="alternar la visibilidad de la contraseña"
              onClick={() => setShowPassword((prevState) => !prevState)}
              onMouseDown={(e) => e.preventDefault()}
            >
              {showPassword ? <IconEyeClosed /> : <IconEye />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
    />
  );
}
