import {
  Box, Grid, Typography, 
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import portalEmpleado from 'assets/images/Portal_empleado.webp';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useDeviceHeight from 'hooks/useDeviceHeight';
import TextButtonComponent from 'components/buttons/TextButtonComponent';
import MiPortalIcon from 'components/icons/MiPortal';
import { SIGNIN_URL, BACK_URL/* , ENVIRONMENT */ } from 'constants';
import { useState } from 'react';
import VerVideoAyudaModal from './VerVideoAyudaModal';
import EnvironmentBar from './EnvironmentBar';

const styles = {
  container: {
    height: '100%',
    overflow: 'auto',
  },
  outletContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    maxHeight: '100vh',
    px: { xs: 4, md: 6, lg: 12 },
    py: 15,
    position: 'relative',
  },
  logo: {
    width: '180px',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '50px',
  },
  logoContainer: {
    position: 'absolute',
    top: '5%',
  },
  crearCuenta: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '50px',
    alignSelf: 'center',
    gap: '20px',
  },
  imagenContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    objectFit: 'contain',
    height: '100vh',
    maxWidth: '100%',
  },
};

export default function IngresarLayout() {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();
  const deviceHeight = useDeviceHeight();
  const location = useLocation();
  const isIngresar = location.pathname === '/ingresar';
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const handleClickVolver = () => {
    navigate('/ingresar');
  };

  const handleClickAbrirVideoAyuda = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <VerVideoAyudaModal open={openModal} handleClose={handleClose} />
      <EnvironmentBar />
      <Grid sx={{ ...styles.container, height: deviceHeight }} container>
        {!isMobile && (
        <Grid sx={styles.imagenContainer} xs={12} sm={12} md={7} item>
          <img
            src={portalEmpleado}
            alt={t('pages.ingresar.banner_alt')}
            style={styles.image}
          />
        </Grid>
        )}
        <Grid xs={12} sm={12} md={5} item>
          <Box sx={styles.outletContainer}>
            <Box sx={styles.logoContainer}>
              <MiPortalIcon style={styles.logo} />
            </Box>
            <Box overflow="auto">
              <Outlet />
            </Box>
            <Box sx={styles.crearCuenta}>
              {
                (isIngresar) && (
                  <Box>
                    <TextButtonComponent
                      onClick={handleClickAbrirVideoAyuda}
                      noHover
                      fullHeight
                      underline
                    >
                      <Typography variant="body2">{t('pages.ingresar.ayuda_ingresar')}</Typography>
                    </TextButtonComponent>
                  </Box>
                )
              }
              <Box display="flex">
                {isIngresar && (
                <Typography variant="body2">{t('pages.ingresar.no_tenes_cuenta')}</Typography>
                )}
              
                <TextButtonComponent
                  onClick={() => (isIngresar 
                    ? window.open(SIGNIN_URL + window.btoa(BACK_URL)) 
                    : handleClickVolver())}
                  sx={{ ml: 1 }}
                  noHover
                  fullHeight
                  underline
                >
                  <Typography variant="body2">
                    {isIngresar ? t('botones.crear_cuenta') : t('botones.volver')}
                  </Typography>
                </TextButtonComponent>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
