import DataGrid from 'components/dataGrid/DataGridComponent';
import Card from 'components/card/CardComponent';

export const setRowsDatagrid = (
  rows,
  apiRef,
) => {
  apiRef.current.setRows(rows);
};

export const updateRowsDatagrid = (
  rows,
  apiRef,
) => {
  apiRef.current.updateRows(rows);
};

export const updateColumnsDatagrid = (
  columns,
  apiRef,
) => {
  apiRef.current.updateColumns(columns);
};

export default function DataGridCard({
  rows,
  columns,
  apiRef,
  withoutHeader,
  height,
  initialState,
  disableAutosize,
  columnGrouping,
  columnGroupingModel,
  enableRowSelection,
  ...props
}) {
  return (
    <Card height={height} pt={0.5} pb={1} px={1}>
      <DataGrid
        columns={columns}
        rows={rows}
        apiRef={apiRef}
        withoutHeader={withoutHeader}
        initialState={initialState}
        disableAutosize={disableAutosize}
        columnGrouping={columnGrouping}
        columnGroupingModel={columnGroupingModel}
        enableRowSelection={enableRowSelection}
        {...props}
      />
    </Card>
  );
}
