export const COOPERATIVA = 1;
export const UNICA = 2;
export const NUEVA_CARD = 3;
export const PRIMER_PRECIO = 5;
export const COLMACO = 6;
export const BORLENGHI = 10;
export const ADECCO = 11;
export const RANDSTAD = 12;
export const MANPOWER = 18;
export const GRUPO_GESTION = 23;

export const ASESORES = 13;
export const CONSULTORES = 22;

export const EMPRESAS_TARJETA_SERVICIOS = [COLMACO, ASESORES, CONSULTORES];
