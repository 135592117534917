import { Box, Typography } from '@mui/material';
import { ENVIRONMENT } from 'constants';

const styles = {
  environmentBar: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
    height: 20,
    pl: {
      xs: 2,
      md: 4,
    },
    '&[data-environment="development"]': {
      backgroundColor: 'warning.main',
    },
    '&[data-environment="testing"]': {
      backgroundColor: 'error.main',
    },
  },
};

export default function EnvironmentBar() {
  return ENVIRONMENT !== 'production' && (
  <Box data-environment={ENVIRONMENT} sx={styles.environmentBar}>
    <Typography color="common.white" fontWeight={600}>{ENVIRONMENT.toUpperCase()}</Typography>
  </Box>
  );
}
