import {
  Button, Snackbar as MuiSnackbar, Alert,
} from '@mui/material';

export default function Snackbar({
  severity = 'success',
  message,
  // icon,
  open,
  handleClose,
  autoHideDuration = 3000,
}) {
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open.state}
      onClose={handleClose}
      autoHideDuration={open.autoHideDuration || autoHideDuration}
    >
      <Alert
        sx={{ borderRadius: '10px' }}
        icon={false}
        severity={open.severity || severity}
        action={
            open.button && (
            <Button onClick={open.button.onClick} color="inherit" size="small">
              {open.button.label}
            </Button>
            )
          }
      >
        {open.message || message}
      </Alert>
    </MuiSnackbar>
  );
}
