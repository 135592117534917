import { Stack, Typography } from '@mui/material';
import Card from 'components/card/CardComponent';
import ListItem2 from 'components/infoItem/ListItem2Component';
import formatPeriodo from '../utils/formatDate';

export default function DatosAportes({ datos }) {
  return (
    <Card>
      {datos ? (
        <Stack direction="row" spacing={4} useFlexGap flexWrap="wrap">
          <ListItem2
            label="Primer aporte"
            value={formatPeriodo(datos.periodo_primer_aporte)}
            labelColor="secondary"
          />
          <ListItem2
            label="Último aporte"
            value={formatPeriodo(datos.periodo_last_aporte)}
            labelColor="secondary"
          />
          <ListItem2
            label="Cuotas aportadas"
            value={datos.cuotas_aportadas}
            labelColor="secondary"
          />
        </Stack>
      ) : (
        <Typography>Obteniendo datos...</Typography>
      )}
    </Card>
  );
}
