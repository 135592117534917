export default function GrupoGestionIcon({
  width = 32,
  height = 32,
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <circle cx="19.0722" cy="14.4011" r="2.85567" fill="#979598" />
      <path
        d="M26.072 1.28789C25.7284 0.994177 25.283 0.847555 24.8321 0.87975C24.3813 0.911946 23.9612 1.12037 23.6629 1.45992C23.3645 1.79948 23.2119 2.24285 23.2379 2.6941C23.264 3.14535 23.4667 3.5682 23.8022 3.87113L26.072 1.28789ZM23.6776 17.2531C23.3671 17.5871 23.202 18.0308 23.2187 18.4866C23.2353 18.9423 23.4323 19.3728 23.7663 19.6833C24.1003 19.9938 24.544 20.1589 24.9998 20.1423C25.4555 20.1256 25.886 19.9286 26.1965 19.5946L23.6776 17.2531ZM23.8022 3.87113C24.9204 4.85373 26.2928 6.73613 26.6868 9.06316C27.0637 11.2882 26.5844 14.126 23.6776 17.2531L26.1965 19.5946C29.7749 15.7453 30.6467 11.8481 30.0778 8.48883C29.5258 5.23153 27.6562 2.67998 26.072 1.28789L23.8017 3.87113H23.8022Z"
        fill="#8CBA45"
      />
      <path
        d="M22.2355 7.57281C22.2243 8.02873 22.3946 8.47042 22.7091 8.80073C23.0236 9.13104 23.4564 9.3229 23.9123 9.33411C24.3682 9.34531 24.8099 9.17494 25.1402 8.86048C25.4705 8.54602 25.6624 8.11323 25.6736 7.65731L22.2355 7.57281ZM24.0995 1.71949L24.2825 0.00977584C24.0461 -0.0155252 23.8071 0.00844686 23.5805 0.0801773C23.3539 0.151908 23.1446 0.269839 22.9659 0.426527C22.7872 0.583215 22.6428 0.775256 22.5421 0.990526C22.4413 1.2058 22.3863 1.43962 22.3804 1.67724L24.0995 1.71949ZM29.4214 4.01876C29.8728 4.06329 30.3235 3.92767 30.6754 3.64144C31.0272 3.35521 31.2518 2.94154 31.3 2.49054C31.3483 2.03954 31.2164 1.58773 30.9331 1.23351C30.6498 0.879289 30.238 0.65135 29.7875 0.599333L29.4214 4.01876ZM25.6736 7.65731L25.8185 1.76174L22.3804 1.67724L22.2355 7.57281L25.6736 7.65731ZM23.9162 3.4292L29.4214 4.01876L29.7875 0.599333L24.2825 0.00977584L23.9162 3.4292Z"
        fill="#8CBA45"
      />
      <path
        d="M3.63818 13.2493C3.54328 13.6913 3.62621 14.1528 3.86902 14.534C4.11182 14.9153 4.49498 15.1856 4.93558 15.2865C5.37618 15.3874 5.83879 15.3107 6.22331 15.0731C6.60783 14.8355 6.88332 14.4561 6.99019 14.0169L3.63818 13.2493ZM18.8202 7.75988C19.2593 7.86714 19.723 7.79718 20.1109 7.56515C20.4988 7.33313 20.7797 6.95769 20.8929 6.52008C21.0061 6.08247 20.9425 5.61789 20.7157 5.22686C20.489 4.83584 20.1174 4.54982 19.6813 4.4307L18.8202 7.75988ZM6.99019 14.0169C7.32251 12.5659 8.32544 10.4628 10.1848 9.00923C11.9627 7.61924 14.6868 6.69079 18.8202 7.75988L19.6813 4.4307C14.5932 3.11444 10.7507 4.20152 8.06638 6.30016C5.46377 8.33505 4.10912 11.1938 3.63818 13.2493L6.99019 14.0169Z"
        fill="#979598"
      />
      <path
        d="M10.9914 13.6338C11.3978 13.4268 11.7052 13.0668 11.8462 12.6331C11.9871 12.1993 11.95 11.7274 11.7429 11.321C11.5359 10.9147 11.1759 10.6072 10.7422 10.4663C10.3084 10.3253 9.83647 10.3625 9.43012 10.5695L10.9914 13.6338ZM4.95613 14.7789L3.36521 15.4313C3.45539 15.6512 3.59019 15.8501 3.76109 16.0152C3.93198 16.1804 4.13527 16.3084 4.35812 16.3911C4.58097 16.4738 4.81854 16.5093 5.05583 16.4956C5.29312 16.4818 5.52498 16.419 5.73677 16.3111L4.95613 14.7789ZM4.44671 9.00391C4.36232 8.79316 4.23704 8.60121 4.07809 8.43913C3.91915 8.27704 3.72968 8.14804 3.52062 8.05956C3.31156 7.97107 3.08704 7.92486 2.86003 7.92358C2.63302 7.92231 2.40801 7.966 2.19797 8.05213C1.98793 8.13826 1.79702 8.26513 1.63627 8.42542C1.47551 8.58571 1.34808 8.77624 1.26133 8.98603C1.17459 9.19582 1.13024 9.42071 1.13085 9.64772C1.13146 9.87473 1.17701 10.0994 1.26489 10.3087L4.44671 9.00391ZM9.43012 10.5695L4.17548 13.2467L5.73677 16.3111L10.9914 13.6338L9.43012 10.5695ZM6.54715 14.1267L4.44671 9.00391L1.26489 10.3087L3.36521 15.4313L6.54715 14.1267Z"
        fill="#979598"
      />
      <path
        d="M25.8531 26.8181C26.2811 26.6683 26.6326 26.3556 26.8312 25.9479C27.0297 25.5402 27.0591 25.0706 26.9131 24.6414C26.7671 24.2121 26.4575 23.8578 26.0515 23.6557C25.6456 23.4537 25.1763 23.4201 24.7458 23.5623L25.8531 26.8181ZM13.2086 16.781C13.0693 16.351 12.7663 15.9931 12.3651 15.7849C11.9639 15.5766 11.4969 15.5347 11.065 15.6682C10.6332 15.8016 10.2712 16.0998 10.0576 16.4981C9.84387 16.8964 9.79561 17.3629 9.92323 17.7965L13.2086 16.781ZM24.7458 23.5623C23.3365 24.0415 21.0203 24.2926 18.807 23.4737C16.6904 22.6907 14.4693 20.8599 13.2086 16.781L9.92302 17.7964C11.475 22.8176 14.418 25.5167 17.6136 26.699C20.7123 27.845 23.8563 27.4969 25.8529 26.818L24.7458 23.5618L24.7458 23.5623Z"
        fill="#DF9E37"
      />
      <path
        d="M22.3183 20.358C22.1254 20.2407 21.9113 20.1624 21.6881 20.1278C21.465 20.0931 21.2372 20.1028 21.0178 20.1562C20.5747 20.264 20.1925 20.5435 19.9554 20.9331C19.7184 21.3227 19.6458 21.7905 19.7536 22.2336C19.8614 22.6767 20.1409 23.0589 20.5305 23.296L22.3183 20.358ZM26.4622 24.8927L27.8529 25.904C27.9927 25.7118 28.0911 25.4926 28.142 25.2604C28.1928 25.0283 28.1949 24.788 28.1483 24.555C28.1016 24.3219 28.0071 24.1011 27.8708 23.9064C27.7344 23.7117 27.5592 23.5474 27.3562 23.4238L26.4622 24.8927ZM21.8152 28.3591C21.5515 28.7281 21.4443 29.1864 21.5169 29.6341C21.5896 30.0818 21.8362 30.4827 22.203 30.7495C22.5698 31.0162 23.0272 31.1273 23.4755 31.0583C23.9239 30.9894 24.3268 30.7462 24.5966 30.3816L21.8152 28.3591ZM20.5305 23.296L25.5683 26.3617L27.3562 23.4238L22.3183 20.358L20.5305 23.296ZM25.0717 23.8813L21.8152 28.3591L24.5966 30.3816L27.8529 25.904L25.0717 23.8813Z"
        fill="#DF9E37"
      />
    </svg>
  );
}
