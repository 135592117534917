import {
  Button,
  Grid,
  MenuItem,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import FormikDatePicker from 'components/datepicker/FormikDatepickerComponent';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import FormikSelect from 'components/selects/FormikSelectComponent';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import solicitarCasaPersonalSchema from 'schemas/solicitarCasaPersonal.schema';
import {
  getCasaPersonalFechas,
  getCasaPersonalSolicitar,
  getCasaPersonalSolicitudes,
} from 'services/Ares';
import onlyNumbersValidate from 'utils/onlyNumbersValidate';
import useModal from 'hooks/useModal';
import useAlerts from 'hooks/useAlerts';
import { PickersDay } from '@mui/x-date-pickers';
import formatSolicitudesData from '../utils/formatSolicitudesData';
import SolicitudRealizadaModal from './SolicitudRealizadaModal';
import { ESTADO_OTORGADO, ESTADO_SOLICITADO } from '../constants';

function ServerDay(props) {
  let title;
  const {
    day,
    outsideCurrentMonth,
    warnings,
    disabled,
    today,
    disabledDays,
    onClick,
    ...other
  } = props;
  const isWarningDay = warnings.find(
    (warningDay) => warningDay.fecha === day.toISOString().slice(0, 10),
  );

  if (isWarningDay && day > new Date()) {
    title = 'Existen otras solicitudes para el salón';
  } else if (disabled) {
    if (today) {
      title = 'La reserva para el día de hoy no está disponible';
    } else if (day > new Date()) {
      const disabledDay = disabledDays.find(
        (d) => d.fecha === day.toISOString().slice(0, 10),
      );

      if (disabledDay) {
        if (Number(disabledDay.estado) === ESTADO_OTORGADO) {
          title = 'Reservado';
        } else {
          title = disabledDay.descripcion;
        }
      }
    }
  }

  return (
    <Box>
      <Tooltip
        title={title}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ],
          },
        }}
      >
        <span>
          <PickersDay
            {...other}
            disabled={disabled}
            today={today}
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
            sx={{
              color: (theme) => (isWarningDay
                ? theme.palette.warning.main
                : theme.palette.text.primary),
            }}
          />
        </span>
      </Tooltip>
    </Box>
  );
}

function disabledMotivos(salon, motivo) {
  return salon === '1' && (motivo === '2' || motivo === '3');
}

export default function SolicitarForm({
  salones, turnos, motivos, setData, 
}) {
  const { t } = useTranslation();
  const tPage = 'pages.tarjeta_empleado.casa_personal';
  const [disabledDays, setDisabledDays] = useState([]);
  const [requestedDays, setRequestedDays] = useState([]);
  const { openModal, modalState, closeModal } = useModal();
  const { openSnackbar } = useAlerts();

  const solicitarForm = useFormik({
    initialValues: {
      salon: '',
      turno: '',
      fecha: null,
      personas: '',
      motivo: '',
    },
    validationSchema: solicitarCasaPersonalSchema,
    onSubmit: async (data, options) => {
      const {
        salon, turno, fecha, personas, motivo, 
      } = data;
      getCasaPersonalSolicitar({
        salon,
        turno,
        fecha: fecha.toISOString().split('T')[0],
        personas,
        motivo,
      }).then((res) => {
        if (res.data.success) {
          getCasaPersonalSolicitudes().then((res2) => {
            setData(formatSolicitudesData(res2.data.data));
          });

          const today = new Date();
          const daysBefore = (fecha - today) / 86400000;
          if (daysBefore >= 30) {
            openModal();
          }
        } else {
          openSnackbar({
            message: res.data.message,
            severity: 'error',
          });
        }

        options.resetForm();
      });
    },
  });

  useEffect(() => {
    if (solicitarForm.values.salon && solicitarForm.values.turno) {
      getCasaPersonalFechas(
        solicitarForm.values.salon,
        solicitarForm.values.turno,
      ).then((res) => {
        setDisabledDays(
          res.data.data.filter(
            (fecha) => Number(fecha.estado) !== ESTADO_SOLICITADO,
          ),
        );
        setRequestedDays(
          res.data.data.filter(
            (fecha) => Number(fecha.estado) === ESTADO_SOLICITADO,
          ),
        );
      });
    }

    solicitarForm.setFieldValue('motivo', '');
    solicitarForm.setFieldValue('fecha', null);
  }, [solicitarForm.values.salon, solicitarForm.values.turno]);

  return (
    <>
      <SolicitudRealizadaModal open={modalState} handleClose={closeModal} />
      <Card>
        <Typography fontWeight={600} mb={1}>
          {t(`${tPage}.datos_solicitud`)}
        </Typography>
        <form onSubmit={solicitarForm.handleSubmit}>
          <Grid spacing={2} container>
            <Grid item xs={12} md={4} lg={2}>
              <FormikSelect
                label={t(`${tPage}.salon_input`)}
                id="salon"
                formik={solicitarForm}
                fullWidth
              >
                <MenuItem value="" disabled sx={{ display: 'none' }}>
                  {t(`${tPage}.salon_input_placeholder`)}
                </MenuItem>
                {salones.map((salon) => (
                  <MenuItem key={salon.value} value={salon.value}>
                    {salon.label}
                  </MenuItem>
                ))}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <FormikSelect
                label={t(`${tPage}.turno_input`)}
                id="turno"
                formik={solicitarForm}
                fullWidth
              >
                <MenuItem value="" disabled sx={{ display: 'none' }}>
                  {t(`${tPage}.turno_input_placeholder`)}
                </MenuItem>
                {turnos.map((turno) => (
                  <MenuItem key={turno.value} value={turno.value}>
                    {turno.label}
                  </MenuItem>
                ))}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <FormikDatePicker
                id="fecha"
                label={t(`${tPage}.fecha_input`)}
                formik={solicitarForm}
                disabled={
                  !(solicitarForm.values.salon && solicitarForm.values.turno)
                }
                shouldDisableDate={(day) => disabledDays.find(
                  (item) => item.fecha === day.toISOString().split('T')[0],
                )}
                minDate={new Date()}
                hideErrorMessage
                slots={{
                  day: ServerDay,
                }}
                slotProps={{
                  day: {
                    warnings: requestedDays,
                    disabledDays,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <FormikTextField
                id="personas"
                label={t(`${tPage}.personas_input`)}
                formik={solicitarForm}
                validate={onlyNumbersValidate}
                hideErrorMessage
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <FormikSelect
                label={t(`${tPage}.motivo_input`)}
                id="motivo"
                formik={solicitarForm}
                fullWidth
              >
                <MenuItem value="" disabled sx={{ display: 'none' }}>
                  {t(`${tPage}.motivo_input_placeholder`)}
                </MenuItem>
                {motivos.map((motivo) => (
                  <MenuItem
                    key={motivo.value}
                    value={motivo.value}
                    disabled={disabledMotivos(
                      solicitarForm.values.salon,
                      motivo.value,
                    )}
                  >
                    {motivo.label}
                  </MenuItem>
                ))}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={4} lg={2} display="flex" alignItems="end">
              <Button
                disabled={!solicitarForm.isValid || !solicitarForm.dirty}
                variant="contained"
                fullWidth
                type="submit"
              >
                {t('botones.solicitar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  );
}
