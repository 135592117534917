import empleadosApi from 'apis/EmpleadosApi';

export const getEstadoRecibos = async () => {
  return empleadosApi.get('/recibos/estado');
};

export const getRecibosDisponibles = async () => {
  return empleadosApi.get('/recibos');
};

export const getReciboPDF = async (periodo, tipo, key) => {
  return empleadosApi.put('/recibos', { periodo, tipo, key });
};

export const solicitarAltaRecibos = async (public_key, encrypted_key) => {
  return empleadosApi.post('/recibos', { public_key, encrypted_key });
};

export const bloquearRecibosDelUsuarioAPI = async () => {
  return empleadosApi.put('/recibos/bloquear');
};
