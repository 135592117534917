import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@mui/material';

export default function FormikSelect({
  id,
  label,
  formik,
  children,
  fullWidth,
  ...props
}) {
  return (
    <FormControl
      fullWidth={fullWidth}
      error={
        formik.touched[id]
        && !Array.isArray(formik.errors[id])
        && Boolean(formik.errors[id])
      }
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        name={id}
        value={formik.values[id]}
        onChange={formik.handleChange}
        displayEmpty
        {...props}
      >
        {children}
      </Select>
      {formik.touched[id]
        && !Array.isArray(formik.errors[id])
        && Boolean(formik.errors[id]) && (
          <FormHelperText>
            {formik.touched[id]
              && !Array.isArray(formik.errors[id])
              && formik.errors[id]}
          </FormHelperText>
      )}
    </FormControl>
  );
}
