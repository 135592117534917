export default function AyudaIcon({
  width = 20,
  height = 20,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M7.367 7.482c0-.589.273-1.153.758-1.57.486-.415 1.144-.649 1.83-.649h.74c.686 0 1.344.234 1.83.65.485.416.758.98.758 1.569a2.217 2.217 0 0 1-1.48 2.218c-.453.213-.842.616-1.109 1.15a3.727 3.727 0 0 0-.369 1.808M10.324 15.616v.009"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={10} cy={10} r={9.5} stroke={stroke} />
    </svg>
  );
}
