/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import useAlerts from 'hooks/useAlerts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { UTOKEN } from '../constants/storage';
import { API_PE_BASEPATH, TIEMPO_INACTIVIDAD } from '../constants';

/**
 * Instancia de API para comunicación con Coopeusuario
 */
const instance = axios.create({
  baseURL: API_PE_BASEPATH,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});
instance.defaults.timeout = 5000;

instance.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(UTOKEN)}`;
  return config;
});

function AxiosInterceptor({ children }) {
  const { t } = useTranslation();
  const [isSet, setIsSet] = useState(false);
  const { openSnackbar } = useAlerts();
  const { state: { session }, signout } = useAuth();
  const navigate = useNavigate();
  let timeOut;

  /**
   * Desloguea despues de 15 minutos de inactividad
   */
  const handleLogout = () => {
    clearTimeout(timeOut);
    timeOut = 0;
    timeOut = setTimeout(() => {
      signout();
      navigate('/ingresar', { replace: true });
    }, TIEMPO_INACTIVIDAD);
  };

  useEffect(() => {
    if (!session) {
      signout();
      navigate('/ingresar', { replace: true });
    }
  }, [session]);

  useEffect(() => {
    const resInterceptor = (response) => {
      handleLogout();
      return response;
    };

    const errInterceptor = (error) => {
      let message;
      if (error.response) {
        const res = error.response;
        if (res.data && typeof res.data === 'object' && 'message' in res.data) {
          message = res.data.message;
        }
        switch (res.status) {
          case 401:
            message = res.data.message;
            signout();
            navigate('/ingresar', { replace: true });
            break;
          case 429:
            message = res.data.message;
            break;
          case 400:
          case 403:
            message = res.data.message;
            break;
          case 404:
            message = res.data.message;
            break;
          case 409:
            break;
          case 500:
            message = t('dialogos.error.inesperado');
            break;
          default:
            break;
        }

        if (message) {
          openSnackbar({
            message,
            severity: 'error',
          });
        }
      } else if (error.message === 'Network Error') {
        message = 'Error de red. Por favor, verifica tu conexión a Internet.';
        openSnackbar({
          message,
          severity: 'error',
        });
      } else if (error.message === 'Request failed with status code 0') {
        // Manejar el error de CORS aquí, por ejemplo, mostrando un mensaje al usuario
        message = 'Error de CORS. Verifica tu configuración de CORS en el servidor.';
        openSnackbar({
          message,
          severity: 'error',
        });
      }

      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    setIsSet(true);

    return () => instance.interceptors.response.eject(interceptor);
  }, []);

  return isSet && children;
}

export default instance;
export { AxiosInterceptor as EmpleadosAxiosInterceptor };
