import { Grid, Typography } from '@mui/material';
import CuentaDuplicadaModal from 'components/restablecerContraseña/CuentaDuplicadaModalComponent';
import RestableceContraseñaForm from 'components/restablecerContraseña/RestablecerContraseñaFormComponent';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import restablecerContraseñaSchema from 'schemas/restablecerContraseña.schema';
import { resetPasswordRequestCU } from 'services/AuthService';
import sanitizeHtml from 'sanitize-html';
import InfoItemIconContainer from 'components/infoItemIconContainer/InfoItemIconContainer';
import ListIcon from 'components/icons/List';

export default function RestablecerContraseñaView() {
  const { t } = useTranslation();
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState({
    state: false,
    usuariosEncontrados: [],
    onClickSoyYo: () => {},
  });
  const [successMessage, setSuccessMessage] = useState({
    message: '',
    email: '',
  });

  const initialValues = {
    documento: '',
  };

  const restablecerContraseña = ({ documento, tipoDocumento }) => {
    return resetPasswordRequestCU(documento, tipoDocumento).then((resp) => {
      if (resp?.data?.success) {
        setSuccessMessage({
          message: resp.data.message,
          email: resp.data.data.email,
        });
        // Mostrar snackbar de exito
      }
      // Más de un usuario, modal de seleción
      if (resp && resp.status && resp.status === 409) {
        setModalState({
          state: true,
          usuariosEncontrados: resp.data.data.map((usuario, i) => ({
            id: i,
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            nro_documento: documento,
            id_tipo_documento: usuario.id_tipo_documento,
          })),
          onClickSoyYo: (usuarioSeleccionado) => {
            restablecerContraseña({
              documento,
              tipoDocumento: usuarioSeleccionado.id_tipo_documento,
            });
            setModalState((prevState) => ({ ...prevState, state: false }));
          },
        });
      }
    });
  };

  const restablecerContraseñaForm = useFormik({
    initialValues,
    validationSchema: restablecerContraseñaSchema,
    validateOnChange: shouldValidateOnChange,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (data, options) => {
      try {
        setIsLoading(true);
        await restablecerContraseña(data);
        setIsLoading(false);
      } catch (error) {
        options.resetForm();
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <CuentaDuplicadaModal
        modalState={modalState}
        setModalState={setModalState}
      />
      <Grid spacing={{ xs: 3, md: 4 }} container>
        {successMessage.message ? (
          <>
            <Grid xs={12} item>
              <InfoItemIconContainer icon={<ListIcon />} />
            </Grid>
            <Grid xs={12} item>
              <Typography mb={1} variant="h6">
                {t('pages.restablecer_contraseña.titulo_finalizacion', { email: successMessage.email })}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(successMessage.message),
                }}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={12} item>
              <Typography mb={1} variant="h6">
                {t('pages.restablecer_contraseña.titulo')}
              </Typography>
              <Typography>
                {t('pages.restablecer_contraseña.subtitulo')}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <RestableceContraseñaForm
                formik={restablecerContraseñaForm}
                setShouldValidateOnChange={setShouldValidateOnChange}
                isLoading={isLoading}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
