import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CUENTA_INACTIVA,
  VALIDACION_CELULAR,
  VALIDACION_USUARIO,
  VALIDACION_USUARIO_CELULAR,
} from 'constants/validacion';
import ValidacionUsuarioForm from 'components/validacion/ValidacionUsuarioFormComponent';
import ValidacionCelularForm from 'components/validacion/ValidacionCelularFormComponent';
import useEmpleado from 'hooks/useEmpleado';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ValidacionUsuarioCelularForm from 'components/validacion/ValidacionUsuarioCelularFormComponent';
import { activarUsuario } from 'services/VerifyService';

const ValidateDataView = () => {
  const { t } = useTranslation();
  const [validacion, setValidacion] = useState();
  const navigate = useNavigate();
  const {
    state: { usuario, empleado },
  } = useEmpleado();

  const activarYRedirigir = async () => {
    const res = await activarUsuario();
    if (res.data.success) {
      navigate('/inicio', { replace: true });
    }
  };

  useEffect(() => {
    const { asociado_verificado, telefono_verificado } = usuario;
    const { status } = empleado;

    if (!asociado_verificado && status === CUENTA_INACTIVA) {
      if (telefono_verificado) {
        setValidacion(VALIDACION_USUARIO);
      } else {
        setValidacion(VALIDACION_USUARIO_CELULAR);
      }
    } else if (!telefono_verificado) {
      setValidacion(VALIDACION_CELULAR);
    } else if (status === CUENTA_INACTIVA) {
      activarYRedirigir();
    } else {
      navigate('/inicio', { replace: true });
    }
  }, [usuario]);

  return (
    <Grid spacing={{ xs: 3, md: 4 }} container>
      <Grid xs={12} item>
        {validacion !== undefined && validacion !== null && (
          <Typography mb={1} variant="h6">
            {validacion === VALIDACION_CELULAR
              ? t('pages.validar.titulo_alt')
              : t('pages.validar.titulo')}
          </Typography>
        )}
      </Grid>
      <Grid xs={12} item>
        {validacion === VALIDACION_USUARIO && <ValidacionUsuarioForm />}
        {validacion === VALIDACION_CELULAR && <ValidacionCelularForm />}
        {validacion === VALIDACION_USUARIO_CELULAR && (
          <ValidacionUsuarioCelularForm />
        )}
      </Grid>
    </Grid>
  );
};

export default ValidateDataView;
