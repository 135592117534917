import { IconFileDownload } from '@cooperativaobrera/react-icons/tabler-icons';
import {
  Box,
  ButtonBase,
  IconButton,
  Typography,
} from '@mui/material';
import Card from 'components/card/CardComponent';

const styles = {
  container: {
    display: 'flex',
  },
  button: {
    width: '100%',
    p: 1,
    borderRadius: '10px',
    transition: 'background-color 0.1s, color 0.1s',
    '&[data-selected=true]': {
      backgroundColor: 'secondary.main',
      '& p': {
        color: 'common.white',
      },
    },
    justifyContent: 'start',
  },
};

export default function SeleccionarResumenCuentaCard({
  periodos,
  resumenCuentaSeleccionado,
  handleClickResumenCuenta,
  handleClickDescargarResumenCuenta,
}) {
  return (
    <Card>
      {periodos?.map(({ periodo, descripcion }) => (
        <Box key={periodo} sx={styles.container}>
          <ButtonBase
            sx={styles.button}
            data-selected={resumenCuentaSeleccionado.id === periodo}
            onClick={() => handleClickResumenCuenta(periodo)}
          >
            <Typography>{descripcion}</Typography>
          </ButtonBase>
          <IconButton
            onClick={() => handleClickDescargarResumenCuenta(periodo)}
          >
            <IconFileDownload />
          </IconButton>
        </Box>
      ))}
    </Card>
  );
}
