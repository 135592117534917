export default function throttle(func, delay) {
  let lastTime = 0;
  return (...args) => {
    const currentTime = new Date().getTime();
    if (currentTime - lastTime >= delay) {
      lastTime = currentTime;
      func.apply(this, args);
    }
  };
}
