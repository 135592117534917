import * as Yup from 'yup';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿]{8,32}$/;

const cambioContraseñaSchema = Yup.object({
  contraseñaActual: Yup.string()
    .required('Ingresá tu contraseña actual')
    .matches(
      passwordRegex,
      'La contraseña no cumple los requisitos',
    ),
  contraseñaNueva: Yup.string()
    .when('contraseñaActual', (contraseñaActual, field) => {
      if (passwordRegex.test(contraseñaActual)) {
        return field.notOneOf(
          [Yup.ref('contraseñaActual')],
          'La nueva contraseña es igual a la actual',
        );
      } 
      return field;
    })
    .required('Ingresá tu nueva contraseña')
    .matches(
      passwordRegex,
      'La contraseña no cumple los requisitos',
    ),
  contraseñaNuevaRepetida: Yup.string()
    .oneOf([Yup.ref('contraseñaNueva')], 'Las contraseñas no coinciden')
    .required('Ingresá nuevamente tu contraseña nueva'),
});

export default cambioContraseñaSchema;
