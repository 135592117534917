export default function VacacionesIcon({
  width = 20,
  height = 20,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      {...props}
    >
      <path 
        d="M13.9363 13.2219C12.6861 11.9718 10.9905 11.2695 9.22257 11.2695C7.45459 11.2695 5.75901 11.9718 4.50879 13.2219"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M14.3332 1.71452C13.1082 1.00731 11.6525 0.815656 10.2863 1.18172C8.92 1.54779 7.75512 2.4416 7.04785 3.66652L16.2852 8.99985C16.9924 7.7749 17.184 6.31918 16.818 4.95293C16.4519 3.58667 15.5581 2.42179 14.3332 1.71452Z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M13.2051 7.22203C14.6789 4.67092 15.1829 2.20514 14.3322 1.71448C13.4815 1.22381 11.5989 2.89314 10.126 5.44425"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M11.6667 6.33301L9 10.9517"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M1 15.4444C1.2767 15.3068 1.57999 15.231 1.88889 15.2221C2.23511 15.2149 2.57789 15.292 2.88762 15.4469C3.19736 15.6018 3.46474 15.8297 3.66667 16.111C3.8686 16.3924 4.13598 16.6203 4.44571 16.7752C4.75545 16.93 5.09823 17.0072 5.44444 16.9999C5.79066 17.0072 6.13344 16.93 6.44318 16.7752C6.75291 16.6203 7.02029 16.3924 7.22222 16.111C7.42415 15.8297 7.69153 15.6018 8.00127 15.4469C8.311 15.292 8.65378 15.2149 9 15.2221C9.34622 15.2149 9.689 15.292 9.99873 15.4469C10.3085 15.6018 10.5758 15.8297 10.7778 16.111C10.9797 16.3924 11.2471 16.6203 11.5568 16.7752C11.8666 16.93 12.2093 17.0072 12.5556 16.9999C12.9018 17.0072 13.2446 16.93 13.5543 16.7752C13.864 16.6203 14.1314 16.3924 14.3333 16.111C14.5353 15.8297 14.8026 15.6018 15.1124 15.4469C15.4221 15.292 15.7649 15.2149 16.1111 15.2221C16.42 15.231 16.7233 15.3068 17 15.4444"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
