export default function ManpowerIcon({ width = 54, height = 32, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 54 32"
      {...props}
    >
      <path
        d="M7.04544 10.3581C7.31666 9.60772 7.87487 8.9958 8.59727 8.65697C9.31966 8.31815 10.1471 8.28017 10.8975 8.5514C11.6479 8.82262 12.2598 9.38083 12.5986 10.1032C12.9374 10.8256 12.9754 11.653 12.7042 12.4034L6.6814 29.0666C6.41017 29.817 5.85196 30.4289 5.12957 30.7678C4.40717 31.1066 3.57977 31.1446 2.82937 30.8733C2.07898 30.6021 1.46706 30.0439 1.12823 29.3215C0.789407 28.5991 0.75143 27.7717 1.02265 27.0213L7.04544 10.3581Z"
        fill="#6281AD"
      />
      <path
        d="M16.3666 10.3581C16.6378 9.60772 17.196 8.9958 17.9184 8.65697C18.6408 8.31815 19.4682 8.28017 20.2186 8.5514C20.969 8.82262 21.5809 9.38083 21.9198 10.1032C22.2586 10.8256 22.2966 11.653 22.0253 12.4034L16.0026 29.0666C15.7313 29.817 15.1731 30.4289 14.4507 30.7678C13.7283 31.1066 12.9009 31.1446 12.1505 30.8733C11.4001 30.6021 10.7882 30.0439 10.4494 29.3215C10.1106 28.5991 10.0726 27.7717 10.3438 27.0213L16.3666 10.3581Z"
        fill="#789CC7"
      />
      <path
        d="M25.6877 10.3582C25.9589 9.60778 26.5171 8.99586 27.2395 8.65704C27.9619 8.31821 28.7893 8.28023 29.5397 8.55146C30.2901 8.82268 30.9021 9.38089 31.2409 10.1033C31.5797 10.8257 31.6177 11.6531 31.3465 12.4035L25.3237 29.0667C25.0524 29.8171 24.4942 30.429 23.7718 30.7678C23.0494 31.1066 22.222 31.1446 21.4716 30.8734C20.7212 30.6022 20.1093 30.044 19.7705 29.3216C19.4317 28.5992 19.3937 27.7718 19.6649 27.0214L25.6877 10.3582Z"
        fill="#819B91"
      />
      <path
        d="M47.019 2.82937C47.2902 2.07897 47.8484 1.46705 48.5708 1.12823C49.2932 0.789402 50.1206 0.751423 50.871 1.02265C51.6214 1.29387 52.2334 1.85208 52.5722 2.57448C52.911 3.29687 52.949 4.12428 52.6778 4.87468L46.655 21.5379C46.3837 22.2883 45.8255 22.9002 45.1031 23.239C44.3807 23.5778 43.5533 23.6158 42.8029 23.3446C42.0525 23.0734 41.4406 22.5151 41.1018 21.7928C40.763 21.0704 40.725 20.243 40.9962 19.4926L47.019 2.82937Z"
        fill="#E28C44"
      />
      <path
        d="M37.698 2.82961C37.9692 2.07921 38.5274 1.4673 39.2498 1.12847C39.9722 0.789645 40.7996 0.751668 41.55 1.02289C42.3004 1.29412 42.9124 1.85233 43.2512 2.57472C43.59 3.29712 43.628 4.12452 43.3568 4.87492L34.5753 29.1706C34.304 29.921 33.7458 30.5329 33.0234 30.8718C32.301 31.2106 31.4736 31.2486 30.7232 30.9773C29.9728 30.7061 29.3609 30.1479 29.0221 29.4255C28.6833 28.7031 28.6453 27.8757 28.9165 27.1253L37.698 2.82961Z"
        fill="#B25D66"
      />
    </svg>
  );
}
