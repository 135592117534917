export default function NuevaCardIcon({ width = 32, height = 24, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 24"
      {...props}
    >
      <path
        d="M0 3.22148C0 1.4423 1.4423 0 3.22148 0H28.7785C30.5577 0 32 1.4423 32 3.22148V9.34228H0V3.22148Z"
        fill="#F68735"
      />
      <path
        d="M32 19.8659C32 21.6451 30.5577 23.0874 28.7785 23.0874L3.22148 23.0874C1.4423 23.0874 1.2609e-07 21.6451 2.8163e-07 19.8659L1.20162e-06 9.34244L32 9.34244L32 19.8659Z"
        fill="#1D5CA9"
      />
      <path
        d="M11.5437 17.3962C11.5437 17.0107 11.8562 16.6982 12.2417 16.6982L27.5974 16.6982C27.9829 16.6982 28.2954 17.0107 28.2954 17.3962C28.2954 17.7817 27.9829 18.0942 27.5974 18.0942L12.2417 18.0942C11.8562 18.0942 11.5437 17.7817 11.5437 17.3962Z"
        fill="#FAFAFA"
      />
      <path
        d="M22.282 13.4231C22.282 13.0376 22.5945 12.7251 22.98 12.7251L27.5974 12.7251C27.9829 12.7251 28.2954 13.0376 28.2954 13.4231C28.2954 13.8086 27.9829 14.1211 27.5974 14.1211L22.98 14.1211C22.5945 14.1211 22.282 13.8086 22.282 13.4231Z"
        fill="#FAFAFA"
      />
      <path d="M0 5.58398H32V9.87929H0V5.58398Z" fill="#FAFAFA" />
    </svg>
  );
}
