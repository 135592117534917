export default function AsesoresIcon({ width = 23, height = 26, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 26"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.94168C0 0.786085 1.00326 -0.113663 2.15203 0.0116763L16.0432 1.5273C17.2251 1.65625 18.1202 2.65442 18.1202 3.8433V24.8114C18.1202 25.1688 17.8305 25.4586 17.4731 25.4586H12.0358V24.8896V19.2699C12.0358 17.6258 10.703 16.293 9.05893 16.293C7.41483 16.293 6.08203 17.6258 6.08203 19.2699V25.4586H2.20032C0.985116 25.4586 0 24.4735 0 23.2583V1.94168ZM4.49609 7.51687C4.49609 6.98075 4.9307 6.54614 5.46682 6.54614H7.06767C7.60379 6.54614 8.0384 6.98075 8.0384 7.51687V7.61905C8.0384 8.15517 7.60379 8.58978 7.06767 8.58978H5.46682C4.9307 8.58978 4.49609 8.15517 4.49609 7.61905V7.51687ZM4.49609 11.7404C4.49609 11.2043 4.9307 10.7697 5.46682 10.7697H7.06767C7.60379 10.7697 8.0384 11.2043 8.0384 11.7404V11.8426C8.0384 12.3787 7.60379 12.8133 7.06767 12.8133H5.46682C4.9307 12.8133 4.49609 12.3787 4.49609 11.8426V11.7404ZM11.0529 6.54614C10.5168 6.54614 10.0821 6.98075 10.0821 7.51687V7.61905C10.0821 8.15517 10.5168 8.58978 11.0529 8.58978H12.6537C13.1898 8.58978 13.6245 8.15517 13.6245 7.61905V7.51687C13.6245 6.98075 13.1898 6.54614 12.6537 6.54614H11.0529ZM10.0821 11.7404C10.0821 11.2043 10.5168 10.7697 11.0529 10.7697H12.6537C13.1898 10.7697 13.6245 11.2043 13.6245 11.7404V11.8426C13.6245 12.3787 13.1898 12.8133 12.6537 12.8133H11.0529C10.5168 12.8133 10.0821 12.3787 10.0821 11.8426V11.7404Z"
        fill="#27559B"
      />
      <circle
        cx="18.1637"
        cy="18.3732"
        r="2.98601"
        fill="#E9332A"
      />
      <path
        d="M13.3301 24.9772C13.3301 22.7434 15.1409 20.9326 17.3746 20.9326H18.9545C21.1883 20.9326 22.9991 22.7434 22.9991 24.9772C22.9991 25.2564 22.7727 25.4827 22.4935 25.4827H13.8356C13.5564 25.4827 13.3301 25.2564 13.3301 24.9772Z"
        fill="#E9332A"
      />
    </svg>
  );
}
