import {
  Box, ButtonBase, Divider, Grid,
  Typography,
} from '@mui/material';
import useAlerts from 'hooks/useAlerts';
import { IconCopy } from '@cooperativaobrera/react-icons/tabler-icons';
import copiarAClipboard from 'utils/copiarAClipboard';
import CuentaAtras from '../cuentaAtras/CuentaAtrasComponent';

const styles = {
  displayCodigo: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    gap: 1,
  },
};

export default function DisplayCodigo({
  value,
  handleFinalizoElTiempo,
  finalizado = false,
  tiempo = 300,
  copiar = true,
}) {
  const { openSnackbar } = useAlerts();

  const copiarCodigo = (copiado) => {
    copiarAClipboard(copiado)
      .then(() => {
        openSnackbar({
          message: 'Copiado correctamente',
          severity: 'success',
        });
      });
  };

  return (
    <Grid container>
      {finalizado ? (
        <Grid xs={12} item p={2}>
          <Box
            className="animate__animated animate__shakeX"
            sx={styles.displayCodigo}
          >
            <Typography variant="h6">
              {value}
            </Typography>
          </Box>
        </Grid>
      ) : (
        <Grid xs={12} item p={2}>
          <Box
            sx={styles.displayCodigo}
          >
            <Typography sx={{ typography: { md: 'h4', xs: 'h3' } }} letterSpacing={4}>
              {value}
            </Typography>
            {copiar && (
              <ButtonBase
                onClick={() => copiarCodigo(value)}
                disableRipple
              >
                <IconCopy color="accent" />
              </ButtonBase>
            )}
          </Box>
          <Divider orientation="horizontal" />
        </Grid>
      )}
      <Grid xs={12} item p={1}>
        <CuentaAtras
          tiempo={tiempo}
          alExpirar={{
            accion: handleFinalizoElTiempo,
            reiniciar: false,
          }}
        />
      </Grid>
    </Grid>
  );
}
