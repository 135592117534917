import { Box } from '@mui/material';

const styles = {
  container: {
    borderRadius: '10px',
    backgroundColor: '#fff',
  },
};

export default function Card({
  children,
  sx,
  padding = { xs: 2, md: 3 },
  ...props 
}) {
  return (
    <Box sx={{ ...styles.container, ...sx }} p={padding} {...props}>
      {children}
    </Box>
  );
}
