import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import DatePicker from 'components/datepicker/FormikDatepickerComponent';
import { useState } from 'react';
import { useFormik } from 'formik';
import { validacionUsuarioSchema } from 'schemas/validacion.schema';
import useEmpleado from 'hooks/useEmpleado';
import { useNavigate } from 'react-router-dom';
import { activarUsuario } from 'services/VerifyService';
import onlyNumbersValidate from 'utils/onlyNumbersValidate';

export default function ValidacionUsuarioForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    state: { usuario },
  } = useEmpleado();
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    legajo: '',
    fechaNacimiento: '',
  };

  const validacionForm = useFormik({
    initialValues,
    validationSchema: validacionUsuarioSchema,
    validateOnChange: shouldValidateOnChange,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (data, { setErrors, resetForm }) => {
      try {
        setIsLoading(true);
        const { legajo, fechaNacimiento } = data;

        // Compruebo si el legajo introducido es correcto
        if (legajo !== String(usuario.legajo)) {
          setErrors({
            legajo: 'El legajo no es correcto',
          });
          setIsLoading(false);

          return;
        }

        // Compruebo si la fehca de nacimiento introducida es correcta
        if (
          fechaNacimiento.getTime()
          !== new Date(`${usuario.fecha_nacimiento}T00:00`).getTime()
        ) {
          setErrors({
            fechaNacimiento: 'La fecha de nacimiento no es correcta',
          });
          setIsLoading(false);

          return;
        }

        const res = await activarUsuario();
        if (res.data.success) {
          navigate('/inicio', { replace: true });
        }

        navigate('/inicio', { replace: true });
        setIsLoading(false);
      } catch (error) {
        resetForm();
        setIsLoading(false);
      }
    },
  });

  const submittion = (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    return validacionForm.handleSubmit();
  };

  return (
    <form onSubmit={submittion}>
      <Grid spacing={{ xs: 3, md: 4 }} container>
        <Grid xs={12} item>
          <FormikTextField
            id="legajo"
            label={t('pages.validar.legajo_input')}
            formik={validacionForm}
            validate={onlyNumbersValidate}
          />
        </Grid>
        <Grid xs={12} item>
          <DatePicker
            id="fechaNacimiento"
            label={t('pages.validar.fecha_nacimiento_input')}
            formik={validacionForm}
          />
        </Grid>
        <Grid xs={12} mt={{ xs: 0, md: 5 }} item>
          <Button
            variant="contained"
            disabled={!validacionForm.isValid || isLoading}
            fullWidth
            type="submit"
          >
            {t('botones.continuar')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
