export const PRIMARY_COLOR = '#22457C';
export const SECONDARY_COLOR = '#4082F6';
export const TERTIARY_COLOR = '#0B58A4';
export const PRIMARY_TEXT = '#3C3C3C';
export const SECONDARY_TEXT = '#707070';
export const DISABLED_COLOR = '#949494';
export const ERROR_COLOR = '#E1001B';
export const SUCCESS_COLOR = '#009D2C';
export const INFO_COLOR = '#1F549B';
export const LINE_COLOR = '#CBCBCB';
export const BACKGROUND_COLOR = '#FCFCFC';
