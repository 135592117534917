import { CARACTERES_TOTAL_CELULAR } from 'constants';
import * as Yup from 'yup';

export const validacionUsuarioCelularSchema = Yup.object({
  legajo: Yup.string()
    .required('Ingresa tu legajo')
    .length(6, 'El legajo debe tener 6 digitos'),
  fechaNacimiento: Yup.string().required('Ingresa tu fecha de nacimiento'),
  codigoArea: Yup.string()
    .required('Ingresá tu codigo de area')
    .min(2, 'Debe tener como minimo 2 digitos')
    .max(4, 'Debe tener como maximo 4 digitos'),
  numeroCelular: Yup.string()
    .when('codigoArea', (codigoArea, field) => {
      const codigoAreaString = codigoArea[0];
      const equalsLengthTo = codigoAreaString
        ? CARACTERES_TOTAL_CELULAR - codigoAreaString.length
        : 10;

      if (equalsLengthTo <= 8 && equalsLengthTo >= 6) {
        return field
          .min(equalsLengthTo, `Debe tener ${equalsLengthTo} caracteres`)
          .max(equalsLengthTo, `Debe tener ${equalsLengthTo} caracteres`);
      }

      return field;
    }).required('Ingresá tu numero de celular'),
});

export const validacionUsuarioSchema = Yup.object({
  legajo: Yup.string()
    .required('Ingresa tu legajo')
    .length(6, 'El legajo debe tener 6 digitos'),
  fechaNacimiento: Yup.string().required('Ingresa tu fecha de nacimiento'),
});

export const validacionCelularSchema = Yup.object({
  codigoArea: Yup.string()
    .required('Ingresá tu codigo de area')
    .min(2, 'Debe tener como minimo 2 digitos')
    .max(4, 'Debe tener como maximo 4 digitos'),
  numeroCelular: Yup.string()
    .when('codigoArea', (codigoArea, field) => {
      const codigoAreaString = codigoArea[0];
      const equalsLengthTo = codigoAreaString
        ? CARACTERES_TOTAL_CELULAR - codigoAreaString.length
        : 10;

      if (equalsLengthTo <= 8 && equalsLengthTo >= 6) {
        return field
          .min(equalsLengthTo, `Debe tener ${equalsLengthTo} caracteres`)
          .max(equalsLengthTo, `Debe tener ${equalsLengthTo} caracteres`);
      }

      return field;
    })
    .required('Ingresá tu numero de celular'),
});

export const validacionSmsSchema = Yup.object({
  codigo: Yup.string()
    .required('Ingresa el codigo de validacion')
    .length(6, 'El codigo debe tener 6 digitos'),
});
