import { useEffect, useState } from 'react';

export default function useDeviceHeight() {
  const [deviceHeight, setDeviceHeight] = useState(0);

  function handleChangeDeviceHeight() {
    setDeviceHeight(document.documentElement.clientHeight);
  }

  useEffect(() => {
    handleChangeDeviceHeight();

    window.addEventListener('resize', handleChangeDeviceHeight);

    return () => {
      window.removeEventListener('resize', handleChangeDeviceHeight);
    };
  }, []);

  return deviceHeight;
}
