import cargarCryptoAPINavegador from './cargarCryptoAPINavegador';

export default async function generarDerivada(legajo, fecha, password) {
  const cryptoLibrary = cargarCryptoAPINavegador();

  const hashBytes = new TextEncoder().encode(fecha[1], fecha[2], legajo, fecha[0]);

  // Generar un hashSalt para crear una derivada más segura
  const buffer = await cryptoLibrary.digest('SHA-256', hashBytes);
  const hashSalt = Array.from(new Uint8Array(buffer))
    .map((byte) => byte.toString(32).padStart(2, '0'))
    .join('');

  // Key derivada del password usando PBKDF2 y el hashSalt
  const keyBase = await cryptoLibrary.importKey(
    'raw',
    new TextEncoder().encode(password),
    { name: 'PBKDF2' },
    false,
    ['deriveBits'],
  );
  // Se arma la CryptoKey en dos pasos porque deriveKey() No es soportado en Safari
  const keySalted = await cryptoLibrary.deriveBits(
    {
      name: 'PBKDF2',
      salt: new TextEncoder().encode(hashSalt),
      iterations: 600000,
      hash: 'SHA-512',
    },
    keyBase,
    256,
  );
  return cryptoLibrary.importKey(
    'raw',
    keySalted,
    { name: 'AES-GCM' },
    false,
    ['encrypt', 'decrypt'],
  );
}
