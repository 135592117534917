import {
  Navigate,
} from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const ProtectedRoute = ({ children }) => {
  const { state: { session } } = useAuth();

  if (!session) {
    return <Navigate to="/ingresar" replace />;
  }

  return children;
};

export default ProtectedRoute;
