import * as Yup from 'yup';

const ingresarSchema = Yup.object({
  documento: Yup.string()
    .test('len', 'El formato del documento no es válido', (val) => {
      if (val) {
        return val.length >= 7 && val.length <= 8 && !Number.isNaN(val);
      }
      return false;
    })
    .required('Ingresa tu documento'),
  password: Yup.string()
    .required('Ingresá tu contraseña')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿]{8,32}$/,
      'La contraseña no cumple los requisitos',
    ),
});

export default ingresarSchema;
