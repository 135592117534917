import * as Yup from 'yup';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const cambioCorreoSchema = Yup.object({
  correoActual: Yup.string()
    .email('El correo no es valido')
    .required('Ingresa tu correo actual'),
  correoNuevo: Yup.string()
    .email('El correo no es valido')
    .when('correoActual', (correoActual, field) => {
      if (emailRegex.test(correoActual)) {
        return field.notOneOf(
          [Yup.ref('correoActual')],
          'El correo nuevo es igual al actual',
        );
      } 
      return field;
    })
    .required('Ingresa tu correo nuevo'),
  correoNuevoRepetido: Yup.string()
    .email('El correo no es valido')
    .oneOf([Yup.ref('correoNuevo')], 'Los correos no coinciden')
    .required('Ingresa nuevamente tu correo nuevo'),
  contraseña: Yup.string()
    .required('Ingresá tu contraseña')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿]{8,32}$/,
      'La contraseña no cumple los requisitos',
    ),
});

export default cambioCorreoSchema;
