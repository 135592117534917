// Importa las funciones necesarias de los SDK que necesitas
import { initializeApp, getApps } from 'firebase/app';
import {
  getAnalytics,
  logEvent as firebaseLogEvent,
  isSupported,
} from 'firebase/analytics';
import {
  API_KEY,
  APP_ID,
  AUTH_DOMAIN,
  MEASUREMENTE_ID,
  MESSAGING_SENDER_ID,
  PROYECT_ID,
  STORAGE_BUCKET,
} from 'constants/firebase';

// Configuración de Firebase para tu aplicación web
// Para Firebase JS SDK v7.20.0 en adelante, measurementId es opcional
const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROYECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENTE_ID,
};

let app;
let analytics;

let firebaseInstance = null;

async function initializeFirebase() {
  if (firebaseInstance) {
    return firebaseInstance;
  }

  // Verificar compatibilidad antes de inicializar la aplicación
  if (await isSupported()) {
    if (!getApps().length) {
      app = initializeApp(firebaseConfig);
    } else {
      // Si la app ya está inicializada, obtén la instancia existente
      [app] = getApps();
    }

    analytics = getAnalytics(app);

    // Función modificada para recibir todos los parámetros excepto analytics
    const logEvent = (eventName, eventParams) => {
      // Completar la instancia de analytics
      firebaseLogEvent(analytics, eventName, eventParams);
    };

    firebaseInstance = { app, analytics, logEvent };

    return firebaseInstance;
  } 
  // eslint-disable-next-line no-console
  console.error('Firebase Analytics no es compatible en este navegador.');
  return null;
}

export default initializeFirebase;
