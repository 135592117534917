import { arrayBufferToBase64, generarIV } from './bufferHelpers';
import cargarCryptoAPINavegador from './cargarCryptoAPINavegador';
import generarDerivada from './generarDerivada';

export default async function generarParDeClaves(leg, fec, password) {
  const cryptoLibrary = cargarCryptoAPINavegador();

  const legajo = leg.toString().padStart(6, '0');
  const fecha = fec.split('-');

  const keyDerivada = await generarDerivada(legajo, fecha, password);

  // Genera el par de llaves publica-privada
  const parKeysRSA = await cryptoLibrary.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 4096,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: 'SHA-512',
    },
    true,
    ['encrypt', 'decrypt'],
  );

  // Exportar key privada y publica
  const keyPrivada = await cryptoLibrary.exportKey(
    'pkcs8',
    parKeysRSA.privateKey,
  );
  const keyPublica = await cryptoLibrary.exportKey(
    'spki',
    parKeysRSA.publicKey,
  );

  // Encripta la key privada con la key derivada del password
  const keyPrivadaEncriptada = await cryptoLibrary.encrypt(
    { name: 'AES-GCM', iv: generarIV(legajo), tagLength: 128 },
    keyDerivada,
    keyPrivada,
  );

  // Convierte las claves a base64 para almacenarlas
  const keyPrivadaEncriptadaBase64 = arrayBufferToBase64(keyPrivadaEncriptada);
  const keyPublicaBase64 = arrayBufferToBase64(keyPublica);

  return { publicKey: keyPublicaBase64, encryptedKey: keyPrivadaEncriptadaBase64 };
}
