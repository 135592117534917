/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect } from 'react';
import {
  AppBar as MuiAppbar,
  Toolbar,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MiPortalIcon from 'components/icons/MiPortal';
import { Context as MobileAppbarContext } from 'contexts/MobileAppbarContext';
import { useLocation, useNavigate } from 'react-router-dom';

function AppBar({ drawerWidth, handleDrawerToggle }) {
  const location = useLocation();
  const {
    updateTitle,
    defaultLeftButton,
    defaultRightButton,
    state: { title, leftButton, rightButton },
  } = useContext(MobileAppbarContext);
  const navigation = useNavigate();

  useEffect(() => {
    updateTitle('');
    defaultLeftButton();
    defaultRightButton();
    // eslint-disable-next-line
  }, [location]);

  return (
    <MuiAppbar
      position="fixed"
      sx={{
        width: { lg: `calc(100% - ${drawerWidth.desktop}px)` },
        ml: { lg: `${drawerWidth.desktop}px` },
        backdropFilter: 'blur(5px)',
      }}
      color="transparent"
      elevation={0}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {leftButton ? (
          <>{leftButton}</>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="abrir menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )}

        {title ? (
          <Typography>{title}</Typography>
        ) : (
          <MiPortalIcon
            onClick={() => navigation('/inicio')}
            style={{ flex: 1, cursor: 'pointer' }}
            width={130}
            height={30}
          />
        )}

        {rightButton ? (
          <>{rightButton}</>
        ) : (
          <Box sx={{ width: { xs: 30, md: 40 }, height: { xs: 40, md: 48 } }} />
        )}
      </Toolbar>
    </MuiAppbar>
  );
}

export default React.memo(AppBar);
