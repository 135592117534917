import { useEffect, useState } from 'react';
import {
  setRowsDatagrid,
  updateColumnsDatagrid,
} from 'components/dataGrid/DataGridCard';
import { GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';
import { DEFAULT_CELL } from 'constants';
import DataGrid from 'components/dataGrid/DataGridComponent';
import {
  Box,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { IconFileDownload } from '@cooperativaobrera/react-icons/tabler-icons';
import dateStringToFormatedDate from 'utils/dateStringToFormatedDate';
import getStatusColor from '../utils/getStatusColor';
import {
  ESTADO_APROBADO,
  ESTADO_ENVIADO,
  ESTADO_RECHAZADO,
  ESTADO_SOLICITADO,
} from '../constants';

const estados = [
  {
    value: ESTADO_APROBADO,
    label: 'Aprobado',
  },
  {
    value: ESTADO_SOLICITADO,
    label: 'Solicitado',
  },
  {
    value: ESTADO_RECHAZADO,
    label: 'Rechazado',
  },
  {
    value: ESTADO_ENVIADO,
    label: 'Enviado',
  },
];

function getColumns(theme, descargarCertificadoLaboral) {
  return [
    {
      field: 'fecha',
      headerName: 'Fecha',
      cellClassName: DEFAULT_CELL,
      width: 120,
      valueFormatter: (params) => dateStringToFormatedDate(`${params.value}T00:00`),
    },
    {
      field: 'presentado_ante',
      headerName: 'Presentar ante',
      cellClassName: DEFAULT_CELL,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        const estado = estados.find((e) => e.value === params.value);
        const { backgroundColor, color } = getStatusColor(
          estado.value,
          params.row.descargar,
          theme,
        );

        return (
          <Tooltip placement="left" title={params.row.observacion}>
            <Box
              sx={{
                width: 'calc(100% + 10px)',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ml: -1.2,
                backgroundColor,
                borderRadius: 1,
                ...(params.row.observacion && {
                  cursor: 'pointer',
                }),
              }}
            >
              <Typography sx={{ color }} fontSize={14}>
                {estado.label}
              </Typography>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'descargar',
      headerName: 'Descargar',
      width: 100,
      type: 'actions',
      cellClassName: 'actions',
      resizable: false,
      getActions: ({ row }) => {
        return row.descargar
          ? [
            <GridActionsCellItem
              icon={<IconFileDownload />}
              onClick={() => descargarCertificadoLaboral(row.id)}
              label="Rechazar"
              color="inherit"
            />,
          ]
          : [];
      },
    },
  ];
}

export default function DataGridHistorial({
  historial,
  descargarCertificadoLaboral,
}) {
  const apiRef = useGridApiRef();
  const [rows] = useState([]);
  const [columns] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setRowsDatagrid(historial, apiRef);
  }, [historial]);

  useEffect(() => {
    updateColumnsDatagrid(
      getColumns(theme, descargarCertificadoLaboral),
      apiRef,
    );
  }, []);

  return (
    <Box height={500}>
      <DataGrid rows={rows} columns={columns} apiRef={apiRef} withoutHeader />
    </Box>
  );
}
