import { Grid, Typography, Button } from '@mui/material';
import InputCodigo from 'components/inputCodigo/InputCodigoComponent';
import Modal from 'components/modal/ModalComponent';
import {
  EMAIL_CODE_DIGITOS,
} from 'constants/validacion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  title: {
    fontWeight: 700,
  },
};

export default function ValidaTusDatosModal({ modalState, setModalState }) {
  const { t } = useTranslation();
  const [finalizoElTiempo, setFinalizoElTiempo] = useState(false);

  const handleClose = () => {
    setModalState((prevState) => ({
      ...prevState,
      state: false,
      tiempoSegundos: 0,
      emailCodeError: false,
      onComplete: () => {},
    }));
  };

  const handleClickReintentar = () => {
    setFinalizoElTiempo(false);
  };

  const handleComplete = async (value) => {
    modalState.onComplete(value);
  };

  useEffect(() => {
    handleClickReintentar();
  }, [modalState.state]);

  return (
    <Modal open={modalState.state} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography sx={styles.title}>
            {t('pages.seguridad.modales.valida_tus_datos.titulo')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">
            {t('pages.seguridad.modales.valida_tus_datos.mensaje', {
              digitos: modalState.digitos,
              correo: modalState.correo,
            })}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          {finalizoElTiempo ? (
            <Button variant="contained" onClick={handleClickReintentar} fullWidth>
              {t('botones.reintentar')}
            </Button>
          ) : (
            <InputCodigo
              error={modalState.emailCodeError}
              digitos={EMAIL_CODE_DIGITOS}
              handleFinalizoElTiempo={() => setFinalizoElTiempo(true)}
              handleComplete={handleComplete}
              tiempo={modalState.tiempoSegundos}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
}
