import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { validacionSmsSchema } from 'schemas/validacion.schema';
import InputCodigo from 'components/inputCodigo/InputCodigoComponent';
import { SMS_CODE_DIGITOS } from 'constants/validacion';
import { activarUsuario, verifySMSCode } from 'services/VerifyService';
import useEmpleado from 'hooks/useEmpleado';
import capitalizeFirstLetterOfEachWord from 'utils/capitalizeFirstLetterOfEachWord';
import useAlerts from 'hooks/useAlerts';
import onlyNumbersValidate from 'utils/onlyNumbersValidate';

export default function ValidacionSmsForm({
  handleClickReintentar,
  smsCodeTiempoSegundos,
  telefonoArea,
  telefonoNumero,
}) {
  const { t } = useTranslation();
  const { openSnackbar } = useAlerts();
  const [finalizoElTiempo, setFinalizoElTiempo] = useState(false);
  const {
    state: { usuario },
    updateUsuario,
  } = useEmpleado();
  const navigate = useNavigate();

  const initialValues = {
    nombreApellido: capitalizeFirstLetterOfEachWord(
      `${usuario.nombre} ${usuario.apellido}`,
    ),
    documento: usuario.nro_documento,
    codigo: '',
  };

  const validacionForm = useFormik({
    initialValues,
    validationSchema: validacionSmsSchema,
    onSubmit: async (data, options) => {
      try {
        const res = await verifySMSCode(data.codigo);
        if (res.data.success) {
          const res2 = await activarUsuario();
          if (res2.data.success) {
            updateUsuario({
              telefono_area: telefonoArea,
              telefono_numero: telefonoNumero,
            });
            navigate('/inicio', { replace: true });
          } else {
            openSnackbar({
              message: res2.data.message,
              severity: 'error',
            });
          }
        } else {
          openSnackbar({
            message: res.data.message,
            severity: 'error',
          });
        }
      } catch (error) {
        options.resetForm();
      }
    },
  });

  return (
    <form onSubmit={validacionForm.handleSubmit}>
      <Grid spacing={{ xs: 3, md: 4 }} container>
        <Grid xs={12} md={6} item>
          <FormikTextField
            id="nombreApellido"
            label={t('pages.validar.nombre_apellido_input')}
            formik={validacionForm}
            validate={onlyNumbersValidate}
            disabled
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <FormikTextField
            id="documento"
            label={t('pages.validar.documento_input')}
            formik={validacionForm}
            validate={onlyNumbersValidate}
            disabled
          />
        </Grid>
        {finalizoElTiempo ? (
          <Grid xs={12} item>
            <Button variant="contained" onClick={handleClickReintentar} fullWidth>
              {t('botones.reintentar')}
            </Button>
          </Grid>
        ) : (
          <>
            <Grid xs={12} item>
              <Typography variant="body2">
                {t('pages.validar.mensaje_codigo_sms')}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <InputCodigo
                error={false}
                digitos={SMS_CODE_DIGITOS}
                handleFinalizoElTiempo={() => setFinalizoElTiempo(true)}
                handleComplete={async (codigo) => {
                  await validacionForm.setFieldValue('codigo', codigo);
                  validacionForm.handleSubmit();
                }}
                tiempo={smsCodeTiempoSegundos}
              />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}
