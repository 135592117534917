import empleadosApi from 'apis/EmpleadosApi';

export const solicitarCertificadoLaboral = async (presentar_ante, firma_certificada) => {
  return empleadosApi.post('/documentos/constancia_laboral', { presentar_ante, firma_certificada });
};

export const getHistorialSolicitudes = async () => {
  return empleadosApi.get('/documentos/constancia_laboral');
};

export const getPdfSolicitud = async (id) => {
  return empleadosApi.get(`documentos/constancia_laboral/${id}`);
};
