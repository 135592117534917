import CooperativaObreraIcon from 'components/icons/CooperativaObrera';
import MiPortalWithoutTextIcon from 'components/icons/MiPortalWithoutText';
import AdeccoIcon from 'components/icons/empresas/Adecco';
import AsesoresIcon from 'components/icons/empresas/Asesores';
import BorlenghiIcon from 'components/icons/empresas/Borlenghi';
import ColmacoIcon from 'components/icons/empresas/Colmaco';
import GrupoGestionIcon from 'components/icons/empresas/GrupoGestion';
import ManpowerIcon from 'components/icons/empresas/Manpower';
import NuevaCardIcon from 'components/icons/empresas/NuevaCard';
import PrimerPrecioIcon from 'components/icons/empresas/PrimerPrecio';
import RandstadIcon from 'components/icons/empresas/Randstad';
import UnicaIcon from 'components/icons/empresas/Unica';

/**
 * Retorna el ícono correspondiente a la empresa
 * @param {int} idEmpresa el id de la empresa
 */
export default function obtenerLogoEmpresa(idEmpresa) {
  switch (idEmpresa) {
    case 1:
    case 14:
      return CooperativaObreraIcon;
    case 2:
      return UnicaIcon;
    case 3:
      return NuevaCardIcon;
    case 5:
      return PrimerPrecioIcon;
    case 6:
      return ColmacoIcon;
    case 10:
      return BorlenghiIcon;
    case 11:
      return AdeccoIcon;
    case 12:
      return RandstadIcon;
    case 13:
    case 22:
      return AsesoresIcon;
    case 18:
      return ManpowerIcon;
    case 23:
      return GrupoGestionIcon;
    case 24:
    default:
      return MiPortalWithoutTextIcon;
  }
}
