import { Grid, Typography, Button } from '@mui/material';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import TextButtonComponent from 'components/buttons/TextButtonComponent';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SecureFormikTextField from 'components/inputs/SecureInputComponent';
import onlyNumbersValidate from 'utils/onlyNumbersValidate';

export default function IngresarForm({
  formik,
  setShouldValidateOnChange,
  isLoading,
}) {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const submittion = (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    return formik.handleSubmit();
  };

  const handleClickOlvideContraseña = () => {
    navigation('/restablecer-contraseña');
  };

  return (
    <form onSubmit={submittion}>
      <Grid spacing={{ xs: 3, md: 4 }} container>
        <Grid xs={12} item>
          <FormikTextField
            id="documento"
            label={t('pages.ingresar.usuario_input')}
            formik={formik}
            validate={onlyNumbersValidate}
          />
        </Grid>
        <Grid xs={12} item>
          <SecureFormikTextField
            id="password"
            label={t('pages.ingresar.contraseña_input')}
            formik={formik}
            helperText={t('pages.ingresar.contraseña_input_help')}
          />
        </Grid>
        <Grid xs={12} item>
          <TextButtonComponent
            onClick={handleClickOlvideContraseña}
            noHover
            fullHeight
            underline
          >
            <Typography variant="body2">
              {t('botones.olvidaste_contraseña')}
            </Typography>
          </TextButtonComponent>
        </Grid>
        <Grid xs={12} mt={{ xs: 0, md: 5 }} item>
          <Button
            variant="contained"
            disabled={!formik.isValid || isLoading}
            fullWidth
            type="submit"
          >
            {isLoading ? t('botones.cargando') : t('botones.ingresar')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
