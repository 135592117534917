export default function RandstadIcon({ width = 68, height = 32, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 68 32"
      {...props}
    >
      <path
        d="M30.996 11.6715C30.996 11.6715 20.332 1.00744 20.3285 1.00744C19.6945 0.355974 18.835 -4.60518e-07 17.902 -4.60518e-07H0V6.81185H19.5991C20.334 6.80975 21.0619 6.9534 21.7408 7.23449C22.4198 7.51558 23.0362 7.92853 23.5545 8.44943C24.0757 8.96751 24.4888 9.58391 24.7699 10.2629C25.051 10.9419 25.1945 11.6699 25.1921 12.4048V32H32V14.0976C32 13.1681 31.644 12.3055 30.996 11.6715Z"
        fill="#1D75DD"
      />
      <path
        d="M50.0961 -4.60518e-07C49.1665 -4.60518e-07 48.303 0.355974 47.6693 1.00744L37.0036 11.6715C36.3556 12.3055 36 13.1685 36 14.098V32H42.8087V12.4039C42.8087 9.31892 45.3165 6.81185 48.4018 6.81185H68V-4.60518e-07H50.0961Z"
        fill="#1D75DD"
      />
    </svg>
  );
}
