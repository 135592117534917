import { useState, useRef } from 'react';

import { Box } from '@mui/material';
import { MAX_SIZE_UPLOAD_IMAGE } from 'constants';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import canvasPreview from './canvasPreview';
import useDebounceEffect from './useDebounceEffect';

import 'react-image-crop/dist/ReactCrop.css';

const styles = {
  container: {
    textAlign: 'center',
    '& .ReactCrop__crop-selection': {
      border: '1px solid #fff',
    },

    '& .ReactCrop__drag-handle::after': {
      backgroundColor: 'transparent',
    },
  },
};

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export default function CropImagen({
  inputRef,
  previewCanvasRef,
  completedCrop,
  setCompletedCrop,
  imgSrc,
  setImgSrc,
  handleMaxSizeError,
}) {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();

  const scale = 1;
  const rotate = 0;
  const aspect = 1;

  function onSelectFile(e) {
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }

    e.target.value = null;

    setCrop(undefined); // Makes crop preview update between images.
    const reader = new FileReader();

    reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));

    if (fileObj.size <= MAX_SIZE_UPLOAD_IMAGE) {
      reader.readAsDataURL(fileObj);
    } else {
      handleMaxSizeError();
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width
        && completedCrop?.height
        && imgRef.current
        && previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop],
  );

  return (
    <>
      <div className="Crop-Controls">
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          onChange={onSelectFile}
          accept="image/*"
        />
      </div>
      <Box sx={styles.container}>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            style={{ borderRadius: '10px', width: '100%' }}
          >
            <img style={{ width: '100%' }} ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
        )}
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              display: 'none',
            }}
          />
        )}
      </Box>
    </>
  );
}
