// Funcion de respaldo para navegadores viejos
function copiarAClipboardFallback(copiado) {
  const textArea = document.createElement('textarea');
  textArea.value = copiado;
  
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');

  document.body.removeChild(textArea);
}

export default async function copiarAClipboard(copiado) {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(copiado);
  } else {
    copiarAClipboardFallback(copiado);
  }
}
