/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  alpha,
  Box,
  Collapse,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React, {
  cloneElement, useContext, useEffect, useState, 
} from 'react';
import { Context as AuthContext } from 'contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { userHasPermissions } from 'utils/userPermissionsUtils';
import {
  ACCION, EXTERNO, INTERNO, MOODLE, 
} from 'constants/menu';
import { moodleToken } from 'services/CapacitacionService';
import useFirebase from 'hooks/useFirebase';
import useEmpleado from 'hooks/useEmpleado';

function List({ items, drawerOpen, handleDrawerToggle }) {
  const theme = useTheme();
  const location = useLocation();
  const navigation = useNavigate();
  const [openCollapse, setOpenCollapse] = useState(false);
  const { signout } = useContext(AuthContext);
  const {
    state: { permisos },
  } = useEmpleado();
  const {
    state: { firebaseInstance },
  } = useFirebase();
  const isMobile = useMobileBreakpoint();
  const [whiteSpace, setWhiteSpace] = useState('nowrap');
  let whiteSpaceTimeout;

  const handleClickMultipleItem = () => {
    setOpenCollapse((prevState) => !prevState);
  };

  const styles = {
    listItem: {
      border: 'none',
      outline: 'none',
      width: '100%',
      backgroundColor: 'transparent',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      padding: 2.2,
      transition: 'background-color 0.1s',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover, &:focus-visible': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      },
      '&[data-selected=true]': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      },
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      ml: 1,
      color: 'text.primary',
      whiteSpace,
      textAlign: 'left',
      opacity: 0,
      transition: 'opacity 0.2s',
      '&[data-is-selected=true]': {
        color: 'secondary.main',
        fontWeight: 'bold',
      },
      '&[data-is-visible=true]': {
        opacity: 1,
      },
    },
  };

  const handleClickExternalItem = (redirectTo) => {
    window?.open(redirectTo, '_blank');
    if (isMobile) {
      handleDrawerToggle();
    }

    firebaseInstance?.logEvent('screen_view', {
      page_title: redirectTo,
      type: 'external',
    });
  };

  const handleClickInternalItem = (redirectTo) => {
    if (redirectTo === '/ingresar') {
      signout();
    }

    firebaseInstance?.logEvent('screen_view', {
      page_title: redirectTo,
      type: 'internal',
    });

    navigation(redirectTo);
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  const moodleAction = () => {
    moodleToken().then((response) => {
      if (response?.data?.success && response.data.data) {
        const url = response.data.data.url_token;

        // Intenta abrir la URL en una nueva pestaña usando window.open
        const newWindow = window.open(url, '_blank');

        if (newWindow) {
          // Si la nueva pestaña se abrió correctamente, enfócate en ella
          newWindow.focus();
        } else {
          // Si la nueva pestaña no se pudo abrir (bloqueada por el navegador)
          // muestra un mensaje al usuario
          alert(
            'Por favor, permita las ventanas emergentes en su navegador para abrir el enlace. Vaya a Configuración > Bloquear ventanas emergentes y desactívelo.',
          );
        }
      }
    });

    firebaseInstance?.logEvent('screen_view', {
      page_title: 'moodle',
      type: 'external',
    });
  };

  const handleActionItem = (accion) => {
    switch (accion) {
      case MOODLE:
        moodleAction();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (drawerOpen) {
      whiteSpaceTimeout = setTimeout(() => {
        setWhiteSpace('break-spaces');
      }, [theme.transitions.duration.leavingScreen]);
    } else {
      setWhiteSpace('nowrap');
      clearTimeout(whiteSpaceTimeout);
    }

    return () => {
      clearTimeout(whiteSpaceTimeout);
    };
  }, [drawerOpen]);

  return (
    <>
      <Grid spacing={2} padding={2} container>
        {items.always
          ?.filter((i) => userHasPermissions(permisos, i.redirectTo))
          .map((item) => {
            const isSelected = location.pathname === item.redirectTo;

            return (
              <Grid key={item.id} xs={12} item>
                <Box>
                  <Box
                    onClick={() => {
                      switch (item.type) {
                        case INTERNO:
                          handleClickInternalItem(item.redirectTo);
                          break;
                        case EXTERNO:
                          handleClickExternalItem(item.redirectTo);
                          break;
                        case ACCION:
                          handleActionItem(item.redirectTo);
                          break;
                        default:
                          break;
                      }
                    }}
                    component="button"
                    sx={styles.listItem}
                    data-selected={isSelected}
                  >
                    <>
                      {isSelected
                        ? cloneElement(item.icon, { stroke: '#4082F6' })
                        : item.icon}
                    </>
                    <Typography
                      variant="body2"
                      data-is-visible={drawerOpen || isMobile}
                      data-is-selected={isSelected}
                      sx={styles.text}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        {isMobile && (
          <>
            {items.onMobile
              ?.filter((i) => userHasPermissions(permisos, i.redirectTo))
              .map((item) => {
                const isSelected = location.pathname === item.redirectTo;

                if (item.type === 'external-multiple') {
                  return (
                    <Grid key={item.id} xs={12} item>
                      <Box>
                        <Box
                          onClick={handleClickMultipleItem}
                          component="button"
                          sx={styles.listItem}
                        >
                          {item.icon}
                          <Typography sx={styles.text} color="primary.dark">
                            {item.text}
                            {openCollapse ? (
                              <ExpandLess sx={{ ml: 1 }} fontSize="inherit" />
                            ) : (
                              <ExpandMore sx={{ ml: 1 }} fontSize="inherit" />
                            )}
                          </Typography>
                        </Box>
                      </Box>
                      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        <Grid spacing={2} pl={3} pt={2} container>
                          {item.childrens?.map((i) => (
                            <Grid key={i.id} xs={12} item>
                              <Box>
                                <Box
                                  onClick={() => handleClickExternalItem(i.redirectTo)}
                                  component="button"
                                  sx={styles.listItem}
                                >
                                  {i.icon}
                                  <Typography
                                    sx={styles.text}
                                    color="primary.dark"
                                  >
                                    {i.text}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Collapse>
                    </Grid>
                  );
                }

                return (
                  <Grid key={item.id} xs={12} item>
                    <Box>
                      <Box
                        onClick={() => {
                          if (item.type === 'external') {
                            handleClickExternalItem(item.redirectTo);
                          } else {
                            handleClickInternalItem(item.redirectTo);
                          }
                        }}
                        component="button"
                        sx={styles.listItem}
                        data-selected={isSelected}
                      >
                        <>
                          {isSelected
                            ? cloneElement(item.icon, { fill: '#fff' })
                            : item.icon}
                        </>
                        <Typography
                          sx={styles.text}
                          color={isSelected ? 'white' : 'primary.dark'}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </>
        )}
      </Grid>
      <Grid spacing={2} px={2} pb={2} flex={1} alignItems="flex-end" container>
        {items.fixedBottom?.map((item) => {
          const isSelected = location.pathname === item.redirectTo;

          return (
            <Grid key={item.id} xs={12} item>
              <Box>
                <Box
                  component="button"
                  sx={styles.listItem}
                  data-selected={isSelected}
                  onClick={() => {
                    if (item.type === 'external') {
                      handleClickExternalItem(item.redirectTo);
                    } else {
                      handleClickInternalItem(item.redirectTo);
                    }
                  }}
                >
                  <>
                    {isSelected
                      ? cloneElement(item.icon, { stroke: '#4082F6' })
                      : item.icon}
                  </>

                  <Typography
                    variant="body2"
                    data-is-visible={drawerOpen || isMobile}
                    data-is-selected={isSelected}
                    sx={styles.text}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default React.memo(List);
