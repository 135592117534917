import { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import throttle from 'utils/throttle';

function PdfComponent({ pdf, pageNumber }) {
  const [width, setWidth] = useState(null);
  const pdfWrapperRef = useRef(null);

  useEffect(() => {
    function setDivSize() {
      if (pdfWrapperRef.current) {
        setWidth(pdfWrapperRef.current.offsetWidth);
      }
    }
    setDivSize();
    window.addEventListener('resize', throttle(setDivSize, 500));
    return () => {
      window.removeEventListener('resize', throttle(setDivSize, 500));
    };
  }, []);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        overflow: 'auto',
      }}
    >
      <div style={{ width: '100%' }} ref={pdfWrapperRef}>
        <Pdf wrapperDivSize={width} pdf={pdf} pageNumber={pageNumber} />
      </div>
    </div>
  );
}

function Pdf({ wrapperDivSize, pdf, pageNumber }) {
  return (
    <Document loading="Cargando..." file={pdf}>
      <Page
        pageNumber={pageNumber}
        width={wrapperDivSize}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        loading="Cargando..."
        error="No se pudo cargar el PDF"
        noData="Selecciona un archivo"
      />
    </Document>
  );
}

export default PdfComponent;
