import { userHasPermission } from 'utils/userPermissionsUtils';
import useEmpleado from './useEmpleado';

export default function useUserHasPermission(permiso) {
  const {
    state: { permisos },
  } = useEmpleado();

  return userHasPermission(permisos, permiso);
}
