import { Grid, Typography } from '@mui/material';
import Card from 'components/card/CardComponent';
import ListItem2 from 'components/infoItem/ListItem2Component';
import { useTranslation } from 'react-i18next';

export default function DatosDiasVacacionesMobile({ datos }) {
  const { t } = useTranslation();
  const {
    pendientes,
    planificadas,
    ganadas,
    gozadas,
    totales,
  } = datos;

  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={600} gutterBottom>
            {t('pages.vacaciones.subtitulo')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ListItem2
            label={`${t('pages.vacaciones.pendientes')}`}
            value={pendientes}
            labelColor="secondary"
          />
        </Grid>
        <Grid item xs={6}>
          <ListItem2
            label={`${t('pages.vacaciones.ganadas')}`}
            value={ganadas}
            labelColor="secondary"
          />
        </Grid>
        <Grid item xs={6}>
          <ListItem2
            label={`${t('pages.vacaciones.gozadas')}`}
            value={gozadas}
            labelColor="secondary"
          />
        </Grid>
        <Grid item xs={6}>
          <ListItem2
            label={`${t('pages.vacaciones.planificadas')}`}
            value={planificadas}
            labelColor="secondary"
          />
        </Grid>
        <Grid item xs={6}>
          <ListItem2
            label={`${t('pages.vacaciones.totales')}`}
            value={totales}
            labelColor="secondary"
          />
        </Grid>
      </Grid>
    </Card>
  );
}
