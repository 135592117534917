function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Espera un periodo en formato 200703 y convierte a Marzo de 2007
export default function formatPeriodo(periodo) {
  const year = periodo.substring(0, 4);
  const month = periodo.substring(4, 6);

  const fechaISO = `${year}-${month}-01`;

  const fecha = new Date(`${fechaISO}T00:00`);

  const options = { month: 'long', year: 'numeric' };

  return capitalizeFirstLetter(new Intl.DateTimeFormat('es-ES', options).format(fecha));
}

// Espera un periodo en formato 2007-03 y convierte a Marzo de 2007
export function formatPeriodo2(fechaString) {
  const [year, month] = fechaString.split('-');

  return `${month}-${year}`;
}
