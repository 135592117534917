export default function UbicacionIcon({
  width = 20,
  height = 22,
  stroke = '#4082F6',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 22"
      {...props}
    >
      <path
        d="M10.094 12.901a3.246 3.246 0 1 0 0-6.492 3.246 3.246 0 0 0 0 6.492Z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m16.214 15.776-4.59 4.591a2.164 2.164 0 0 1-3.06 0l-4.591-4.59a8.656 8.656 0 1 1 12.241 0Z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
