import {
  ESTADO_APROBADO,
  ESTADO_ENVIADO,
  ESTADO_RECHAZADO,
  ESTADO_SOLICITADO,
} from '../constants';

export function formatSolicitudesResponse(solicitudes) {
  return solicitudes.map(
    ({
      id_solicitud, tipo, created_at, status, data, 
    }) => ({
      id: id_solicitud,
      tipo,
      fecha: created_at,
      presentar_ante: data.presentado_ante,
      estado: status,
    }),
  );
}

export function getEstadoCertificado(estado, t) {
  switch (estado) {
    case ESTADO_APROBADO:
      return t('pages.solicitudes.aprobado');
    case ESTADO_RECHAZADO:
      return t('pages.solicitudes.rechazado');
    case ESTADO_SOLICITADO:
      return t('pages.solicitudes.solicitado');
    case ESTADO_ENVIADO:
      return t('pages.solicitudes.enviado');
    default:
      return `Estado ${estado} no configurado`;
  }
}

export function getSubtitle(tipoSolicitud) {
  switch (tipoSolicitud) {
    default:
      return `Solicitud ${tipoSolicitud} no configurada`;
  }
}
