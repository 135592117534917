import {
  TableCell, TableHead, TableSortLabel, Box,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export default function EnhancedTableHeadWithSorting({
  headCells,
  StyledTableHeadRow,
  order,
  orderBy,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableHeadRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sx={{ pl: headCell.disablePadding ? 0 : 'inherit', width: headCell.width }}
          >
            {headCell.allowSorting
              ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )
              : <Box>{ headCell.label }</Box> }
          </TableCell>
        ))}
      </StyledTableHeadRow>
    </TableHead>
  );
}
