import getTokenUsuarioAPI from 'services/TokenService';
import CreateDataContext from './CreateDataContext';

const empleadoReducer = (state, action) => {
  switch (action.type) {
    case 'setToken':
      return {
        ...state,
        token: action.payload.token,
        expiracionToken: action.payload.timestamp,
      };
    default:
      return state;
  }
};

const generarToken = (dispatch) => async () => {
  const res = await getTokenUsuarioAPI();
  const tokenData = { token: Number(res.data.data.token), timestamp: res.data.data.expires_at };

  dispatch({ type: 'setToken', payload: tokenData });

  return tokenData;
};

const limpiarToken = (dispatch) => async () => {
  dispatch({ type: 'setToken', payload: { token: null, timestamp: null } });
};

export const { Context, Provider } = CreateDataContext(
  empleadoReducer,
  {
    generarToken,
    limpiarToken,
  },
  {
    token: null,
    expiracionToken: null,
  },
);
