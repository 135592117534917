import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SeleccionarSolicitudCard from './components/SeleccionarSolicitudCardComponent';
import { CERTIFICADO_LABORAL } from './constants';

export default function SolicitudesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickSolicitud = (id) => {
    if (id === CERTIFICADO_LABORAL) {
      navigate('/solicitudes/certificado-laboral');
    }
  };

  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <Typography fontWeight={600}>
          {t('pages.solicitudes.titulo')}
        </Typography>
      </Grid>

      <Grid xs={12} item>
        <Typography>{t('pages.solicitudes.subtitulo')}</Typography>
      </Grid>

      <Grid xs={12} lg={4} item>
        <SeleccionarSolicitudCard handleClickSolicitud={handleClickSolicitud} />
      </Grid>
    </Grid>
  );
}
