export default function IngresoIcon({
  width = 21,
  height = 22,
  stroke = '#4082F6',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 22"
      {...props}
    >
      <path
        d="M17.188 3.25h-13.5a2.25 2.25 0 0 0-2.25 2.25V19a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25V5.5a2.25 2.25 0 0 0-2.25-2.25ZM14.938 1v4.5M5.938 1v4.5M1.438 10h18"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.188 14.5h-2.25v2.25h2.25V14.5Z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
