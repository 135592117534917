/* eslint-disable import/no-cycle */
import initializeFirebaseService from 'utils/firebase';
import CreateDataContext from './CreateDataContext';

const firebaseContext = (state, action) => {
  switch (action.type) {
    case 'setFirebaseInstance':
      return { ...state, firebaseInstance: action.payload };
    default:
      return state;
  }
};

const initializeFirebase = (dispatch) => async () => {
  initializeFirebaseService().then((firebaseInstance) => {
    dispatch({ type: 'setFirebaseInstance', payload: firebaseInstance });
  });
};

export const { Context, Provider } = CreateDataContext(
  firebaseContext,
  {
    initializeFirebase,
  },
  {
    firebaseInstance: null,
  },
);
