import CreateDataContext from 'contexts/CreateDataContext';
import {
  getEstadoRecibos,
  getRecibosDisponibles,
  getReciboPDF,
  bloquearRecibosDelUsuarioAPI,
} from 'services/RecibosService';
import desencriptarKeyRecibo from '../utils/desencriptarKeyRecibo';
import { RECIBOS_ESTADO_BLOQUEADO_TEMPORALMENTE } from '../constants';

const reciboReducer = (state, action) => {
  switch (action.type) {
    case 'setEstadoRecibo':
      return { ...state, estadoRecibo: Number(action.payload.estado) };
    case 'setIntentosIngreso': {
      if (action.payload.reset) {
        return { ...state, intentosIngreso: 0 };
      }
      return { ...state, intentosIngreso: state.intentosIngreso + action.payload.sumar };
    }
    case 'setKeyEncriptada':
      return { ...state, keyPrivadaEmpleadoEncriptada: action.payload.key };
    case 'setRecibos':
      return { ...state, recibos: action.payload.recibos };
    case 'setReciboSeleccionado': {
      const { periodo, tipo, file } = action.payload;

      return { ...state, reciboSeleccionado: { periodo, tipo, file } };
    }
    case 'updateRecibo': {
      const { periodo, tipo, file } = action.payload;
      const nuevoRecibos = state.recibos.map((recibo) => {
        if (recibo.periodo === periodo && recibo.tipo === tipo) {
          return {
            ...recibo,
            file,
          };
        }

        return recibo;
      });
      return { ...state, recibos: nuevoRecibos };
    }
    default:
      return state;
  }
};

const obtenerEstadoRecibo = (dispatch) => async () => {
  const res = await getEstadoRecibos();

  dispatch({ type: 'setEstadoRecibo', payload: { estado: res.data.data.estado } });
  dispatch({ type: 'setKeyEncriptada', payload: { key: res.data.data.encrypted_key } });

  return res.data.data.estado;
};

const updateEstadoRecibo = (dispatch) => (estado) => {
  dispatch({ type: 'setEstadoRecibo', payload: { estado } });
};

const obtenerRecibos = (dispatch) => async () => {
  const res = await getRecibosDisponibles();

  dispatch({ type: 'setRecibos', payload: { recibos: res.data.data.recibos } });
};

const obtenerReciboDesencriptado = (dispatch) => async (periodo, tipo, keyRecibo, keyEmpleado) => {
  const keyReciboDesencriptada = await desencriptarKeyRecibo(keyRecibo, keyEmpleado);
  const res = await getReciboPDF(periodo, tipo, keyReciboDesencriptada);

  dispatch({ type: 'updateRecibo', payload: { periodo, tipo, file: res.data.data.recibo } });

  return res.data.data.recibo;
};

const seleccionarRecibo = (dispatch) => async (periodo, tipo, file) => {
  dispatch({ type: 'setReciboSeleccionado', payload: { periodo, tipo, file } });
};

const sumarIntentoDeIngreso = (dispatch) => () => {
  dispatch({ type: 'setIntentosIngreso', payload: { sumar: 1, reset: false } });
};

const bloquearRecibosDelUsuario = (dispatch) => async () => {
  await bloquearRecibosDelUsuarioAPI();

  dispatch({ type: 'setEstadoRecibo', payload: { estado: RECIBOS_ESTADO_BLOQUEADO_TEMPORALMENTE } });
  dispatch({ type: 'setKeyEncriptada', payload: { key: '' } });
  dispatch({ type: 'setIntentosIngreso', payload: { reset: true } });
};

export const { Context, Provider } = CreateDataContext(
  reciboReducer,
  {
    obtenerEstadoRecibo,
    updateEstadoRecibo,
    obtenerRecibos,
    obtenerReciboDesencriptado,
    seleccionarRecibo,
    sumarIntentoDeIngreso,
    bloquearRecibosDelUsuario,
  },
  {
    estadoRecibo: 3,
    keyPrivadaEmpleadoEncriptada: null,
    recibos: [],
    reciboSeleccionado: null,
    intentosIngreso: 0,
  },
);
