import { Tooltip, tooltipClasses, styled } from '@mui/material';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.9rem',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    padding: 15,
    maxWidth: 450,
    marginRight: 12,
    marginLeft: 12,

    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
      padding: 10,
    },
  },
}));

export default LightTooltip;
