import {
  Grid, Typography,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import { useTranslation } from 'react-i18next';

export default function BloqueadoTemporalmentePage() {
  const { t } = useTranslation();

  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <Typography fontWeight="bold">
          {t('pages.recibo.titulo')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Card>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid xs={12} item>
              <Typography variant="body2" fontWeight={600}>
                {t('pages.recibo.bloqueado_temporalmente')}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="body2">
                {t('pages.recibo.bloqueado')}
              </Typography>
              <Typography variant="body2">
                {t('pages.recibo.recibos_consulta')}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
