import { IconAlertCircle } from '@cooperativaobrera/react-icons/tabler-icons';
import {
  Box,
  Grid,
  Typography,
  Button,
  alpha,
} from '@mui/material';
import Modal from 'components/modal/ModalComponent';
import { Trans, useTranslation } from 'react-i18next';

const styles = {
  title: {
    fontWeight: 700,
  },
  mensajeContainer: {
    border: `1.2px solid ${alpha('#000', 0.32)}`,
    p: 2,
    borderRadius: '10px',
  },
};

export default function VerificarDatosModal({ modalState, setModalState }) {
  const { t } = useTranslation();

  const handleClose = () => {
    setModalState((prevState) => ({
      ...prevState,
      state: false,
      enviarSolicitud: () => {},
    }));
  };

  return (
    <Modal open={modalState.state} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography sx={styles.title}>
            {t('pages.solicitudes.modales.verificar_datos.titulo')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">
            {t('pages.solicitudes.modales.verificar_datos.mensaje')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Box sx={styles.mensajeContainer}>
            <Typography variant="body2">
              <Trans t={t}>
                {t(
                  'pages.solicitudes.modales.verificar_datos.confirmar_solicitud',
                  { presentar_ante: modalState.presentar_ante },
                )}
              </Trans>
            </Typography>
          </Box>
        </Grid>
        {modalState.firma_certificada && (
          <Grid xs={12} item>
            <Box display="flex">
              <IconAlertCircle sx={{ fontSize: 20 }} color="warning" />
              <Typography variant="body2">
                <Trans t={t}>
                  {t(
                    'pages.solicitudes.modales.verificar_datos.aviso_firma_certificada',
                  )}
                </Trans>
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid xs={12} item>
          <Button
            variant="contained"
            onClick={modalState.enviarSolicitud}
            fullWidth
          >
            {modalState.firma_certificada
              ? t('botones.enviar_solicitud')
              : t('botones.descargar_certificado')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
