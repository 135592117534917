import {
  Grid, Typography,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import { Trans, useTranslation } from 'react-i18next';
import { RECIBOS_ESTADO_PENDIENTE_ALTA } from '../constants';
import useRecibo from '../hooks/useRecibo';

export default function PendienteHabilitacionPage() {
  const { t } = useTranslation();
  const { state: { estadoRecibo } } = useRecibo();

  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <Typography fontWeight="bold">
          {t('pages.recibo.titulo')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Card>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid xs={12} item>
              <Typography variant="body2" fontWeight={600}>
                {t('pages.recibo.solicitud_realizada')}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="body2" gutterBottom>
                <Trans t={t}>
                  {estadoRecibo === RECIBOS_ESTADO_PENDIENTE_ALTA ? t('pages.recibo.pendiente_alta') : t('pages.recibo.pendiente_cambio')}
                </Trans>
              </Typography>
              <Typography variant="body2">
                {t('pages.recibo.pendiente')}
              </Typography>
              <Typography variant="body2">
                {t('pages.recibo.recibos_consulta')}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
