import Snackbar from 'components/snackbar/SnackbarComponent';
import useAlerts from 'hooks/useAlerts';

export default function GlobalSnackbar() {
  const {
    closeSnackbar,
    state: { snackbarState },
  } = useAlerts();

  const handleCloseSnackbar = () => {
    closeSnackbar({
      state: false,
    });
  };

  return <Snackbar open={snackbarState} handleClose={handleCloseSnackbar} />;
}
