export default function SolicitudesIcon({
  width = 19,
  height = 23,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 19 23"
      {...props}
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.563 1v4.25a1.062 1.062 0 001.063 1.063h4.25"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.438 20.125H3.125A2.125 2.125 0 011 18V3.125A2.125 2.125 0 013.125 1h7.438l5.312 5.313v4.78"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.22 19.063a2.656 2.656 0 100-5.313 2.656 2.656 0 000 5.313zM15.344 18.531L18 21.188"
      />
    </svg>
  );
}
