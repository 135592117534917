export default function MiPortalWithoutTextIcon({
  width = 46,
  height = 46,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 52 54"
      {...props}
    >
      <path fill="#D9D9D9" d="M19.977 36.325h2.802v2.766h-2.802z" />
      <path
        fill="#E9332A"
        stroke="#E9332A"
        strokeLinejoin="round"
        d="M48.648 16.872c-17.217-3.475-25.932 5.04-28.578 9.311 1.195 8.409 7.471 12.109 10.46 12.908 11.506 3.245 17.744-4.549 19.425-8.851 2.391-6.048.125-11.432-1.307-13.368Z"
      />
      <path
        fill="#0B58A4"
        stroke="#0B58A4"
        strokeLinejoin="round"
        d="M18.015 29.594c-2.259-8.698-12.794-8.82-16.81-7.56-1.868 15.71 9.495 23.879 15.41 26 16.66 6.416 28.048-4.395 31.66-10.603-4.11 5.31-12.484 5.532-16.157 4.979-9.34-1.77-13.169-9.22-14.103-12.816Z"
      />
      <ellipse cx={28.755} cy={6.361} fill="#E9332A" rx={6.537} ry={6.361} />
      <ellipse cx={11.665} cy={13.184} fill="#0B58A4" rx={6.257} ry={6.177} />
    </svg>
  );
}
