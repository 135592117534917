import { Grid, Typography, Button } from '@mui/material';
import CropImagen from 'components/cropImagen/CropImagen';
import Modal from 'components/modal/ModalComponent';
import { MAX_SIZE_UPLOAD_IMAGE } from 'constants';
import useEmpleado from 'hooks/useEmpleado';
import useSnackbar from 'hooks/useSnackbar';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateUserPicture } from 'services/AuthService';
import canvasToFile from 'utils/canvasToFile';
import resizeFile from 'utils/resizeFile';

export default function EditarFotoModal({
  modalState,
  setModalState,
  inputRef,
}) {
  const { updateUsuario } = useEmpleado();
  const previewCanvasRef = useRef(null);
  const { t } = useTranslation();
  const [completedCrop, setCompletedCrop] = useState();
  const [imgSrc, setImgSrc] = useState('');
  const { openSnackbar } = useSnackbar();

  const handleClose = () => {
    setCompletedCrop(undefined);
    setImgSrc('');
    setModalState({
      state: false,
    });
  };

  const handleClickSubirFoto = async () => {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }

    const fileObj = await canvasToFile(previewCanvasRef.current, 'foto_perfil.jpeg');
    const image = await resizeFile(fileObj);

    try {
      const res = await updateUserPicture(image);
      if (res.data.success) {
        updateUsuario({ foto: res.data.data.foto });
        openSnackbar({
          message: res.data.message,
          severity: 'success',
        });
      } else {
        openSnackbar({
          message: res.data.message,
          severity: 'error',
        });
      }
      handleClose();
    } catch (error) {
      // console.error(error);
    }
  };

  const handleMaxSizeError = () => {
    openSnackbar({
      message: t('pages.inicio.modales.editar_foto.error_max_size', {
        mb: MAX_SIZE_UPLOAD_IMAGE / 1000000,
      }),
      severity: 'error',
    });
    setModalState({
      state: false,
    });
  };

  useEffect(() => {
    if (completedCrop) {
      setModalState({
        state: true,
      });
    }
  }, [completedCrop]);

  return (
    <Modal open={modalState.state} handleClose={handleClose} keepMounted>
      <Grid spacing={{ xs: 1, md: 2 }} container>
        <Grid xs={12} item>
          <Typography fontWeight={600}>
            {t('pages.inicio.modales.editar_foto.titulo')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <CropImagen
            {...{
              inputRef,
              previewCanvasRef,
              completedCrop,
              setCompletedCrop,
              handleMaxSizeError,
              imgSrc,
              setImgSrc,
            }}
          />
        </Grid>
        <Grid xs={12} item>
          <Button variant="contained" onClick={handleClickSubirFoto} fullWidth>
            {t('botones.guardar_cambios')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
