import { alpha } from '@mui/material';
import {
  ESTADO_CANCELADO,
  ESTADO_INCISO_51,
  ESTADO_NO_OTORGADO,
  ESTADO_OTORGADO,
  ESTADO_SOLICITADO,
} from '../constants';

export default function getStatusColor(id, theme) {
  switch (Number(id)) {
    case ESTADO_SOLICITADO:
      return {
        backgroundColor: alpha(theme.palette.info.main, 0.2),
        color: theme.palette.info.main,
      };
    case ESTADO_CANCELADO:
    case ESTADO_INCISO_51:
      return {
        backgroundColor: alpha(theme.palette.error.main, 0.2),
        color: theme.palette.error.main,
      };
    case ESTADO_NO_OTORGADO:
      return {
        backgroundColor: alpha(theme.palette.warning.main, 0.2),
        color: theme.palette.warning.main,
      };
    case ESTADO_OTORGADO:
      return {
        backgroundColor: alpha(theme.palette.success.main, 0.2),
        color: theme.palette.success.main,
      };
    default:
      return {
        backgroundColor: alpha(theme.palette.common.black, 0.2),
        color: theme.palette.common.white,
      };
  }
}
