export default function ReciboSueldoIcon({
  width = 14,
  height = 17,
  stroke = '#3C3C3C',
  strokeWidth = 1.2,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 21"
      style={{ minWidth: width }}
      {...props}
    >
      <g
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      >
        <path d="M13.8785 13.1927C13.6545 12.7555 13.2337 12.4923 12.7856 12.5096H11.5705C10.9002 12.5096 10.3564 13.1198 10.3564 13.8758C10.3564 14.629 10.9002 15.2411 11.5705 15.2411H12.7856C13.4559 15.2411 13.9997 15.8522 13.9997 16.6073C13.9997 17.3615 13.4559 17.9726 12.7856 17.9726H11.5705C11.1224 17.9899 10.7016 17.7267 10.4776 17.2895" />
        <path d="M12.1777 10.6875V12.5091" />
        <path d="M12.1777 17.9736V19.7952" />
        <path d="M9.19727 1V4.64322C9.19727 4.88478 9.29323 5.11645 9.46403 5.28726C9.63484 5.45807 9.86651 5.55403 10.1081 5.55403H13.7513" />
        <path d="M7.95524 17.3945H2.82161C2.33849 17.3945 1.87516 17.2026 1.53354 16.861C1.19192 16.5193 1 16.056 1 15.5729V2.82161C1 2.33849 1.19192 1.87516 1.53354 1.53354C1.87516 1.19192 2.33849 1 2.82161 1H9.19725L13.7513 5.55403V8.61765" />
        <path d="M4.8916 12.6748H7.37562" />
        <path d="M4.64355 9.19727H10.1084" />
      </g>
    </svg>
  );
}
