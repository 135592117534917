import { Box } from '@mui/material';

export default function ModalActions({
  children,
  sx,
  ...restOfProps
}) {
  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
      gap={2}
      sx={sx}
      {...restOfProps}
    >
      {children}
    </Box>
  );
}
