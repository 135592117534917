import { getAyuda } from 'services/AyudaService';

const nextStepNotFound = async (trigger, setSteps) => {
  const res = await getAyuda(trigger === '1' ? undefined : trigger);
  const steps = res.data.data;
  if (steps.length > 1) {
    setSteps((prevState) => [...prevState, ...steps]);
  }

  return steps[0];
};

export default nextStepNotFound;
