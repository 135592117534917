export const API_AUTH_BASEPATH = process.env.REACT_APP_API_ENDPOINT_CU;
export const API_PE_BASEPATH = process.env.REACT_APP_API_ENDPOINT_PE;

export const API_AUTH_CLIENT_ID = process.env.REACT_APP_CLIENTID_CU;
export const API_AUTH_GRAND_TYPE = process.env.REACT_APP_GRANDTYPE_CU;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const TIEMPO_INACTIVIDAD = 900000;

// TODO: Cambiar por url de modulo de registro
export const SIGNIN_URL = 'https://www.cooperativaobrera.coop/registro-de-usuario?back_url=';
export const BACK_URL = 'https://miportal.cooperativaobrera.coop';

export const CARACTERES_TOTAL_CELULAR = 10;

export const MAX_SIZE_UPLOAD_IMAGE = 2000000;

export const MUIX_LICENCE_KEY = process.env.REACT_APP_MUIX_KEY;

// DATAGRID CELLS
export const DEFAULT_CELL = 'Default-cell';
export const ALERT_CELL = 'Alert-cell';
export const ERROR_CELL = 'Error-cell';
