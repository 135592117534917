import { useState } from 'react';

export default function useModal(initState) {
  const [open, setOpen] = useState(initState);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  return {
    openModal,
    closeModal,
    modalState: open,
  };
}
