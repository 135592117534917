/* 
  Se setean las rutas para poder mapearlas con su respectiva parte del diccionario,
  tambien sirve para setear los permisos que requiere cada ruta para acceder a ella.

  WARNING: Los permisos no son heredables, si una pagina no posee permisos se supone
  que es de acceso publico
*/

import {
  PERMISO_AULA_VIRTUAL,
  PERMISO_BMJP,
  PERMISO_CERTIFICADO_LABORAL,
  PERMISO_RECIBO_SUELDO,
  PERMISO_RESERVA_SALON,
  PERMISO_RESUMEN_CUENTA,
  PERMISO_VACACIONES,
} from './permisos';

const pages = {
  name: '',
  children: {
    inicio: {
      name: 'inicio',
    },
    seguridad: {
      name: 'seguridad',
    },
    recibo: {
      name: 'recibo',
      permissions: [PERMISO_RECIBO_SUELDO],
      children: {
        'generar-pin': {
          name: 'generar-pin',
          permissions: [PERMISO_RECIBO_SUELDO],
        },
        pendiente: {
          name: 'pendiente',
          permissions: [PERMISO_RECIBO_SUELDO],
        },
      },
    },
    'tarjeta-empleado': {
      name: 'tarjeta-empleado',
      permissions: [PERMISO_RESUMEN_CUENTA, PERMISO_RESERVA_SALON, PERMISO_BMJP],
      children: {
        'resumen-cuenta': {
          name: 'resumen-cuenta',
          permissions: [PERMISO_RESUMEN_CUENTA],
        },
        'casa-personal': {
          name: 'casa-personal',
          permissions: [PERMISO_RESERVA_SALON],
        },
        bmpj: {
          name: 'bmpj',
          permissions: [PERMISO_BMJP],
        },
      },
    },
    vacaciones: {
      name: 'vacaciones',
      permissions: [PERMISO_VACACIONES],
    },
    aulavirtual: {
      name: 'aulavirtual',
      permissions: [PERMISO_AULA_VIRTUAL],
    },
    solicitudes: {
      name: 'tarjeta-empleado',
      permissions: [PERMISO_CERTIFICADO_LABORAL],
      children: {
        'certificado-laboral': {
          name: 'certificado-laboral',
          permissions: [PERMISO_CERTIFICADO_LABORAL],
        },
      },
    },
  },
};

export default pages;
