import * as Yup from 'yup';

const solicitarCasaPersonalSchema = Yup.object({
  salon: Yup.string().required('Seleccione un salón'),
  turno: Yup.string().required('Seleccione un turno'),
  fecha: Yup.string().required('Seleccione una fecha'),
  personas: Yup.string()
    .required('Introduzca la cantidad de personas')
    .test('limite-personas', 'Límite de personas excedido', (value, context) => {
      const { salon } = context.parent;

      if (salon === '1' && Number(value) > 40) {
        return false;
      }

      if (salon === '2' && Number(value) > 80) {
        return false;
      }

      return true;
    }),
  motivo: Yup.string().required('Seleccione un motivo'),
});

export default solicitarCasaPersonalSchema;
