import empleadosApi from 'apis/EmpleadosApi';

// eslint-disable-next-line import/prefer-default-export
export const getAyuda = async (page) => {
  return empleadosApi.get('/ayuda', { params: { page } });
};

export const enviarConsultaAyuda = async (consulta) => {
  return empleadosApi.post('/ayuda/consulta', { consulta });
};
