import getPagePermissions from './getPagePermissions';

/**
 * Chequea si dentro de los permisos del usuario se encuentra
 * el permiso para la ruta dada
 * @param {*} userPermissions los permisos del usuario
 * @param {*} pathname la ruta de la que se quiere chequear acceso
 * @returns true | false, tiene el permiso o no
 */
export function userHasPermissions(userPermissions, pathname) {
  const pagePermissions = getPagePermissions(pathname);

  if (!pagePermissions) {
    return true;
  }
  return userPermissions.some((permiso) => pagePermissions.includes(permiso));
}

/**
 * Chequea si dentro de los permisos del usuario se encuentra el permiso
 * indicado para alguna acción
 * @param {*} userPermissions los permisos del usuario
 * @param {*} permiso el permiso que se quiere chequear
 * @returns true | false, tiene el permiso o no
 */
export function userHasPermission(userPermissions, permiso) {
  return userPermissions.some((p) => permiso === p);
}
