export function formatDatosVacacionesResponse(vacaciones) {
  const {
    anterior, planificadas, ganado, restantes, gozadas,
  } = vacaciones;
  const formatted = {
    pendientes: anterior,
    planificadas,
    ganadas: ganado,
    totales: restantes,
    gozadas,
  };

  return formatted;
}

export function formatHistorialVacacionesResponse(vacaciones) {
  const {
    historial,
  } = vacaciones;
  const formatted = historial.map(({
    desde, hasta, dias,
  }, index) => ({
    id: index,
    desde,
    hasta,
    dias,
  }));

  return formatted;
}
