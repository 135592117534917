import {
  Grid,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import SimpleTableComponent from 'components/table/SimpleTableComponent';
import { useTranslation } from 'react-i18next';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { useEffect, useState } from 'react';
import dateStringToFormatedDate from 'utils/dateStringToFormatedDate';

export default function HistorialVacacionesCard({ historial }) {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();
  const [rows, setRows] = useState([]);

  const headCells = [
    {
      id: 'desde',
      numeric: false,
      align: 'left',
      label: isMobile ? `${t('pages.vacaciones.desde_mobile')}` : `${t('pages.vacaciones.desde')}`,
      width: '40%',
      allowSorting: true,
    },
    {
      id: 'hasta',
      numeric: false,
      align: 'left',
      label: isMobile ? `${t('pages.vacaciones.hasta_mobile')}` : `${t('pages.vacaciones.hasta')}`,
      width: '40%',
      allowSorting: true,
    },
    {
      id: 'dias',
      numeric: false,
      align: 'left',
      label: `${t('pages.vacaciones.dias')}`,
      width: '20%',
    },
  ];

  useEffect(() => {
    setRows(historial.map((row) => ({
      ...row,
      desde: dateStringToFormatedDate(`${row.desde}T00:00`),
      hasta: dateStringToFormatedDate(`${row.hasta}T00:00`),
    })));
  }, [historial]);

  return (
    <Card>
      <Grid container spacing={2}>
        <SimpleTableComponent headCells={headCells} rows={rows} sorting />
      </Grid>
    </Card>
  );
}
