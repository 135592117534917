import {
  Grid, Typography,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import NumericInfoItem from 'components/infoItem/NumericInfoItemComponent';
import { useTranslation } from 'react-i18next';

export default function DatosDiasVacacionesCard({ datos }) {
  const { t } = useTranslation();
  const {
    pendientes,
    planificadas,
    ganadas,
    gozadas,
    totales,
  } = datos;

  return (
    <Card>
      <Typography variant="body2" fontWeight={600} gutterBottom>
        {t('pages.vacaciones.subtitulo')}
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={3} item>
          <NumericInfoItem
            title={t('pages.vacaciones.pendientes')}
            number={pendientes}
            typography="body2"
          />
        </Grid>
        <Grid xs={2} item>
          <NumericInfoItem
            title={t('pages.vacaciones.ganadas')}
            number={ganadas}
            typography="body2"
          />
        </Grid>
        <Grid xs={2} item>
          <NumericInfoItem
            title={t('pages.vacaciones.gozadas')}
            number={gozadas}
            typography="body2"
          />
        </Grid>
        <Grid xs={2.5} item>
          <NumericInfoItem
            title={t('pages.vacaciones.planificadas')}
            number={planificadas}
            typography="body2"
          />
        </Grid>
        <Grid xs={2.5} item>
          <NumericInfoItem
            title={t('pages.vacaciones.totales')}
            number={totales}
            typography="body2"
          />
        </Grid>
      </Grid>
    </Card>
  );
}
