import axios from 'axios';
import { UTOKEN } from 'constants/storage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAlerts from 'hooks/useAlerts';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { API_AUTH_BASEPATH, API_AUTH_CLIENT_ID } from '../constants';

/**
 * Instancia de API para comunicación con Coopeusuario
 */
const instance = axios.create({
  baseURL: API_AUTH_BASEPATH,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
  },
});
instance.defaults.timeout = 5000;

instance.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem(UTOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['x-api-key'] = API_AUTH_CLIENT_ID;
    return config;
  },
);

function AxiosInterceptor({ children }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSet, setIsSet] = useState(false);
  const { openSnackbar } = useAlerts();
  const {
    signout,
  } = useAuth();

  const handleLogout = () => {
    signout();
    navigate('/ingresar', { replace: true });
  };

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      let message;
      if (error.response) {
        const res = error.response;
        if (res.data && typeof res.data === 'object' && 'message' in res.data) {
          message = res.data.message;
        }
        switch (res.status) {
          case 401:
            message = res.data.message;
            handleLogout();
            break;
          case 429:
            message = res.data.message;
            break;
          case 400:
          case 403:
            message = res.data.message;
            break;
          case 404:
            message = res.data.message;
            break;
          case 409:
            break;
          case 500:
            message = t('dialogos.error.inesperado');
            break;
          default:
            break;
        }

        if (message) {
          openSnackbar({
            message,
            severity: 'error',
          });
        }
      } else if (error.message === 'Network Error') {
        message = 'Error de red. Por favor, verifica tu conexión a Internet.';
        openSnackbar({
          message,
          severity: 'error',
        });
      } else if (error.message === 'Request failed with status code 0') {
        // Manejar el error de CORS aquí, por ejemplo, mostrando un mensaje al usuario
        message = 'Error de CORS. Verifica tu configuración de CORS en el servidor.';
        openSnackbar({
          message,
          severity: 'error',
        });
      }

      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    setIsSet(true);

    return () => instance.interceptors.response.eject(interceptor);
  }, []);

  return isSet && children;
}

export default instance;
export { AxiosInterceptor as AuthAxiosInterceptor };
