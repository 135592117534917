import { Grid } from '@mui/material';
import CambiarContraseñaCard from 'components/seguridad/CambiarContraseñaCardComponent';
import CambiarCorreoCard from 'components/seguridad/CambiarCorreoCardComponent';
import HeaderCard from 'components/seguridad/HeaderCardComponent';

export default function SeguridadView() {
  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <HeaderCard />
      </Grid>
      <Grid xs={12} item>
        <CambiarCorreoCard />
      </Grid>
      <Grid xs={12} item>
        <CambiarContraseñaCard />
      </Grid>
    </Grid>
  );
}
