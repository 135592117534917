/* eslint-disable import/prefer-default-export */
export const TIPOS_DOCUMENTOS = [
  {
    id_tipo_documento: 3,
    tipo_documento: 'DNI',
  },
  {
    id_tipo_documento: 7,
    tipo_documento: 'DNF.',
  },
  {
    id_tipo_documento: 8,
    tipo_documento: 'DNM.',
  },
];
