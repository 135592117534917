/**
 * Asigna el nombre de la empresa en base a su id
 * @param {int} idEmpresa el id de la empresa
 */
export default function obtenerLeyendaEmpresa(idEmpresa) {
  switch (idEmpresa) {
    case 1:
      return 'Cooperativa Obrera';
    case 2:
      return 'Unica';
    case 3:
      return 'Nueva Card';
    case 4:
      return 'Nikant Tour';
    case 5:
      return 'Primer Precio';
    case 6:
      return 'Colmaco';
    case 10:
      return 'Borlenghi';
    case 11:
      return 'Adecco';
    case 12:
      return 'Randstad';
    case 13:
    case 22:
      return 'Asesores/Empresas';
    case 14:
      return 'Pasantes';
    case 15:
      return 'Plan Bonus';
    case 16:
      return 'Hipervision';
    case 17:
      return 'Practicantes';
    case 18:
      return 'Manpower';
    case 20:
      return 'Gui Laboral';
    case 21:
      return 'Diplomat S.R.L.';
    case 23:
      return 'Gestión Laboral S.A';
    case 24:
      return 'FRIDEVI';
    case 25:
      return 'Pilotti';
    case 26:
      return 'Bahia Tuning';
    case 28:
      return 'Higinex';
    default:
      return 'Empresa inexistente';
  }
}
