import { Grid, Typography } from '@mui/material';
import Card from 'components/card/CardComponent';

export default function EvaluacionDesempeñoView() {
  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <Card>
          <Typography>Evaluación de desempeño</Typography>
        </Card>
      </Grid>
    </Grid>
  );
}
