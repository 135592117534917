import { Box } from '@mui/material';

const styles = {
  iconContainer: {
    width: { xs: 40, md: 48 },
    height: { xs: 40, md: 48 },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FBFBFB',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    '&[data-size=small]': {
      width: { xs: 32, md: 32 },
      height: { xs: 32, md: 32 },
    },
  },
};

export default function InfoItemIconContainer({ icon, size = 'medium' }) {
  return (
    <Box data-size={size} sx={styles.iconContainer}>
      {icon}
    </Box>
  );
}
