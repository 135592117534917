import {
  Button,
  Grid,
  Typography,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import useAlerts from 'hooks/useAlerts';
import useEmpleado from 'hooks/useEmpleado';
import useRecibo from 'pages/recibo/hooks/useRecibo';
import SecureFormikTextField from 'components/inputs/SecureInputComponent';
import TextButtonComponent from 'components/buttons/TextButtonComponent';
import desencriptarConKeyDerivada from '../../utils/desencriptarConKeyDerivada';

export default function IngresarARecibosComponent({ handleKeyPrivadaEmpleado }) {
  const { t } = useTranslation();
  const { openSnackbar } = useAlerts();
  const navigate = useNavigate();
  const {
    state: { empleado, usuario },
  } = useEmpleado();
  const {
    state:
    { keyPrivadaEmpleadoEncriptada, intentosIngreso },
    bloquearRecibosDelUsuario,
    sumarIntentoDeIngreso,
  } = useRecibo();

  const ingresarRecibosForm = useFormik({
    initialValues: { pin: '' },
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      const { pin } = data;

      desencriptarConKeyDerivada(
        empleado.legajo,
        usuario.fecha_nacimiento,
        pin,
        keyPrivadaEmpleadoEncriptada,
      )
        .then((res) => handleKeyPrivadaEmpleado(res))
        .catch((error) => {
          if (error.code && error.code === 404) {
            openSnackbar({
              message: error.message,
              severity: 'error',
            });
          } else if (intentosIngreso < 5) {
            openSnackbar({
              message: 'Contraseña incorrecta, intente nuevamente',
              severity: 'error',
            });
            sumarIntentoDeIngreso();
          } else {
            openSnackbar({
              message: 'Demasiados intentos incorrectos, se bloquea temporalmente la función. Intente nuevamente pasados 30 minutos.',
              severity: 'error',
            });
            bloquearRecibosDelUsuario();
            navigate('/recibo/bloqueado');
          }
        });
    },
  });

  const ingresarRecibos = (e) => {
    e.preventDefault();
    return ingresarRecibosForm.handleSubmit();
  };

  return (
    <Card>
      <form onSubmit={ingresarRecibos}>
        <Grid spacing={2} sx={{ alignItems: 'flex-end' }} container>
          <Grid xs={12} item>
            <Typography variant="body1">
              <Trans t={t}>
                {t('pages.recibo.ingresar')}
              </Trans>
            </Typography>
          </Grid>
          <Grid xs={12} md={3} item>
            <SecureFormikTextField
              id="pin"
              label={t('pages.recibo.pin_input')}
              formik={ingresarRecibosForm}
            />
          </Grid>
          <Grid xs={12} md={2} item>
            <Button
              variant="contained"
              disabled={!ingresarRecibosForm.isValid}
              fullWidth
              type="submit"
            >
              {t('pages.recibo.botones.ingresar')}
            </Button>
          </Grid>
          <Grid xs={12} item>
            <TextButtonComponent
              onClick={() => navigate('/recibo/generar-pin')}
              noHover
              fullHeight
              underline
            >
              <Typography variant="body2">{t('pages.recibo.olvide_pin')}</Typography>
            </TextButtonComponent>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}
