import { base64ToArrayBuffer, generarIV } from './bufferHelpers';
import cargarCryptoAPINavegador from './cargarCryptoAPINavegador';
import generarDerivada from './generarDerivada';

export default async function desencriptarConKeyDerivada(
  leg,
  fec,
  password,
  encryptedKey,
) {
  const cryptoLibrary = cargarCryptoAPINavegador();

  const legajo = leg.toString().padStart(6, '0');
  const fecha = fec.split('-');

  const keyDerivada = await generarDerivada(legajo, fecha, password);

  const bufKey = base64ToArrayBuffer(encryptedKey);
  // Desencripta la key privada con la key derivada del password
  const keyPrivadaObtenida = await cryptoLibrary.decrypt(
    { name: 'AES-GCM', iv: generarIV(legajo), tagLength: 128 },
    keyDerivada,
    bufKey,
  );

  return cryptoLibrary.importKey(
    'pkcs8',
    keyPrivadaObtenida,
    { name: 'RSA-OAEP', hash: 'SHA-512' },
    true,
    ['decrypt'],
  );
}
