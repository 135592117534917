import Modal from 'components/modal/Modal2/ModalComponent';
import {
  Button, ButtonBase, Grid, Stack, Typography, 
} from '@mui/material';
import ModalHeader from 'components/modal/Modal2/ModalHeaderComponent';
import ModalContent from 'components/modal/Modal2/ModalContentComponent';
import ModalActions from 'components/modal/Modal2/ModalActionsComponent';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';

const styles = {
  container: {
    display: 'flex',
    padding: 1,
  },
  button: {
    width: '100%',
    p: 1,
    borderRadius: '10px',
    transition: 'background-color 0.1s, color 0.1s',
    '&[data-selected=true]': {
      backgroundColor: 'secondary.main',
      '& p': {
        color: 'common.white',
      },
      border: 'none',
    },
    justifyContent: 'start',
    border: {
      xs: '1px solid grey',
    },
  },
};

const videosAyuda = [
  {
    id: 1,
    title: 'Acceder al portal',
    link: 'https://www.youtube.com/embed/qZTsZskmxJc',
  },
  {
    id: 2,
    title: 'Olvidé mi contraseña',
    link: 'https://www.youtube.com/embed/qwbml9U0Tjk',
  },
  {
    id: 3,
    title: 'No tengo cuenta para ingresar a miCoope',
    link: ' https://www.youtube.com/embed/X9oYE_HUafc',
  },
  {
    id: 4,
    title: 'Acceder de forma más rápida',
    link: 'https://www.youtube.com/embed/C_NDKuaUhF4',
  },
];

export default function VerVideoAyudaModal({ open, handleClose }) {
  const { t } = useTranslation();
  const [videoSeleccionado, setVideoSeleccionado] = useState(videosAyuda[0]);
  const isMobile = useMobileBreakpoint();

  const handleSeleccionarVideo = (video) => {
    setVideoSeleccionado(video);

    if (isMobile) {
      const link = document.createElement('a');
      link.href = video.link;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Modal
      sx={{
        maxWidth: {
          xs: '392px',
          sm: '800px',
          lg: '800px',
        },
      }}
      open={open}
      handleClose={handleClose}
    >
      <ModalHeader>
        <Typography variant="h6" fontWeight={600}>
          {t('pages.ingresar.modales.ver_video_ayuda.titulo')}
        </Typography>
      </ModalHeader>
      <ModalContent>
        <Grid spacing={2} container>
          <Grid xs={12} md={7.02} item>
            <Stack spacing={1}>
              {videosAyuda.map((video) => (
                <ButtonBase
                  key={video.id}
                  sx={styles.button}
                  data-selected={!isMobile && (videoSeleccionado.id === video.id)}
                  onClick={() => handleSeleccionarVideo(video)}
                >
                  <Typography>{video.title}</Typography>
                </ButtonBase>
              ))}
            </Stack>
          </Grid>
          {!isMobile && (
            <Grid xs={12} md={4.98} item>
              <iframe
                width="100%"
                height={isMobile ? '200' : '550'}
                src={videoSeleccionado.link}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowfullscreen
              />
            </Grid>
          )}
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button
          variant="outlined"
          color="tertiary"
          type="button"
          onClick={handleClose}
          size="small"
        >
          {t('botones.cerrar')}
        </Button>
      </ModalActions>
    </Modal>
  );
}
