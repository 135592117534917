import { arrayBufferToBase64, base64ToArrayBuffer } from './bufferHelpers';
import cargarCryptoAPINavegador from './cargarCryptoAPINavegador';

export default async function desencriptarKeyRecibo(keyRecibo, keyPrivada) {
  const cryptoLibrary = cargarCryptoAPINavegador();

  const decodedKeyRecibo = base64ToArrayBuffer(keyRecibo);
  const decryptedSymmetricKey = await cryptoLibrary.decrypt(
    {
      name: 'RSA-OAEP',
    },
    keyPrivada,
    decodedKeyRecibo,
  );

  return arrayBufferToBase64(decryptedSymmetricKey);
}
