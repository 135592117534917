export default function CertificadoLaboralIcon({
  width = 16,
  height = 15,
  stroke = '#4082F6',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 15"
      {...props}
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.216 11.103a2.347 2.347 0 100-4.695 2.347 2.347 0 000 4.695z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.65 10.712v3.52l1.565-1.173 1.565 1.174v-3.521"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M6.304 11.885H2.392A1.565 1.565 0 01.827 10.32V2.496c0-.86.704-1.565 1.565-1.565h10.954a1.565 1.565 0 011.565 1.565v7.824a1.565 1.565 0 01-.782 1.354"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.174 8.756h1.565"
      />
    </svg>
  );
}
