import { Typography } from '@mui/material';
import Card from 'components/card/CardComponent';
import { useTranslation } from 'react-i18next';
import { getPdfSolicitud } from 'services/CertificadoLaboralService';
import DataGridHistorial from './DataGridHistorial';

export default function HistorialSolicitudesCard({ historial }) {
  const { t } = useTranslation();

  const descargarCertificadoLaboral = (id) => {
    getPdfSolicitud(id).then((res) => {
      const { base64, mime, name } = res.data.data;
      const link = document.createElement('a');
      link.href = `data:${mime};base64,${base64}`;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <Card>
      <Typography variant="h6">
        {t('pages.solicitudes.historial_solicitudes')}
      </Typography>
      <DataGridHistorial
        historial={historial}
        descargarCertificadoLaboral={descargarCertificadoLaboral}
      />
    </Card>
  );
}
