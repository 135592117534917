import { useState, useEffect } from 'react';
import { Box, Typography, keyframes } from '@mui/material';
import MiPortalWithoutTextIcon from 'components/icons/MiPortalWithoutText';

const pulse = keyframes`
  0% {
    transform: scale(2);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
`;

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  iconContainer: {
    animation: `${pulse} 1.5s infinite ease`,
  },
};

export default function Cargando({ loadingInstances }) {
  const [showLabels, setShowLabels] = useState(false);

  useEffect(() => {
    let timeout;

    if (loadingInstances.length === 0) {
      setShowLabels(false); // Si el array está vacío, ocultar los labels
    } else {
      timeout = setTimeout(() => {
        setShowLabels(true);
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [loadingInstances]);

  if (!loadingInstances.length) {
    return null;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.iconContainer}>
        <MiPortalWithoutTextIcon />
      </Box>
      {showLabels && (
        <Box textAlign="center" mt={6}>
          {loadingInstances.map((instance) => (
            <Typography key={instance.id} color="common.white">
              {`${instance.label}`}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
}
