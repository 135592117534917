import { useState } from 'react';

export default function useSnackbar(enableClickawayAutoclose = false) {
  const [open, setOpen] = useState({
    state: false,
  });

  const openSnackbar = (props) => {
    setOpen({ ...props, state: true });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway' && !enableClickawayAutoclose) {
      return;
    }

    setOpen((prevState) => ({ ...prevState, state: false }));
  };

  return {
    openSnackbar,
    closeSnackbar,
    snackbarState: open,
  };
}
