export default function SucursalIcon({
  width = 22,
  height = 22,
  stroke = '#4082F6',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        d="M1 21h20M7.665 6.556h1.111M7.665 11h1.111M7.665 15.444h1.111M13.223 6.556h1.11M13.223 11h1.11M13.223 15.444h1.11M3.223 21V3.222A2.222 2.222 0 0 1 5.445 1h11.111a2.222 2.222 0 0 1 2.222 2.222V21"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
