export default function ArrowRightIcon({
  width = 7,
  height = 11,
  stroke = '#2C3E50',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 7 11"
      {...props}
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M1 10l5-4.5L1 1"
      />
    </svg>
  );
}
