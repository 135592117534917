import generateId from 'utils/generateId';
import CreateDataContext from './CreateDataContext';

const alertsReducer = (state, action) => {
  switch (action.type) {
    case 'setSnackbarState':
      return {
        ...state,
        snackbarState: action.payload,
      };
    case 'updateSnackbarState':
      return {
        ...state,
        snackbarState: {
          ...state.snackbarState,
          ...(typeof action.payload === 'object' ? action.payload : {}),
        },
      };
    case 'startLoading':
      return {
        ...state,
        loadingInstances: [...state.loadingInstances, action.payload],
      };
    case 'stopLoading':
      return {
        ...state,
        loadingInstances: state.loadingInstances.filter(
          (instance) => instance.id !== action.payload,
        ),
      };
    case 'startProgreso': {
      const { id, total } = action.payload;
      return {
        ...state,
        progreso: { id, parcial: 0, total },
      };
    }
    case 'updateProgreso': {
      const { sumar } = action.payload;
      const nuevoParcial = state.progreso.parcial + sumar;
      return {
        ...state,
        progreso: { ...state.progreso, parcial: nuevoParcial },
      };
    }
    case 'stopProgreso': {
      const { id } = action.payload;
      if (Number(state.progreso?.id) === Number(id)) {
        return {
          ...state,
          progreso: null,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

const openSnackbar = (dispatch) => (snackbarState) => {
  dispatch({
    type: 'setSnackbarState',
    payload: {
      state: true,
      ...snackbarState,
    },
  });
};

const closeSnackbar = (dispatch) => (snackbarState) => {
  dispatch({
    type: 'updateSnackbarState',
    payload: {
      state: false,
      ...snackbarState,
    },
  });
};

const startLoading = (dispatch) => (label, total) => {
  const id = generateId();
  dispatch({ type: 'startLoading', payload: { label, id } });
  if (total) {
    dispatch({ type: 'startProgreso', payload: { id, total } });
  }
  return id;
};

const stopLoading = (dispatch) => (instanceId) => {
  dispatch({ type: 'stopLoading', payload: instanceId });
  dispatch({ type: 'stopProgreso', payload: { id: instanceId } });
};

const updateProgreso = (dispatch) => (sumar) => {
  dispatch({ type: 'updateProgreso', payload: { sumar } });
};

export const { Context, Provider } = CreateDataContext(
  alertsReducer,
  {
    openSnackbar,
    closeSnackbar,
    startLoading,
    stopLoading,
    updateProgreso,
  },
  {
    snackbarState: {
      state: false,
      severity: 'success',
      message: '',
      icon: null,
      autoHideDuration: 3000,
      button: {
        label: '',
        onClick: () => {},
      },
    },
    loadingInstances: [],
    progreso: null, // { id, parcial, total }
  },
);
