import Card from 'components/card/CardComponent';
import InfoItem from 'components/infoItem/InfoItemComponent';
import IngresoIcon from 'components/icons/Ingreso';
import PuestoIcon from 'components/icons/Puesto';
import SectorIcon from 'components/icons/Sector';
import SucursalIcon from 'components/icons/Sucursal';
import UbicacionIcon from 'components/icons/Ubicacion';
import useEmpleado from 'hooks/useEmpleado';
import { useTranslation } from 'react-i18next';
import dateStringToFormatedDate from 'utils/dateStringToFormatedDate';

const styles = {
  container: {
    display: 'flex',
    gap: {
      xs: '25px',
      md: '50px',
    },
    flexWrap: 'wrap',
  },
};

export default function EmpleadoPuestoCard() {
  const { t } = useTranslation();
  const {
    state: { empleado },
  } = useEmpleado();

  return (
    <Card sx={styles.container}>
      <InfoItem
        icon={<SucursalIcon />}
        title={t('pages.inicio.sucursal')}
        subtitle={empleado.local_desc}
      />
      <InfoItem
        icon={<UbicacionIcon />}
        title={t('pages.inicio.direccion_sucursal')}
        subtitle={`${empleado.calle} ${empleado.numero}`}
      />
      <InfoItem
        icon={<PuestoIcon />}
        title={t('pages.inicio.puesto')}
        subtitle={empleado.puesto_desc}
      />
      <InfoItem
        icon={<SectorIcon />}
        title={t('pages.inicio.sector')}
        subtitle={empleado.sector_desc}
      />
      <InfoItem
        icon={<IngresoIcon />}
        title={t('pages.inicio.ingreso')}
        subtitle={dateStringToFormatedDate(`${empleado.fecha_ingreso}T00:00`)}
      />
    </Card>
  );
}
