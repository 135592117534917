import { Modal as MuiModal, Box, Fade } from '@mui/material';

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: {
      xs: '392px',
      sm: '600px',
      /* md: '752px',
      lg: '888px', */
    },
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.04)',
    border: 'none',
    borderRadius: '10px',
    p: 2,
    outline: 'none',

    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90%',
  },
};

/* 
  Debe usar los componentes hermanos de este
*/
export default function Modal({
  open,
  handleClose,
  keepMounted,
  children,
  sx,
}) {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      keepMounted={keepMounted}
    >
      <Fade in={open}>
        <Box sx={{ ...styles.container, ...sx }}>{children}</Box>
      </Fade>
    </MuiModal>
  );
}
