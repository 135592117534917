import { Grid, Typography, Button } from '@mui/material';
import Card from 'components/card/CardComponent';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cambioCorreoSchema from 'schemas/cambioCorreo.schema';
import useEmpleado from 'hooks/useEmpleado';
import { changeValidatedEmailCU, sendVerifyEmailCU } from 'services/AuthService';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import SecureFormikTextField from 'components/inputs/SecureInputComponent';
import useAlerts from 'hooks/useAlerts';
import useFirebase from 'hooks/useFirebase';
import ValidaTusDatosModal from './ValidaTusDatosModalComponent';

export default function CambiarCorreoCard() {
  const { t } = useTranslation();
  const { state: { usuario }, updateUsuario } = useEmpleado();
  const { openSnackbar } = useAlerts();
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState({
    state: false,
    correo: '',
    tiempoSegundos: 0,
    onComplete: () => {},
  });
  const {
    state: { firebaseInstance },
  } = useFirebase();
  const initialValues = {
    correoActual: usuario.email,
    correoNuevo: '',
    correoNuevoRepetido: '',
    contraseña: '',
  };

  const verificoCodigoValidacion = async (
    codigo,
    correoNuevo,
  ) => {
    const res = await changeValidatedEmailCU(codigo);
    if (res.data.success) {
      openSnackbar({
        message: res.data.message,
        severity: 'success',
      });
      setModalState({
        state: false,
        tiempoSegundos: 0,
        emailCodeError: false,
        onComplete: () => {},
      });
      updateUsuario({
        email: correoNuevo,
      });

      firebaseInstance?.logEvent('actualizo-correo-electronico');
    } else {
      openSnackbar({
        message: res.data.message,
        severity: 'error',
      });
      setModalState((prevState) => ({ ...prevState, emailCodeError: true }));
      setTimeout(() => {
        setModalState((prevState) => ({ ...prevState, emailCodeError: false }));
      }, [1000]);
    }
  };

  const cambiarCorreoForm = useFormik({
    initialValues,
    validationSchema: cambioCorreoSchema,
    validateOnChange: shouldValidateOnChange,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (data, { setErrors, resetForm }) => {
      try {
        setIsLoading(true);
        const { correoActual, correoNuevo, contraseña } = data;

        // Compruebo si el correo introduce es correcto
        if (correoActual !== usuario.email) {
          setErrors({
            correoActual: 'El correo actual no es correcto',
          });
          setIsLoading(false);

          return;
        }

        // Se envia el post con el nuevo email para que le envie el codigo de validacion
        const res = await sendVerifyEmailCU(correoNuevo, contraseña);

        if (res.data.success) {
          setModalState({
            state: true,
            correo: data.correoNuevo,
            tiempoSegundos: Math.floor(
              (new Date(res.data.data.expires_at).getTime()
                - new Date().getTime())
                / 1000,
            ),
            onComplete: (codigo) => verificoCodigoValidacion(codigo, correoNuevo),
          });
        } else {
          openSnackbar({
            message: res.data.message,
            severity: 'error',
          });
        }
        
        resetForm();
        setIsLoading(false);
      } catch (error) {
        resetForm();
        setIsLoading(false);
      }
    },
  });

  const submittion = (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    return cambiarCorreoForm.handleSubmit();
  };

  return (
    <Card>
      <ValidaTusDatosModal
        modalState={modalState}
        setModalState={setModalState}
      />
      <form onSubmit={submittion}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <Typography fontWeight={600}>
              {t('pages.seguridad.cambiar_correo_titulo')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} lg={3.5} item>
                <FormikTextField
                  id="correoActual"
                  label={t('pages.seguridad.correo_actual_input')}
                  formik={cambiarCorreoForm}
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6} lg={3.5} item>
                <FormikTextField
                  id="correoNuevo"
                  label={t('pages.seguridad.correo_nuevo_input')}
                  formik={cambiarCorreoForm}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} lg={3.5} item>
                <FormikTextField
                  id="correoNuevoRepetido"
                  label={t('pages.seguridad.correo_nuevo_repetir_input')}
                  formik={cambiarCorreoForm}
                />
              </Grid>
              <Grid xs={12} md={6} lg={3.5} item>
                <SecureFormikTextField
                  id="contraseña"
                  label={t('pages.seguridad.contraseña_input')}
                  formik={cambiarCorreoForm}
                />
              </Grid>
              <Grid xs={12} md={6} lg={3.5} display="flex" alignItems="end" item>
                <Button
                  variant="contained"
                  disabled={!cambiarCorreoForm.isValid || isLoading}
                  type="submit"
                  fullWidth
                >
                  {t('botones.guardar')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}
