import {
  Button,
  ButtonBase,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import SecureFormikTextField from 'components/inputs/SecureInputComponent';
import pinRecibosSchema from 'schemas/pinRecibos.schema';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import useAlerts from 'hooks/useAlerts';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useEmpleado from 'hooks/useEmpleado';
import Card from 'components/card/CardComponent';
import { IconChevronLeft } from '@cooperativaobrera/react-icons/tabler-icons';
import { solicitarAltaRecibos } from 'services/RecibosService';
import useRecibo from '../hooks/useRecibo';
import generarParDeClaves from '../utils/generarParDeClavesRSA';

const initialValues = {
  pin: '',
  repetirPin: '',
};

export default function GenerarPinPage() {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();
  const { openSnackbar, startLoading, stopLoading } = useAlerts();
  const navigate = useNavigate();
  const { state: { empleado, usuario } } = useEmpleado();
  const { state: { keyPrivadaEmpleadoEncriptada }, updateEstadoRecibo } = useRecibo();

  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

  const cambio = !!keyPrivadaEmpleadoEncriptada;

  const pinRecibosForm = useFormik({
    initialValues,
    validationSchema: pinRecibosSchema,
    validateOnBlur: false,
    validateOnChange: shouldValidateOnChange,
    enableReinitialize: true,
    onSubmit: async (data) => {
      const loadingId = startLoading('Generando claves de seguridad, este proceso puede demorar unos segundos. Por favor espere.');
      const { pin } = data;

      const { publicKey, encryptedKey } = await generarParDeClaves(
        empleado.legajo,
        usuario.fecha_nacimiento,
        pin,
      ).catch(() => {
        openSnackbar({
          message: 'Ocurrió un error con la generación del PIN, intente nuevamente y si el error persiste pruebe desde otro dispositivo o contáctese con Recursos Humanos.',
          severity: 'error',
        });
        stopLoading(loadingId);
      });

      solicitarAltaRecibos(publicKey, encryptedKey)
        .then((res) => {
          openSnackbar({
            message: res.data.message,
            severity: 'success',
          });
          updateEstadoRecibo(cambio ? 2 : 1);
          navigate('/recibo/pendiente');
        })
        .catch((error) => {
          openSnackbar({
            message: error.data.message,
            severity: 'error',
          });
        })
        .finally(() => stopLoading(loadingId));
    },
  });

  const generarPIN = (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    return pinRecibosForm.handleSubmit();
  };

  useEffect(() => {
    if (pinRecibosForm.errors.pin) {
      openSnackbar({
        message: pinRecibosForm.errors.pin,
        severity: 'error',
      });
    }
    if (pinRecibosForm.errors.repetirPin) {
      openSnackbar({
        message: pinRecibosForm.errors.repetirPin,
        severity: 'error',
      });
    }
  }, [pinRecibosForm.errors]);

  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <Typography fontWeight={600}>
          {t('pages.recibo.titulo')}
        </Typography>
      </Grid>
      {cambio && (
        <Grid xs={12} item>
          <ButtonBase onClick={() => navigate('/recibo')} disableRipple>
            <IconChevronLeft />
            <Typography>{t('pages.recibo.botones.volver')}</Typography>
          </ButtonBase>
        </Grid>
      )}
      <Grid xs={12} item>
        <Card>
          <form onSubmit={generarPIN}>
            <Grid spacing={{ xs: 2, md: 4 }} sx={{ alignItems: 'flex-end' }} container>
              {cambio
                ? (
                  <Grid xs={12} item>
                    <Typography variant="body1" fontWeight={600} mb={2}>
                      {t('pages.recibo.olvide_pin')}
                    </Typography>
                    <Typography variant="body2">
                      <Trans t={t}>
                        {t('pages.recibo.reestablecer_pin')}
                      </Trans>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid xs={12} item>
                    <Typography variant="body1">
                      <Trans t={t}>
                        {t('pages.recibo.crear_pin')}
                      </Trans>
                    </Typography>
                  </Grid>
                )}
              <Grid xs={12} md={3} item>
                <SecureFormikTextField
                  id="pin"
                  label={t('pages.recibo.pin_crear_input')}
                  placeholder={t('pages.recibo.pin_crear_input_placeholder')}
                  type="password"
                  formik={pinRecibosForm}
                  onlyNumbers
                  hideErrorMessage={!isMobile}
                />
              </Grid>
              <Grid xs={12} md={3} item>
                <SecureFormikTextField
                  id="repetirPin"
                  label={t('pages.recibo.pin_repetir_input')}
                  type="password"
                  formik={pinRecibosForm}
                  onlyNumbers
                  hideErrorMessage={!isMobile}
                />
              </Grid>
              <Grid xs={12} md={2} item>
                <Button
                  variant="contained"
                  disabled={!pinRecibosForm.isValid}
                  fullWidth
                  type="submit"
                >
                  {t('pages.recibo.botones.guardar_pin')}
                </Button>
              </Grid>
              <Grid xs={12} item display="flex" flexDirection="column">
                <Typography variant="body2" fontWeight={600}>
                  {t('pages.recibo.sugerencias')}
                </Typography>
                <List sx={{ listStyleType: 'disc', ml: 3 }} dense>
                  <ListItem sx={{ display: 'list-item', pl: 0 }}>
                    <Typography variant="body2" lineHeight={1}>
                      {t('pages.recibo.primera_sugerencia')}
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', pl: 0 }}>
                    <Typography variant="body2" lineHeight={1}>
                      {t('pages.recibo.segunda_sugerencia')}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
}
