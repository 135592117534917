import { Box } from '@mui/material';

export default function ModalContent({
  children,
  withoutHeader,
  withoutActions,
  sx,
  ...restOfProps
}) {
  return (
    <Box
      sx={{ overflow: 'auto', ...sx }}
      mt={withoutHeader ? 0 : 1}
      mb={withoutActions ? 0 : 2}
      {...restOfProps}
    >
      {children}
    </Box>
  );
}
