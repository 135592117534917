import {
  Grid, IconButton, Typography, Button,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cambioContraseñaSchema from 'schemas/cambioContraseña.schema';
import { changePasswordCU } from 'services/AuthService';
import AyudaIcon from 'components/icons/Ayuda';
import LightTooltip from 'components/lightTooltip/LightTooltipComponent';
import SecureFormikTextField from 'components/inputs/SecureInputComponent';
import useAlerts from 'hooks/useAlerts';
import useFirebase from 'hooks/useFirebase';

export default function CambiarContraseñaCard() {
  const { t } = useTranslation();
  const { openSnackbar } = useAlerts();
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    state: { firebaseInstance },
  } = useFirebase();
  const initialValues = {
    contraseñaActual: '',
    contraseñaNueva: '',
    contraseñaNuevaRepetida: '',
  };

  const cambiarContraseñaForm = useFormik({
    initialValues,
    validationSchema: cambioContraseñaSchema,
    validateOnChange: shouldValidateOnChange,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (data, options) => {
      try {
        setIsLoading(true);
        const { contraseñaActual, contraseñaNueva } = data;
        const res = await changePasswordCU(contraseñaActual, contraseñaNueva);

        firebaseInstance?.logEvent('actualizo-contraseña');

        openSnackbar({
          state: true,
          message: res.data.message,
          severity: res.data.success ? 'success' : 'error',
        });

        setIsLoading(false);
        options.resetForm();
      } catch (error) {
        options.resetForm();
        setIsLoading(false);
      }
    },
  });

  const submittion = (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    return cambiarContraseñaForm.handleSubmit();
  };

  return (
    <Card>
      <form onSubmit={submittion}>
        <Grid container spacing={2}>
          <Grid xs={12} display="flex" alignItems="center" gap="8px" item>
            <Typography fontWeight={600}>
              {t('pages.seguridad.cambiar_contraseña_titulo')}
            </Typography>
            <LightTooltip title={t('pages.seguridad.condiciones_contraseña')}>
              <IconButton>
                <AyudaIcon />
              </IconButton>
            </LightTooltip>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} lg={3.5} item>
                <SecureFormikTextField
                  id="contraseñaActual"
                  label={t('pages.seguridad.contraseña_actual_input')}
                  formik={cambiarContraseñaForm}
                />
              </Grid>
              <Grid xs={12} md={6} lg={3.5} item>
                <SecureFormikTextField
                  id="contraseñaNueva"
                  label={t('pages.seguridad.contraseña_nueva_input')}
                  formik={cambiarContraseñaForm}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} lg={3.5} item>
                <SecureFormikTextField
                  id="contraseñaNuevaRepetida"
                  label={t('pages.seguridad.contraseña_nueva_repetir_input')}
                  formik={cambiarContraseñaForm}
                />
              </Grid>
              <Grid xs={12} md={6} lg={3.5} display="flex" alignItems="end" item>
                <Button
                  variant="contained"
                  disabled={!cambiarContraseñaForm.isValid || isLoading}
                  type="submit"
                  fullWidth
                >
                  {t('botones.guardar')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}
