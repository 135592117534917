import { Grid, Button } from '@mui/material';

// Components
import FormikTextField from 'components/inputs/FormikTextFieldComponent';

import { useTranslation } from 'react-i18next';
import onlyNumbersValidate from 'utils/onlyNumbersValidate';

export default function RestableceContraseñaForm({
  formik,
  setShouldValidateOnChange,
  isLoading,
}) {
  const { t } = useTranslation();

  const submittion = (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    return formik.handleSubmit();
  };

  return (
    <form onSubmit={submittion}>
      <Grid spacing={{ xs: 3, md: 4 }} container>
        <Grid xs={12} item>
          <FormikTextField
            id="documento"
            label={t('pages.restablecer_contraseña.usuario_input')}
            formik={formik}
            validate={onlyNumbersValidate}
          />
        </Grid>
        <Grid xs={12} mt={{ xs: 0, md: 5 }} item>
          <Button
            variant="contained"
            disabled={!formik.isValid || isLoading}
            fullWidth
            type="submit"
          >
            {isLoading ? t('botones.cargando') : t('botones.enviar')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
