import {
  TableCell, TableHead, 
} from '@mui/material';

export default function EnhancedTableHead({ headCells, StyledTableHeadRow }) {
  return (
    <TableHead>
      <StyledTableHeadRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sx={{ pl: headCell.disablePadding ? 0 : 'inherit' }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </StyledTableHeadRow>
    </TableHead>
  );
}
