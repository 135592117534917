export default function formatSolicitudesData({
  res, cp_salon, cp_turno, cp_motivo, cp_estado, 
}) {
  const state = {
    solicitudes: res,
    salones: [],
    turnos: [],
    motivos: [],
    estados: [],
  };

  Object.keys(cp_salon).forEach((key) => {
    state.salones.push({
      label: cp_salon[key],
      value: key,
    });
  });

  Object.keys(cp_turno).forEach((key) => {
    state.turnos.push({
      label: cp_turno[key],
      value: key,
    });
  });

  Object.keys(cp_motivo).forEach((key) => {
    state.motivos.push({
      label: cp_motivo[key],
      value: key,
    });
  });

  Object.keys(cp_estado).forEach((key) => {
    state.estados.push({
      label: cp_estado[key],
      value: key,
    });
  });

  state.solicitudes = state.solicitudes.map((solicitud) => ({
    ...solicitud,
    salon: state.salones.find((salon) => salon.value === solicitud.salon).label,
    turno: state.turnos.find((turno) => turno.value === solicitud.turno).label,
    motivo: state.motivos.find((motivo) => motivo.value === solicitud.motivo).label,
  }));

  return state;
}
