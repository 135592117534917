import * as Yup from 'yup';

const pinRegex = /^(?!1234|2345|3456|4567|5678|6789|123|456|789|987654|654321|8765|000|111|222|333|444|555|666|777|888|999)\d+$/;
const requiredRegex = /^\d{6,}$/;

const pinRecibosSchema = Yup.object({
  pin: Yup.string()
    .required('Ingresá tu nueva contraseña')
    .matches(
      requiredRegex,
      'El PIN debe tener al menos 6 dígitos',
    )
    .matches(
      pinRegex,
      'El PIN es muy simple',
    ),
  repetirPin: Yup.string()
    .oneOf([Yup.ref('pin')], 'Los PINES no coinciden. Por favor revisalos y volvé a intentarlo')
    .required('Ingresá nuevamente tu PIN'),
});

export default pinRecibosSchema;
