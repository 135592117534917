import Modal from 'components/modal/Modal2/ModalComponent';
import { Button, Typography } from '@mui/material';
import ModalHeader from 'components/modal/Modal2/ModalHeaderComponent';
import ModalActions from 'components/modal/Modal2/ModalActionsComponent';
import ModalContent from 'components/modal/Modal2/ModalContentComponent';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useModal from 'hooks/useModal';

const medium = 500;
const semibold = 600;

export default function TyCModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modalState, closeModal } = useModal(true);

  const handleVolver = () => {
    navigate('/tarjeta-empleado');
  };

  return (
    <Modal
      sx={{
        maxWidth: {
          xs: '392px',
          sm: '600px',
          md: '752px',
        },
      }}
      open={modalState}
    >
      <ModalHeader>
        <Typography variant="h5" fontWeight={600}>
          Aceptar términos y condiciones
        </Typography>
      </ModalHeader>
      <ModalContent>
        <Typography>
          <Typography component="span" fontWeight={medium}>
            Asociacion empleados Cooperativa Obrera
          </Typography>
          <br />
          <Typography component="span" fontWeight={medium}>
            Reglamento de uso de instalaciones | Predio Aldea Romana y Casa del Personal
          </Typography>
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            1. Alta y Baja de asociado
          </Typography>
          <br />
          Todo empleado que desee asociarse podrá utilizar los salones sin
          mediar periodo de carencia. Se dará curso a una solicitud de baja de
          asociado, siempre y cuando entre la última utilización de los salones
          y dicha solicitud, medie un período de 6 meses.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            2. Usos
          </Typography>
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            2.1
          </Typography>
          {' '}
          Las instalaciones podrán ser utilizadas, para destino exclusivamente
          familiar, por todo empleado o grupos de empleados y sus familiares
          convivientes, adheridos al sistema, respetando el cupo máximo de
          personas que admite cada local (80 personas en Aldea Romana y 40 en
          Casa del Personal).
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            2.2
          </Typography>
          {' '}
          No se permite el uso de las mismas para reuniones de tipo político,
          comercial, religioso y/o sociales que exceda el marco de lo
          estrictamente familiar.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            2.3
          </Typography>
          {' '}
          En la Casa del Personal no podrán realizarse fiestas de casamientos o
          de cumpleaños de 15. La falta de cumplimiento de esta norma
          inhabilitará al asociado por un lapso de 6 meses y deberá abonar una
          multa del valor estipulado por la Comisión.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            2.4
          </Typography>
          {' '}
          El asociado, no podrá ceder a terceros el uso de dichas instalaciones.
          Si así lo hiciera se hará cargo de la multa estipulada por la Comisión
          y estará inhabilitado por 6 meses para poder gozar de las mismas.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            2.5
          </Typography>
          {' '}
          El asociado que reservó el salón será responsable por las pertenencias
          que deje en el salón y de las roturas de vajilla y otros bienes
          disponibles para el uso en el salón.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            2.6
          </Typography>
          {' '}
          El uso del patio (solo para el salón Chiclana) no está permitido en el
          turno de noche.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            2.7
          </Typography>
          {' '}
          El objeto del presente es únicamente el uso del salón y su patio. En
          el caso del salón Aldea Romana se encuentra prohibido para los
          asistentes al evento salir del predio asignado a Cooperativa Obrera
          Ltda y que se encuentra delimitado, como así también el uso de
          piletas, canchas, parrillas, otros salones, espacios comunes con
          arbolado u otros sectores ubicados en el predio de la Asociación de
          Empleados de Comercio.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            3. Reserva
          </Typography>
          <br />
          Se realizará por Intranet a través de la tarjeta de empleados o por
          medio de miPortal desde el menú de tarjeta de empleados, según
          instructivo. El asociado podrá solicitar con un año de anticipación la
          Casa del Personal o Quincho de Aldea Romana indicando día, horario
          (mañana o tarde) y el motivo del pedido.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            4. Otorgamiento de salones
          </Typography>
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            4.1
          </Typography>
          {' '}
          Se otorgará de forma inmediata al asociado que utilice el salón para
          casamiento (propio o de hijos) o cumpleaños de quince de una hija o
          Bodas de Oro y Plata hasta un año antes de la fecha de la realización
          del evento.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            4.2
          </Typography>
          {' '}
          Diariamente, el sistema otorga el salón para dentro de 30 días y el
          asociado favorecido será comunicado en forma automática a la casilla
          de correo de su lugar de trabajo.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            4.3
          </Typography>
          {' '}
          Cuando exista más de un pedido de empleados para la misma fecha y
          horario, se priorizará el pedido del asociado que hace más tiempo que
          no utiliza el salón.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            4.4
          </Typography>
          {' '}
          En caso que los asociados tengan la misma antigüedad de uso de los
          salones, el sistema realizará el sorteo.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            4.5
          </Typography>
          {' '}
          Aquellos asociados que soliciten el salón luego de la fecha de
          otorgamiento y el mismo estuviese disponible, la reserva será
          confirmada en el momento.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            5. Anulaciones
          </Typography>
          <br />
          <Typography component="span" fontWeight={semibold} ml={1}>
            5.1
          </Typography>
          {' '}
          Cuando un asociado anula su reserva con menos de una semana de
          anticipación quedará registrado como uso de salón.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            6. Frecuencia
          </Typography>
          <br />
          Los asociados podrán solicitar el local las veces que lo deseen,
          respetando los términos establecidos en los puntos anteriores.La
          primera utilización del salón en el año tiene un costo de $5.650. Cada
          asociado deberá abonar ante el segundo otorgamiento del año para el
          uso de los salones y en las veces sucesivas $11.300, siendo descontado
          dicho importe del recibo de haberes.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            7. Días y Horarios
          </Typography>
          <br />
          Los días habilitados para solicitar los salones son todos los días, excepto el martes.
          No se podrán utilizar los salones los días 01-ene; 01-may; 24-25 y 31-dic.
          <br />
          La ocupación se otorgará por turnos, comprendiendo los mismos, estos
          horarios:
          <br />
          A) Mediodía 10:00 a 16:30
          <br />
          B) Noche 18:30 a 03:00. Los horarios indicados deben ser de estricto
          cumplimiento y los fines de no perjudicar al asociado que solicitó el
          turno siguiente. A la finalización del turno, el asociado deberá
          retirar toda las pertenencias, alimentos, artículos alquilados (plaza
          blanda, inflables, vajilla, etc). La falta de cumplimiento de estas
          pautas inhabilitará al asociado por un lapso de 6 meses y deberá
          abonar una multa del valor estipulado por la Comisión. El personal de
          limpieza, no se hará responsable, ni será depositario de las
          pertenencias de los asociados. El asociado será responsable por los
          servicios adicionales que contrate.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            8. Equipos musicales
          </Typography>
          <br />
          En el local de la Casa del Personal no está permitida su utilización.
          En Aldea Romana se podrán utilizar, quedando a cargo del asociado, el
          canon que pudiera corresponder a Sadaic y Aadicapif, como asimismo, a
          la finalización del turno el retiro de los equipos.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            9. Entrega y devolución de las llaves
          </Typography>
          <br />
          <Typography component="span" fontWeight={semibold}>
            Salón de Chiclana:
          </Typography>
          {' '}
          De lunes a viernes, de 8:00 a 17:00, en la Gerencia de Recursos
          Humanos, Paraguay 364.
          <br />
          <Typography component="span" fontWeight={semibold}>
            Salón Aldea Romana:
          </Typography>
          {' '}
          Retirar llaves en ingreso a predio de empleados de comercio o
          comunicarse con el Sr. Luciano Zimerman (0291-155128786).
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            10. Cierre de las instalaciones
          </Typography>
          <br />
          Será responsabilidad del asociado, tomar las medidas necesarias para
          el apagado de las luces, calefactores, cocina, etc., como asimismo
          asegurarse que el local quede cerrado bajo llave. Debiendo retirar sus
          pertenencias, a la finalización del turno asignado. No se podrá
          solicitar la presencia del personal de limpieza y/o encargado, para
          que reciba a los asociados fuera del turno asignado.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            11. Daños
          </Typography>
          <br />
          Las multas, roturas o desaparición de objetos o elementos provistos
          y/o existentes al momento de la entrega del local, serán a cargo
          exclusivo del asociado, al que se le descontará dicho importe de sus
          haberes.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            12. Limpieza
          </Typography>
          <br />
          Los salones serán entregados en similares condiciones de limpieza en
          que son recibidos. La falta de cumplimiento de estas pautas
          inhabilitará al asociado por un lapso de 6 meses y deberá abonar una
          multa del valor estipulado por la Comisión.
          <br />
          <br />
          <Typography component="span" fontWeight={semibold}>
            13. Suministro eléctrico
          </Typography>
          <br />
          En caso de corte, la contratación e instalación de un generador
          eléctrico corre por cuenta del asociado.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={closeModal}
          size="small"
        >
          {t('botones.aceptar')}
        </Button>
        <Button
          variant="outlined"
          color="tertiary"
          type="button"
          onClick={handleVolver}
          size="small"
        >
          {t('botones.volver')}
        </Button>
      </ModalActions>
    </Modal>
  );
}
