import {
  Grid, Typography, Button,
} from '@mui/material';
import DisplayCodigo from 'components/displayCodigo/DisplayCodigoComponent';
import Modal from 'components/modal/ModalComponent';
import useSeguridad from 'hooks/useSeguridad';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function GenerarTokenModal({ modalState, setModalState }) {
  const { t } = useTranslation();
  const {
    state: { token, expiracionToken },
    generarToken,
    limpiarToken,
  } = useSeguridad();
  const [finalizoElTiempo, setFinalizoElTiempo] = useState(false);
  const [tiempoRestante, setTiempoRestante] = useState(false);

  const handleGenerarToken = () => {
    generarToken().then((res) => {
      const tokenTS = new Date(res.timestamp);
      const ahora = new Date();
      const segundos = (tokenTS.getTime() - ahora.getTime()) / 1000;
      setFinalizoElTiempo(false);
      setTiempoRestante(segundos);
    });
  };

  useEffect(() => {
    if (token && expiracionToken) {
      const tokenTS = new Date(expiracionToken);
      const ahora = new Date();
      const segundos = (tokenTS.getTime() - ahora.getTime()) / 1000;
      setFinalizoElTiempo(false);
      setTiempoRestante(segundos);
    }
  }, [modalState]);

  useEffect(() => {
    const expirado = expiracionToken ? new Date(expiracionToken) < new Date() : true;
    if (modalState.state && (!token || expirado)) {
      handleGenerarToken();
    }
  }, [modalState]);

  const handleClose = () => {
    setModalState({
      state: false,
    });
  };

  const handleClickToken = () => {
    handleGenerarToken();
  };

  const handleTokenExpirado = () => {
    setFinalizoElTiempo(true);
    limpiarToken(true);
    setTiempoRestante(0);
  };

  return (
    <Modal open={modalState.state} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography fontWeight={600}>
            {t('pages.seguridad.modales.token.titulo')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">
            {t('pages.seguridad.modales.token.mensaje')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          {!token && (
            <DisplayCodigo
              value={t('pages.seguridad.modales.token.vencido')}
              handleFinalizoElTiempo={() => {}}
              tiempo={0}
              finalizado
            />
          )}
          {token && (
            <DisplayCodigo
              value={token}
              handleFinalizoElTiempo={handleTokenExpirado}
              tiempo={tiempoRestante}
            />
          )}
        </Grid>
        <Grid xs={12} item>
          <Button variant="contained" onClick={handleClickToken} disabled={!finalizoElTiempo} fullWidth>
            {t('pages.seguridad.modales.token.generar_nuevo_token')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
