import {
  alpha, Box, Button, Grid, Typography,
} from '@mui/material';
import Card from 'components/card/CardComponent';
import SeguridadIcon from 'components/icons/Seguridad';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenerarTokenModal from './GenerarTokenModalComponent';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  iconContainer: {
    width: 56,
    height: 56,
    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.1),
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default function HeaderCard() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState({
    state: false,
  });

  const handleClickToken = () => {
    setModalState({
      state: true,
    });
  };

  return (
    <Card sx={styles.container}>
      <GenerarTokenModal
        modalState={modalState}
        setModalState={setModalState}
      />
      <Grid spacing={2} justifyContent="space-between" alignItems="center" container>
        <Grid xs={12} md={9} lg={10} item sx={styles.container}>
          <Box sx={styles.iconContainer}>
            <SeguridadIcon stroke="#4082F6" />
          </Box>
          <Typography fontWeight={600}>
            {t('pages.seguridad.titulo')}
          </Typography>
        </Grid>
        <Grid xs={12} md={2} lg={2} item>
          <Button variant="outlined" onClick={handleClickToken} fullWidth>
            {t('pages.seguridad.generar_token')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
