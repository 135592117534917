import { Grid } from '@mui/material';
import Solicitud from './Solicitud';

export default function HistorialSolicitudesMobile({ solicitudes, estados }) {
  return (
    <Grid spacing={2} container>
      {solicitudes.map((solicitud) => (
        <Solicitud key={solicitud.id} solicitud={solicitud} estados={estados} />
      ))}
    </Grid>
  );
}
