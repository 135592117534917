/* eslint-disable react/jsx-no-useless-fragment */
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useRecibo from './hooks/useRecibo';
import RecibosComponent from './components/RecibosComponents';
import {
  RECIBOS_ESTADO_BLOQUEADO_TEMPORALMENTE,
  RECIBOS_ESTADO_NO_SOLICITADO,
  RECIBOS_ESTADO_PENDIENTE_ALTA,
  RECIBOS_ESTADO_PENDIENTE_CAMBIO,
} from './constants';
import IngresarARecibosComponent from './components/IngresarARecibos/IngresarARecibosComponent';

export default function ReciboSueldoPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { obtenerEstadoRecibo } = useRecibo();
  const [isLoading, setIsLoading] = useState(true);
  const [keyPrivadaEmpleado, setKeyPrivadaEmpleado] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    obtenerEstadoRecibo()
      .then((estado) => {
        switch (Number(estado)) {
          case RECIBOS_ESTADO_NO_SOLICITADO: {
            navigate('/recibo/generar-pin');
            break;
          }
          case RECIBOS_ESTADO_PENDIENTE_ALTA:
          case RECIBOS_ESTADO_PENDIENTE_CAMBIO: {
            navigate('/recibo/pendiente');
            break;
          }
          case RECIBOS_ESTADO_BLOQUEADO_TEMPORALMENTE: {
            navigate('/recibo/bloqueado');
            break;
          }
          default: {
            break;
          }
        }
      }).finally(() => setIsLoading(false));
  }, []);

  const handleKeyPrivadaEmpleado = (keyDesencriptada) => {
    setKeyPrivadaEmpleado(keyDesencriptada);
  };

  return (
    <>
      {!isLoading && (
        <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
          <Grid xs={12} item>
            <Typography fontWeight="bold">
              {t('pages.recibo.titulo')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            {!keyPrivadaEmpleado
              ? (
                <IngresarARecibosComponent handleKeyPrivadaEmpleado={handleKeyPrivadaEmpleado} />
              ) : (
                <RecibosComponent keyPrivadaEmpleado={keyPrivadaEmpleado} />
              )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
