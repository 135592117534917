// Tipos de validaciones
export const VALIDACION_USUARIO = 0;
export const VALIDACION_CELULAR = 1;
export const VALIDACION_USUARIO_CELULAR = 2;

// SMS
export const SMS_CODE_DIGITOS = 6;
export const SMS_CODE_TIEMPO_SEGUNDOS = 20;

// EMAIL
export const EMAIL_CODE_DIGITOS = 6;

// Tipos de estado del empleado
export const CUENTA_INACTIVA = '1';
export const CUENTA_ACTIVA = '0';
