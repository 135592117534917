export default function TarjetaEmpleadoIcon({
  width = 24,
  height = 24,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      stroke={stroke}
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M3 8a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3zM3 10h18M7 15h.01M11 15h2" />
    </svg>
  );
}
