import {
  Box, Grid, Typography, Radio, alpha,
} from '@mui/material';
import { useState } from 'react';
import Modal from 'components/modal/ModalComponent';
import { useTranslation } from 'react-i18next';
import TextButton from 'components/buttons/TextButtonComponent';
import { TIPOS_DOCUMENTOS } from 'constants/tiposDocumentos';

const styles = {
  title: {
    fontWeight: 700,
  },
  usuarioContainer: {
    height: '64px',
    border: `1.2px solid ${alpha('#000', 0.32)}`,
    transition: 'border-color 0.3s',
    '&[data-selected=true]': {
      borderColor: (theme) => theme.palette.primary.main,
    },
    borderRadius: '7px',
    display: 'flex',
    px: 1.5,
    cursor: 'pointer',
    gap: 3,
    radioContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dataContainer: {
      display: 'flex',
      aligItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  radio: {
    '&.Mui-checked': {
      color: (theme) => theme.palette.primary.main,
    },
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent',
    },
  },
  strongLabel: {
    fontWeight: 600,
    transition: 'color 300ms',
    textTransform: 'capitalize',
    '&[data-selected=true]': {
      color: (theme) => theme.palette.primary.main,
    },
  },
};

export default function CuentaDuplicadaModal({ modalState, setModalState }) {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null);

  function handleSelectUser(user) {
    setSelectedUser(user);
  }

  const handleClose = () => {
    setModalState({
      state: false,
      usuariosEncontrados: [],
      onClickSoyYo: () => {},
    });
  };

  return (
    <Modal open={modalState.state} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography sx={styles.title}>
            {t('pages.ingresar.modales.cuenta_duplicada.titulo')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">
            {t('pages.ingresar.modales.cuenta_duplicada.mensaje')}
          </Typography>
        </Grid>
        {modalState.usuariosEncontrados.map((usuario) => (
          <Grid key={usuario.id} xs={12} item>
            <Box
              onClick={() => handleSelectUser(usuario)}
              sx={styles.usuarioContainer}
              data-selected={selectedUser?.id === usuario.id}
            >
              <Box sx={styles.usuarioContainer.radioContainer}>
                <Radio
                  checked={selectedUser?.id === usuario.id}
                  value={usuario.id}
                  name="radio-buttons"
                  sx={styles.radio}
                />
              </Box>
              <Box sx={styles.usuarioContainer.dataContainer}>
                <Typography
                  data-selected={selectedUser?.id === usuario.id}
                  sx={styles.strongLabel}
                  variant="body2"
                >
                  {`${usuario.nombre} ${usuario.apellido}`.toLowerCase()}
                </Typography>
                <Typography variant="body2">
                  {`${
                    TIPOS_DOCUMENTOS.find(
                      (tipoDocumento) => tipoDocumento.id_tipo_documento
                        === usuario.id_tipo_documento,
                    )?.tipo_documento
                  } ${usuario.nro_documento}`}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid xs={12} item>
          <Box
            display="flex"
            gap={2}
            justifyContent={{ xs: 'space-evenly', md: 'flex-end' }}
          >
            <TextButton
              onClick={() => {
                modalState.onClickSoyYo(selectedUser);
              }}
            >
              {t('botones.soy_yo')}
            </TextButton>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
}
