import { Box, Typography } from '@mui/material';
import InfoItemIconContainer from '../infoItemIconContainer/InfoItemIconContainer';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: {
      xs: '100%',
      md: 'auto',
    },
  },
};

export default function InfoItem({ title, subtitle, icon }) {
  return (
    <Box sx={styles.container}>
      <InfoItemIconContainer icon={icon} />
      <Box>
        <Typography variant="body2" color="secondary">{title}</Typography>
        <Typography>{subtitle}</Typography>
      </Box>
    </Box>
  );
}
