export default function AdeccoIcon({ width = 32, height = 32, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <rect width="32" height="32" fill="#D6291C" />
      <path
        d="M14.4056 7.35931C14.5462 6.9988 14.8195 6.70092 15.1654 6.53119C15.5112 6.36146 15.9012 6.33379 16.2497 6.45427C16.5981 6.57475 16.8764 6.83351 17.0233 7.17362C17.1702 7.51373 17.1737 7.90734 17.0331 8.26785L10.84 24.1415C10.6994 24.502 10.4261 24.7999 10.0803 24.9696C9.73443 25.1393 9.3444 25.167 8.99597 25.0465C8.64754 24.926 8.36926 24.6673 8.22234 24.3272C8.07542 23.9871 8.0719 23.5935 8.21255 23.2329L14.4056 7.35931Z"
        fill="white"
      />
      <path
        d="M7.26698 20.5C6.93096 20.5 6.6087 20.3788 6.37109 20.1632C6.13349 19.9475 6 19.655 6 19.35C6 19.045 6.13349 18.7525 6.37109 18.5368C6.6087 18.3212 6.93096 18.2 7.26698 18.2H16.233C16.569 18.2 16.8913 18.3212 17.1289 18.5368C17.3665 18.7525 17.5 19.045 17.5 19.35C17.5 19.655 17.3665 19.9475 17.1289 20.1632C16.8913 20.3788 16.569 20.5 16.233 20.5H7.2672H7.26698Z"
        fill="white"
      />
      <path
        d="M14.6256 8.27268C14.4811 7.90396 14.481 7.50231 14.6251 7.15608C14.7692 6.80986 15.0458 6.54742 15.3941 6.42651C15.7423 6.3056 16.1337 6.33611 16.4821 6.51133C16.8305 6.68656 17.1073 6.99214 17.2518 7.36086L23.4554 23.1966C23.5998 23.5653 23.6 23.967 23.4559 24.3132C23.3118 24.6594 23.0352 24.9219 22.6869 25.0428C22.3386 25.1637 21.9473 25.1332 21.5989 24.958C21.2505 24.7827 20.9736 24.4771 20.8292 24.1084L14.6256 8.27268Z"
        fill="white"
      />
    </svg>
  );
}
