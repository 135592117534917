import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import 'animate.css';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { MUIX_LICENCE_KEY } from 'constants/index';
import App from './App';

import '@fontsource/inter/400.css'; // Regular
import '@fontsource/inter/500.css'; // Medium
import '@fontsource/inter/600.css'; // Semibold

LicenseInfo.setLicenseKey(MUIX_LICENCE_KEY || '');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
