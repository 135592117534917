import * as Yup from 'yup';

const solicitarCertificadoLaboralSchema = Yup.object({
  presentar_ante: Yup.string()
    .min(3, ({ min }) => `Debe tener como minimo ${min} caracteres`)
    .max(30, ({ max }) => `Debe tener como maximo ${max} caracteres`)
    .required('Ingresa el solicitante'),
  firma_certificada: Yup.bool(),
});

export default solicitarCertificadoLaboralSchema;
