import { useEffect, useState } from 'react';
import useDeviceHeight from 'hooks/useDeviceHeight';
import DataGridCard, {
  setRowsDatagrid,
  updateColumnsDatagrid,
} from 'components/dataGrid/DataGridCard';
import { GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';
import { DEFAULT_CELL } from 'constants';
import { IconTrash } from '@cooperativaobrera/react-icons/tabler-icons';
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import getStatusColor from '../utils/getStatusColor';

function getColumns(estados, handleClickCancelar, theme) {
  return [
    {
      field: 'fecha',
      headerName: 'Fecha',
      cellClassName: DEFAULT_CELL,
      width: 150,
    },
    {
      field: 'salon',
      headerName: 'Salón',
      cellClassName: DEFAULT_CELL,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'turno',
      headerName: 'Turno',
      cellClassName: DEFAULT_CELL,
      width: 200,
    },
    {
      field: 'motivo',
      headerName: 'Motivo',
      cellClassName: DEFAULT_CELL,
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Estado',
      renderCell: (params) => {
        const estado = estados.find((e) => e.value === params.value);

        const { backgroundColor, color } = getStatusColor(estado.value, theme);

        return (
          <Box
            sx={{
              width: 'calc(100% + 8px)',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              ml: -1,
              pl: 1,
              backgroundColor,
              borderRadius: 1,
            }}
          >
            <Typography sx={{ color }} fontSize={14}>
              {estado.label}
            </Typography>
          </Box>
        );
      },
      width: 150,
    },
    {
      field: 'solicitudes',
      headerName: 'Solicitudes',
      cellClassName: DEFAULT_CELL,
      width: 100,
      type: 'number',
    },
    {
      field: 'cancelar',
      type: 'actions',
      headerName: '',
      cellClassName: 'actions',
      width: 50,
      resizable: false,
      getActions: ({ row }) => {
        return row.status < 4
          ? [
            <GridActionsCellItem
              icon={<IconTrash />}
              label="Cancelar"
              color="inherit"
              onClick={() => handleClickCancelar(row.id, row.fecha)}
            />,
          ]
          : [];
      },
    },
  ];
}

export default function DataGridSolicitudes({
  solicitudes,
  estados,
  handleCancelarSolicitud,
}) {
  const deviceHeight = useDeviceHeight();
  const apiRef = useGridApiRef();
  const [rows] = useState([]);
  const [columns] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setRowsDatagrid(solicitudes, apiRef);
  }, [solicitudes]);

  useEffect(() => {
    updateColumnsDatagrid(
      getColumns(estados, handleCancelarSolicitud, theme),
      apiRef,
    );
  }, [estados]);

  return (
    <DataGridCard
      height={deviceHeight - 362}
      rows={rows}
      columns={columns}
      apiRef={apiRef}
      withoutHeader
    />
  );
}
